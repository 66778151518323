import React from "react";
// import { displayProtectedFile } from "../../api/ApiManager";

const ContentImage = (props) => {
  const { src, style, alt, hasAccess } = props;

  const imgStyle = () => {
    return hasAccess ? style : { ...styles.imageNoAccessStyle, ...style };
  };

  
  // displayProtectedFile(src, null, null, (url) => {
  //   const imageElement = document.getElementById(src);
  //   console.log("******URL", url);
  //   imageElement.src = url;
  // });

  return <img src={src} style={imgStyle()} alt={alt} />;;
};

export default ContentImage;

const styles = {
  imageNoAccessStyle: {
    // filter: "blur(65px)",
  },
};
