import React from "react";
import { BrowserView, MobileView, isMobile } from "react-device-detect";
import { Row, Col } from "react-bootstrap";
import { COLORS, LAYOUTS } from "../../constants";

const AboutUs = () => {
  const ImageSection = () => {
    return (
      <Row className="d-flex justify-content-center">
        <Col>
          <img
            src="https://intrigueme.com/wp-content/uploads/2021/06/IntrigueMe.jpeg"
            style={styles.imageStyle}
            alt="Model"
          />
          <img
            src="https://intrigueme.com/wp-content/uploads/2021/06/IntrigueMe-creator-driving-sports-car.jpeg"
            style={styles.imageStyle}
            alt="Model"
          />
          <img
            src="https://intrigueme.com/wp-content/uploads/2021/06/IntrigueMe-unlockable-content-subscription.jpeg"
            style={styles.imageStyle}
            alt="Model"
          />
          <img
            src="https://intrigueme.com/wp-content/uploads/2021/06/IntrigueMe-social-profile-tab.jpeg"
            style={styles.imageStyle}
            alt="Model"
          />
          <img
            src="https://intrigueme.com/wp-content/uploads/2021/06/IntrigueMe-creator-exiting-Lamborghini.jpeg"
            style={styles.imageStyle}
            alt="Model"
          />
          <img
            src="https://intrigueme.com/wp-content/uploads/2021/06/IntrigueMe-subscription-cooking-content-cook-in-the-kitchen.jpeg"
            style={styles.imageStyle}
            alt="Model"
          />
        </Col>
      </Row>
    );
  };

  const InfoSection = () => {
    const padding = isMobile ? 30 : 0;

    return (
      <Row
        style={{
          width: "80%",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <Col md={6} xs={12} style={{ paddingBottom: padding }}>
          <Col md={9} style={styles.infoSectionStyle}>
            <Row></Row>
            <Row>
              <h2 style={styles.infoTitleStyle}>IntrigueMe for Creators</h2>
            </Row>
            <Row>
              <span>
                Creators can earn from their content with pay-per-view
                messaging, monthly subscription fees, and unlimited amounts of
                tips. With no caps on tipping, fans and followers can truly
                connect with creators in a way that’s genuine and natural.
                Freedom for all users is what IntrigueMe stands for most.
              </span>
            </Row>
          </Col>
        </Col>
        <Col md={6} xs={12} style={{ paddingBottom: padding }}>
          <Col md={9} style={styles.infoSectionStyle}>
            <Row></Row>
            <Row>
              <h2 style={styles.infoTitleStyle}>
                IntrigueMe for Users and Viewers
              </h2>
            </Row>
            <Row>
              <span>
                Users on the IntrigueMe social platform will be able to create
                their own profiles and get in touch with each other privately or
                publicly to discuss live streams, events, creators, popular
                content and share infinite thoughts and experiences in any way
                they’d like to. Send & receive money, text messages, photos and
                videos while in a live chat with anyone on the platform from
                around the world. Potential and freedom are limitless on the
                unbounded IntrigueMe network.
              </span>
            </Row>
          </Col>
        </Col>
      </Row>
    );
  };

  const LogoSection = () => {
    return (
      <Row>
        <img
          src={require("../../assets/images/logos/im_logo.png")}
          style={styles.logoStyle}
          alt="logo"
        />
      </Row>
    );
  };

  const TopSection = () => {
    const imgWidth = isMobile ? "90%" : 420;
    const textPadding = isMobile ? 40 : 0;

    return (
      <Row style={styles.topSectionStyle}>
        <Col md={5} xs={12}>
          <img
            src="https://intrigueme.com/wp-content/uploads/2021/04/intrigueMeE.png"
            style={{ width: imgWidth, height: "auto" }}
            alt="App"
          />
        </Col>
        <Col
          md={7}
          xs={12}
          style={{ textAlign: "left", paddingLeft: textPadding }}
        >
          <h1>IntrigueMe</h1>
          <h2>Next Generation Social Media Platform</h2>
          <h2>(Launching 2021)</h2>
          <Col md={6} style={{ textAlign: "left", paddingLeft: 0 }}>
            <span>
              Our new subscription-based social media platform IntrigueMe is the
              most creator-friendly social network yet. When creators direct
              their fans and followers to their IntrigueMe pages, they have full
              control over their premium posts, live streams with interactive
              tips, and much more; all with no cap on tips and the lowest fee in
              the industry – 12%. IntrigueMe creators pocket a hefty 88% of all
              revenue sources for their income no matter what.
            </span>
          </Col>
        </Col>
      </Row>
    );
  };

  const VideoSection = () => {
    return (
      <Row className="d-flex justify-content-center" style={styles.videoSectionStyle}>
        <Col>
          <iframe
            src="https://www.youtube.com/embed/SP23UQbPTKo"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            style={{ margin: 10 }}
          ></iframe>
          <iframe
            src="https://www.youtube.com/embed/0jCTduSTWbI"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            style={{ margin: 10 }}
          ></iframe>
          <iframe
            src="https://www.youtube.com/embed/4gr7caNJX7I"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            style={{ margin: 10 }}
          ></iframe>
        </Col>
      </Row>
    );
  };

  return (
    <div>
      <BrowserView>
        <div className="container-fluid" style={LAYOUTS.bodyStyle}>
          <Row>
            <Col
              style={{
                ...LAYOUTS.mainSectionStyle,
                ...{ paddingLeft: 30, paddingRight: 30 },
              }}
            >
              <TopSection />
              <InfoSection />
              <LogoSection />
              <VideoSection />
              <ImageSection />
            </Col>
          </Row>
        </div>
      </BrowserView>
      <MobileView>
        <Col className="container-fluid">
          <Row style={LAYOUTS.mobileMainSectionStyle}>
            <TopSection />
            <InfoSection />
            <LogoSection />
            <VideoSection />
            <ImageSection />
          </Row>
        </Col>
      </MobileView>
    </div>
  );
};

export default AboutUs;

const styles = {
  imageStyle: {
    width: isMobile ? "90%" : 200,
    height: "auto",
    marginRight: 10,
    marginLeft: 10,
    paddingBottom: isMobile ? 20 : 0
  },
  infoSectionStyle: {
    padding: 30,
    borderWidth: 1,
    boxShadow: `0px 0px 10px 2px ${COLORS.darkPurple}`,
  },
  infoTitleStyle: {
    color: COLORS.darkPurple,
    fontSize: 25,
    fontWeight: "bold",
    width: "100%",
  },
  logoStyle: {
    height: "auto",
    width: 400,
    marginLeft: "auto",
    marginRight: "auto",
  },
  sectionStyle: {
    marginBottom: 20,
  },
  topSectionStyle: {
    marginTop: -27,
    paddingTop: 80,
    paddingBottom: 30,
    marginBottom: 80,
    color: "white",
    backgroundColor: COLORS.darkPurple,
  },
  videoSectionStyle: {
    marginBottom: 60,
  },
};
