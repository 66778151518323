import React, { useContext, useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { BoldText } from "../texts/PageText";
import { AuthContext } from "../../providers/AuthProvider";
import { getUserInfo } from "../../api/ApiManager";
import {
  baseStorageUrl,
  uploadAvatarToFirebase,
  uploadBackgroundToFirebase,
} from "../../api/StorageManager";
import { postUserAvatar, postUserBackground } from "../../api/ApiManager";
import { COLORS } from "../../constants";
import AvatarIcon from "./AvatarIcon";
import $ from "jquery";

const Header = (props) => {
  const { user, updateUser } = useContext(AuthContext);
  const [content, setContent] = useState({});

  function EditStatus() {
    if (window.location.pathname !== "/settings/profile") {
      $(".profileHeaderBody").remove();
      $(".profileDisplayBody").remove();
      $(".profile-header").removeClass("bannerimg");
      $(".profile-display").removeClass("profileimg");
    } else {
      $(".profile-header").addClass("bannerimg");
      $(".profile-display").addClass("profileimg");
    }
  }

  let uploadProfileBannerImage = () => {
    document.getElementById("uploadBackground").click();
    // alert("Please select profile banner image");
    // $('.profile-image-upload').css('background','red')
  };

  let uploadProfileDisplayImage = () => {
    document.getElementById("uploadAvatar").click();
    // alert("Please select profile picture");
    // $('.profile-image-upload').css('background','red')
  };

  useEffect(() => {
    getUserInfo(user.uid, setContent);

    // console.log("*****", document.querySelector("input"));
    const inputs = Array.prototype.slice
      .call(document.querySelectorAll("input"))
      .slice(0, 2);

    for (const input of inputs) {
      input.addEventListener("change", async (e) => {
        if (typeof user.uid !== "undefined" && user.uid !== "" && input.files !== null) {
          const id = e.srcElement.id;
          const file = input.files[0];
          var filename = "";

          switch (id) {
            case "uploadAvatar":
              filename = uploadAvatarToFirebase(user, file);
              break;
            case "uploadBackground":
              filename = uploadBackgroundToFirebase(user, file);
              break;
            default:
              break;
          }

          if (filename !== "undefined" && filename !== "") {
            const baseDirectory = "%2Faccount%2F";
            const fullFileDirectory = `${baseStorageUrl}${user.uid}${baseDirectory}${filename}?alt=media`;

            const jsonBody = {
              uuid: user.uid,
              filepath: fullFileDirectory,
            };

            switch (id) {
              case "uploadAvatar":
                postUserAvatar(jsonBody, () => {
                  const updatedUser = {
                    ...user,
                    avatarUrl: fullFileDirectory,
                  };
                  updateUser(updatedUser, () => {
                    window.location.reload();
                  });
                });
                break;
              case "uploadBackground":
                postUserBackground(jsonBody, () => {
                  const updatedUser = {
                    ...user,
                    backgroundImageUrl: fullFileDirectory,
                  };
                  updateUser(updatedUser, () => {
                    window.location.reload();
                  });
                });
                break;
              default:
                break;
            }
          }
        }
      });
    }
  }, [user, updateUser]);

  return (
    <div className="container mw-100 headerComponent">
      <div className="fb-profile">
        {EditStatus()}
        <div
          className="profile-header"
          style={{
            ...styles.headerStyle,
            ...{ backgroundImage: "url('" + user.backgroundImageUrl + "')" },
          }}
        >
          <div
            htmlFor="uploadBackground"
            className="profileHeaderBody"
            style={{ width: "100%", height: "100%" }}
          >
            <div
              className="profile-image-upload"
              onClick={uploadProfileBannerImage}
            >
              <input
                type="file"
                id="uploadBackground"
                accept="image/png, image/jpeg"
                hidden
              />
            </div>
          </div>
        </div>
        <div>
          <Row>
            <div className="col-6">
              <Row>
                <div
                  className="profile-display"
                  style={styles.avatarSectionStyle}
                >
                  <AvatarIcon user={user} size={150} />
                  <div
                    htmlFor="uploadAvatar"
                    className="profileDisplayBody"
                    style={{ width: "100%", height: "100%" }}
                  >
                    <div
                      className="profile-display-image-upload"
                      onClick={uploadProfileDisplayImage}
                    >
                      <input
                        type="file"
                        id="uploadAvatar"
                        accept="image/png, image/jpeg"
                        hidden
                      />
                    </div>
                  </div>
                </div>
                <div style={{ marginLeft: 20 }}>
                  <div className="fb-profile-text">
                    <h1>{content.displayName}</h1>
                    <h6 className="profile-usernmae">{content.userName}</h6>
                  </div>
                </div>
              </Row>
            </div>
            <div className="col-6 justify-content-end">
              <div
                className="justify-content-end hs"
                style={{ textAlign: "right" }}
              >
                <span className="headerStats">
                  <BoldText>{content.postsCount}</BoldText> Posts
                </span>{" "}
                |{" "}
                <span className="headerStats">
                  <BoldText>{content.likesCount}</BoldText> Likes
                </span>{" "}
                |{" "}
                <span className="headerStats">
                  <BoldText>{content.subscribersCount}</BoldText> Fans
                </span>
              </div>
            </div>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Header;

const styles = {
  avatarSectionStyle: {
    marginTop: -80,
    marginLeft: 50,
    width: 150,
    height: 150,
  },
  headerStyle: {
    backgroundColor: COLORS.lightPurple,
    height: 300,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
};
