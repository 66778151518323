const FOOTER_HEIGHT = 180;
const HEADER_HEIGHT = 80;
const PAGE_MIN_HEIGHT = window.innerHeight - FOOTER_HEIGHT * 2;

export const COLORS = {
  athensGray: "#F2ECF4",
  darkGray: "#282828",
  darkPurple: "#4F0259",
  lightPurple: "#FCA8FE",
  whisper: "#F9F6FA",
};

export const BACKGROUNDS = {
  primaryBackground: COLORS.athensGray,
  secondaryBackground: COLORS.whisper,
};

export const LAYOUTS = {
  bodyStyle: {
    marginTop: HEADER_HEIGHT,
    minHeight: "100%",
    paddingRight: 0,
    paddingLeft: 0
  },
  mainSectionStyle: {
    backgroundColor: COLORS.athensGray,
    minHeight: PAGE_MIN_HEIGHT,
    paddingTop: 30,
    paddingBottom: 30,
    paddingRight: 0
  },
  mainSectionStyleBlank: {
    minHeight: PAGE_MIN_HEIGHT,
    paddingTop: 30,
    paddingBottom: 30,
    paddingRight: 0
  },
  mobileMainSectionStyle: {
    backgroundColor: COLORS.athensGray,
    minHeight: PAGE_MIN_HEIGHT,
  },
  sideSectionStyle: {
    backgroundColor: COLORS.whisper,
    textAlign: "left",
    minHeight: PAGE_MIN_HEIGHT,
  },
  profileHeaderStyle: {
    marginTop: HEADER_HEIGHT,
    maxWidth: "100%",
  },
};

export const TEXTS = {
  primaryText: {
    color: COLORS.darkPurple,
    fontWeight: "bold",
  },
  primaryTextWhite: {
    color: COLORS.darkPurple,
    fontWeight: "bold",
  },
  secondaryText: {
    color: COLORS.lightPurple,
    fontWeight: "normal",
  },
};