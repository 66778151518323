import axios from "axios";
import qs from "qs";
import { API_PATH } from "../lib/utils";

// Comments
const deleteRemoveComment = (commentid, uuid, callback) => {
  const url = `${API_PATH("api")}?qtype=removecomment&commentid=${commentid}`;
  deleteUrlSession(url, callback);
};

const deleteRemoveCommentLike = (commentid, uuid, callback) => {
  const url = `${API_PATH(
    "api"
  )}?qtype=removecommentlike&commentid=${commentid}`;
  deleteUrlSession(url, callback);
};

const getPostComments = (postid, callback) => {
  const url = `${API_PATH("api")}?qtype=postcomments&postid=${postid}`;
  getUrlSession(url, callback);
};

const postAddCommentLike = (commentid, uuid, callback) => {
  const jsonBody = {
    commentid: commentid,
    uuid: uuid,
  };

  const url = `${API_PATH("api")}?qtype=addcommentLike`;
  postUrlSession(url, jsonBody, callback);
};

const postNewComment = (body, callback) => {
  const url = `${API_PATH("api")}?qtype=newcomment`;
  postUrlSession(url, body, callback);
};

const postEditComment = (body, callback) => {
  const url = `${API_PATH("api")}?qtype=editcomment`;
  postUrlSession(url, body, callback);
};

// Company
const postContact = (name, email, message, callback) => {
  const jsonBody = {
    name,
    email,
    message,
  };

  const url = `${API_PATH("api")}?qtype=contact`;
  postUrlSession(url, jsonBody, callback);
};

// Feeds
const getContentFeed = (uuid, page, callback) => {
  const url = `${API_PATH("api")}?qtype=contentfeed&page=${page}`;
  getUrlSession(url, callback);
};

const getSuggestFeed = (uuid, callback) => {
  const url = `${API_PATH("api")}?qtype=suggestionfeed`;
  getUrlSession(url, callback);
};

const getUserPageFeed = (uuid, username, page, callback) => {
  const url = `${API_PATH(
    "api"
  )}?qtype=userpagefeed&username=${username}&page=${page}`;
  getUrlSession(url, callback);
};

const getUserPageSubscriptionFeed = (username, callback) => {
  const url = `${API_PATH(
    "api"
  )}?qtype=userpagesubscriptionfeed&username=${username}`;
  getUrlSession(url, callback);
};

const getUserProfileFeed = (uuid, callback) => {
  const url = `${API_PATH("api")}?qtype=userprofilefeed`;
  getUrlSession(url, callback);
};

// Image
export const displayProtectedFile = async (
  fileId,
  fileName,
  username,
  callback
) => {
  // Fetch the image.
  var imageUrl = `${API_PATH("images")}?`;

  if (fileId !== null) {
    imageUrl += `fileid=${fileId}`;
  }

  if (fileName !== null) {
    imageUrl += `filename=${fileName}&${username}`;
  }

  const token = localStorage.getItem("token");
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "http://localhost:3000",
      "Access-Control-Allow-Credentials": true,
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    responseType: "blob",
  };

  const response = await axios.get(imageUrl, config);
  const blob = response.data;
  const objectUrl = URL.createObjectURL(blob);
  callback(objectUrl);

  // try {
  //   imageElement.src = response.data;
  //   return response.data;
  // } catch (err) {
  //     console.log(err);
  //   }

  // console.log("test",imageUrl)
  // getUrlSession(imageUrl, (data) => {

  //   console.log("ADR", data);

  // }, true)

  // const response = await fetchWithAuthentication(imageUrl, authToken);
  // console.log("response", response.text());
  // Create an object URL from the data.
  // const blob = await response.blob();
  // const imgSrc = await blob.text()
  // console.log("BOY", imgSrc);
  // return imgSrc;

  // const objectUrl = URL.createObjectURL(blob);

  // const test = blob.text().then ((acorn)=> {
  //   console.log("Acorn", acorn)
  //   return acorn
  // });
  // const newBlob = blob.slice(0, blob.size, "image/jpeg")
  // console.log("Blob", blob);
  // const objectUrl = URL.createObjectURL(blob);
  // console.log("UXR", objectUrl);
  // return objectUrl;
  // Update the source of the image.
  // try {
  //   imageElement.src = "https://storage.googleapis.com/intrigueme-b7be5.appspot.com/bOXB7zbrJfRgdeVZuR80SFAZeZs1/content/f233d690-06ba-11ec-852a-f1ddad8af2b6.jpeg?GoogleAccessId=firebase-adminsdk-4wzl6%40intrigueme-b7be5.iam.gserviceaccount.com&Expires=1742194800&Signature=ZGMABgYF3daqDAzme80%2BufgzU2vCwC88bW9h%2FG88zurpocAl%2F0tTk2J%2F80QCsmW68v8Amg2nhU%2F9pBGRg54jUDKL7MhrvEpILhh8W87eUKRor6%2B7Dlnve%2Fe9VgxKXLznK3lxd9%2FE%2BzeylagMAO0HM5ojR%2FCj%2FilBoZpaRkCV3E%2BIz3N4OB0w%2FtSi5CL5K%2B8jm1RycD8nOOttqPDODJILP7zprTVrcgFxI10Ij4tFdCVt1Xf9%2BBp6Qa5Kz95Iik%2F1fTmLVVrqViKNLVtKw2OBgA6YnAiKegCpurbUXdjvai42zhn7RusJKrPKkeWTaDbMJBXh2eV6XuwqDK0u7cHqbA%3D%3D"

  //   // imageElement.src = objectUrl;
  //   // imageElement.onload = () => URL.revokeObjectUrl(objectUrl);
  //   console.log("IMAGE", imageElement);
  // } catch (err) {
  //   console.log(err);
  // }
};

// Notifications
const getEmailNotifications = (uuid, callback) => {
  const url = `${API_PATH("api")}?qtype=notificationsemail`;
  getUrlSession(url, callback);
};

const getOtherNotifications = (uuid, callback) => {
  const url = `${API_PATH("api")}?qtype=notificationsother`;
  getUrlSession(url, callback);
};

const getPushNotifications = (uuid, callback) => {
  const url = `${API_PATH("api")}?qtype=notificationspush`;
  getUrlSession(url, callback);
};

const getSiteNotifications = (uuid, callback) => {
  const url = `${API_PATH("api")}?qtype=notificationssite`;
  getUrlSession(url, callback);
};

const getToastNotifications = (uuid, callback) => {
  const url = `${API_PATH("api")}?qtype=notificationstoast`;
  getUrlSession(url, callback);
};

const postEmailNotifications = (boolvalue, uuid, callback) => {
  const jsonBody = {
    boolvalue: boolvalue,
    uuid: uuid,
  };

  const url = `${API_PATH("api")}?qtype=notificationsemail`;
  postUrlSession(url, jsonBody, callback);
};

const postEmailNotificationsNewsletter = (boolvalue, uuid, callback) => {
  const jsonBody = {
    boolvalue: boolvalue,
    uuid: uuid,
  };

  const url = `${API_PATH("api")}?qtype=notificationsemailnewsletter`;
  postUrlSession(url, jsonBody, callback);
};

const postEmailNotificationsReceiveLess = (boolvalue, uuid, callback) => {
  const jsonBody = {
    boolvalue: boolvalue,
    uuid: uuid,
  };

  const url = `${API_PATH("api")}?qtype=notificationsemailreceiveless`;
  postUrlSession(url, jsonBody, callback);
};

const postEmailNotificationsShowFullText = (boolvalue, uuid, callback) => {
  const jsonBody = {
    boolvalue: boolvalue,
    uuid: uuid,
  };

  const url = `${API_PATH("api")}?qtype=notificationsemailshowfulltext`;
  postUrlSession(url, jsonBody, callback);
};

const postOtherNotifications = (boolvalue, uuid, callback) => {
  const jsonBody = {
    boolvalue: boolvalue,
    uuid: uuid,
  };

  const url = `${API_PATH("api")}?qtype=notificationsother`;
  postUrlSession(url, jsonBody, callback);
};

const postPushNotifications = (boolvalue, uuid, callback) => {
  const jsonBody = {
    boolvalue: boolvalue,
    uuid: uuid,
  };

  const url = `${API_PATH("api")}?qtype=notificationspush`;
  postUrlSession(url, jsonBody, callback);
};

const postSiteNotificationsNewCampaignContribution = (
  boolvalue,
  uuid,
  callback
) => {
  const jsonBody = {
    boolvalue: boolvalue,
    uuid: uuid,
  };

  const url = `${API_PATH(
    "api"
  )}?qtype=notificationssitenewcampaigncontribution`;
  postUrlSession(url, jsonBody, callback);
};

const postSiteNotificationsNewComment = (boolvalue, uuid, callback) => {
  const jsonBody = {
    boolvalue: boolvalue,
    uuid: uuid,
  };

  const url = `${API_PATH("api")}?qtype=notificationssitenewcomment`;
  postUrlSession(url, jsonBody, callback);
};

const postSiteNotificationsNewLikes = (boolvalue, uuid, callback) => {
  const jsonBody = {
    boolvalue: boolvalue,
    uuid: uuid,
  };

  const url = `${API_PATH("api")}?qtype=notificationssitenewlikes`;
  postUrlSession(url, jsonBody, callback);
};

const postSiteNotificationsNewSubscriber = (boolvalue, uuid, callback) => {
  const jsonBody = {
    boolvalue: boolvalue,
    uuid: uuid,
  };

  const url = `${API_PATH("api")}?qtype=notificationssitenewsubscriber`;
  postUrlSession(url, jsonBody, callback);
};

const postSiteNotificationsNewTip = (boolvalue, uuid, callback) => {
  const jsonBody = {
    boolvalue: boolvalue,
    uuid: uuid,
  };

  const url = `${API_PATH("api")}?qtype=notificationssitenewtip`;
  postUrlSession(url, jsonBody, callback);
};

const postToastNotificationsNewCampaignContribution = (
  boolvalue,
  uuid,
  callback
) => {
  const jsonBody = {
    boolvalue: boolvalue,
    uuid: uuid,
  };

  const url = `${API_PATH(
    "api"
  )}?qtype=notificationstoastnewcampaigncontribution`;
  postUrlSession(url, jsonBody, callback);
};

const postToastNotificationsNewComment = (boolvalue, uuid, callback) => {
  const jsonBody = {
    boolvalue: boolvalue,
    uuid: uuid,
  };

  const url = `${API_PATH("api")}?qtype=notificationstoastnewcomment`;
  postUrlSession(url, jsonBody, callback);
};

const postToastNotificationsNewLikes = (boolvalue, uuid, callback) => {
  const jsonBody = {
    boolvalue: boolvalue,
    uuid: uuid,
  };

  const url = `${API_PATH("api")}?qtype=notificationstoastnewlikes`;
  postUrlSession(url, jsonBody, callback);
};

const postToastNotificationsNewSubscriber = (boolvalue, uuid, callback) => {
  const jsonBody = {
    boolvalue: boolvalue,
    uuid: uuid,
  };

  const url = `${API_PATH("api")}?qtype=notificationstoastnewsubscriber`;
  postUrlSession(url, jsonBody, callback);
};

const postToastNotificationsNewTip = (boolvalue, uuid, callback) => {
  const jsonBody = {
    boolvalue: boolvalue,
    uuid: uuid,
  };

  const url = `${API_PATH("api")}?qtype=notificationstoastnewtip`;
  postUrlSession(url, jsonBody, callback);
};

// Posts
const deleteRemoveBookmark = (postid, uuid, callback) => {
  const url = `${API_PATH("api")}?qtype=removebookmark&postid=${postid}`;
  deleteUrlSession(url, callback);
};

const deleteRemoveLike = (postid, uuid, callback) => {
  const url = `${API_PATH("api")}?qtype=removelike&postid=${postid}`;
  deleteUrlSession(url, callback);
};

const getPostEditById = (postid, uuid, callback) => {
  const url = `${API_PATH("api")}?qtype=posteditbyid&postid=${postid}`;
  getUrlSession(url, callback);
};

const postAddBookmark = (postid, uuid, callback) => {
  const jsonBody = {
    postid: postid,
    uuid: uuid,
  };

  const url = `${API_PATH("api")}?qtype=addbookmark`;
  postUrlSession(url, jsonBody, callback);
};

const postAddLike = (postid, subuuid, uuid, callback) => {
  const jsonBody = {
    postid: postid,
    subuuid: subuuid,
    uuid: uuid,
  };

  const url = `${API_PATH("api")}?qtype=addlike`;
  postUrlSession(url, jsonBody, callback);
};

const postEditPost = (body, callback) => {
  const url = `${API_PATH("api")}?qtype=editpost`;
  postUrlSession(url, body, callback);
};

const postNewPost = (body, callback) => {
  const url = `${API_PATH("api")}?qtype=newpost`;
  postUrlSession(url, body, callback);
};

// Private Messages
const getExistingPM = (uuid, touuid, callback) => {
  const url = `${API_PATH("api")}?qtype=pmexist&touuid=${touuid}`;
  getUrlSession(url, callback);
};

const getMessages = (uuid, pmid, callback) => {
  const url = `${API_PATH("api")}?qtype=messages&pmid=${pmid}`;
  getUrlSession(url, callback);
};

const getMessageUserList = (uuid, callback) => {
  const url = `${API_PATH("api")}?qtype=messageuserlist`;
  getUrlSession(url, callback);
};

const postNewMessage = (body, callback) => {
  const url = `${API_PATH("api")}?qtype=newmessage`;
  postUrlSession(url, body, callback);
};

const postReplyMessage = (body, callback) => {
  const url = `${API_PATH("api")}?qtype=replymessage`;
  postUrlSession(url, body, callback);
};

// Subscription
const postSubscriptionFree = (body, callback) => {
  const url = `${API_PATH("api")}?qtype=subscriptionfree`;
  postUrlSession(url, body, callback);
};

// User Info
const getExistingAccount = (uuid, callback) => {
  const url = `${API_PATH("api")}?qtype=accountexist`;
  getUrlSession(url, callback);
};

const getUserAccount = (uuid, callback) => {
  const url = `${API_PATH("api")}?qtype=useraccount`;
  getUrlSession(url, callback);
};

const getUserBookmarks = (uuid, callback) => {
  const url = `${API_PATH("api")}?qtype=userbookmarks`;
  getUrlSession(url, callback);
};

const getUserFollowers = (uuid, callback) => {
  const url = `${API_PATH("api")}?qtype=userfollowers`;
  getUrlSession(url, callback);
};

const getUserFollowing = (uuid, callback) => {
  const url = `${API_PATH("api")}?qtype=userfollowing`;
  getUrlSession(url, callback);
};

const getUserHasSubscription = (uuid, subusername, callback) => {
  const url = `${API_PATH(
    "api"
  )}?qtype=userhassubscription&subusername=${subusername}`;
  getUrlSession(url, callback);
};

const getUserInfo = (uuid, callback) => {
  const url = `${API_PATH("api")}?qtype=userinfo`;
  getUrlSession(url, callback);
};

const getUserLists = (uuid, callback) => {
  const url = `${API_PATH("api")}?qtype=userlists`;
  getUrlSession(url, callback);
};

const getUsernameList = (uuid, q, callback) => {
  const url = `${API_PATH("api")}?qtype=usernamelist&q=${q}`;
  getUrlSession(url, callback);
};

const getUserProfile = (uuid, callback) => {
  const url = `${API_PATH("api")}?qtype=userprofile`;
  getUrlSession(url, callback);
};

const getUserSubscriptionInfo = (uuid, callback) => {
  const url = `${API_PATH("api")}?qtype=subscriptioninfo`;
  getUrlSession(url, callback);
};

const postRegistration = (body, callback) => {
  const url = `${API_PATH("api")}?qtype=register`;
  postUrlSession(url, body, callback);
};

const postUserAccount = (body, callback) => {
  const url = `${API_PATH("api")}?qtype=useraccount`;
  postUrlSession(url, body, callback);
};

const postUserAvatar = (body, callback) => {
  const url = `${API_PATH("api")}?qtype=useravatar`;
  postUrlSession(url, body, callback);
};

const postUserBackground = (body, callback) => {
  const url = `${API_PATH("api")}?qtype=userbackground`;
  postUrlSession(url, body, callback);
};

const postUserProfile = (body, callback) => {
  const url = `${API_PATH("api")}?qtype=userprofile`;
  postUrlSession(url, body, callback);
};

const postUserSubscriptionInfo = (body, callback) => {
  const url = `${API_PATH("api")}?qtype=subscriptioninfo`;
  postUrlSession(url, body, callback);
};

// URL Session
const token = localStorage.getItem("token");
const config = {
  headers: {
    Authorization: `Bearer ${token}`,
  },
};

const deleteUrlSession = (url, callback) => {
  axios
    .delete(url, config)
    .then((response) => {
      return callback(response.data);
    })
    .catch((error) => console.log(error));
};

const getUrlSession = (url, callback, isBaseData) => {
  isBaseData = isBaseData || false;

  axios
    .get(url, config)
    .then((response) => {
      const data = response.data;
      if (typeof data.errors !== "undefined") {
        return callback(null);
      }
      const _data = isBaseData ? data : data.data;
      return callback(_data);
    })
    .catch((error) => console.log(error));
};

const postUrlSession = (url, body, callback) => {
  axios
    .post(url, qs.stringify(body), config)
    .then((response) => {
      return callback(response.data);
    })
    .catch((error) => console.log(error));
};

export {
  deleteRemoveBookmark,
  deleteRemoveComment,
  deleteRemoveCommentLike,
  deleteRemoveLike,
  getContentFeed,
  getEmailNotifications,
  getExistingAccount,
  getExistingPM,
  getMessages,
  getMessageUserList,
  getOtherNotifications,
  getPostEditById,
  getPostComments,
  getPushNotifications,
  getSiteNotifications,
  getSuggestFeed,
  getToastNotifications,
  getUserAccount,
  getUserBookmarks,
  getUserFollowers,
  getUserFollowing,
  getUserHasSubscription,
  getUserInfo,
  getUserLists,
  getUsernameList,
  getUserPageFeed,
  getUserPageSubscriptionFeed,
  getUserProfile,
  getUserProfileFeed,
  getUserSubscriptionInfo,
  postAddBookmark,
  postAddCommentLike,
  postAddLike,
  postContact,
  postEditComment,
  postEditPost,
  postEmailNotifications,
  postEmailNotificationsNewsletter,
  postEmailNotificationsReceiveLess,
  postEmailNotificationsShowFullText,
  postNewComment,
  postNewMessage,
  postNewPost,
  postOtherNotifications,
  postPushNotifications,
  postRegistration,
  postReplyMessage,
  postSiteNotificationsNewCampaignContribution,
  postSiteNotificationsNewComment,
  postSiteNotificationsNewLikes,
  postSiteNotificationsNewSubscriber,
  postSiteNotificationsNewTip,
  postSubscriptionFree,
  postToastNotificationsNewCampaignContribution,
  postToastNotificationsNewComment,
  postToastNotificationsNewLikes,
  postToastNotificationsNewSubscriber,
  postToastNotificationsNewTip,
  postUserAccount,
  postUserAvatar,
  postUserBackground,
  postUserProfile,
  postUserSubscriptionInfo,
};
