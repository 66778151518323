import React, { useContext, useEffect, useState } from "react";
import { Picker } from "emoji-mart";
import FileUploader from "../../components/FileUploader";
import { uploadFileToFirebase } from "../../api/StorageManager";
import { Form, Col, Row } from "react-bootstrap";
import { AuthContext } from "../../providers/AuthProvider";
import { COLORS, LAYOUTS } from "../../constants";
import { BoldText, PagePlaceholderText } from "../../components/texts/PageText";
import {
  getExistingPM,
  getMessageUserList,
  getUsernameList,
  postNewMessage,
  postReplyMessage,
} from "../../api/ApiManager";
import MessageFeed from "../../components/feeds/MessageFeed";
import "./MessageNew.css";
import { BrowserView, MobileView } from "react-device-detect";
import { BackButton, BasicButton } from "../../components/buttons/Buttons";

const MessageNew = () => {
  const [messageFile, setMessageFile] = useState([]);
  const [messageInfo, setMessageInfo] = useState({ message: "" });
  const [messageUserList, setMessageUserList] = useState([]);
  const [userSuggestionInfo, setUserSuggestionInfo] = useState([]);
  const [selectedUUID, setSelectedUUID] = useState(null);
  const { user } = useContext(AuthContext);

  const UserSuggestions = () => {
    const usersuggestion = userSuggestionInfo.map((suggestion, idx) => {
      return (
        <li
          key={suggestion}
          name={suggestion.username}
          value={suggestion.uuid}
          onClick={handleClick}
          style={styles.suggestionItemStyle}
        >
          {suggestion.username}
        </li>
      );
    });

    return (
      <div style={{ width: 100, paddingLeft: 0 }}>
        <ul style={styles.suggestionSectionStyle}>{usersuggestion}</ul>
      </div>
    );
  };

  const handleChange = (e) => {
    switch (e.target.name) {
      case "message":
        setMessageInfo({
          ...messageInfo,
          [e.target.name]: e.target.value,
        });
        break;
      case "touser":
        if (e.target.value === "" || typeof e.target.value === "undefined") {
          setUserSuggestionInfo([]);
        } else {
          const userInput = e.target.value;
          getUsernameList(user.uid, userInput, setUserSuggestionInfo);
        }
        break;
      default:
        break;
    }
  };

  const handleChangeStatus = ({ meta, file }, status) => {
    setMessageFile([file]);
  };

  const handleClick = (e) => {
    const selectedUsername = e.target.getAttribute("name");
    setSelectedUUID(e.target.getAttribute("value"));
    document.getElementById("touser").value = selectedUsername;
    setUserSuggestionInfo([]);
  };

  const handlePickerOnSelect = (emoji) => {
    setMessageInfo({
      ...messageInfo,
      message: messageInfo.message + emoji.native,
    });
  };

  const handleSubmit = () => {
    const fileList = [];
    messageFile.forEach((file) => {
      // Upload files to firebase
      let fileName = uploadFileToFirebase(user.uid, file);
      fileList.push(fileName);
    });

    getExistingPM(user.uid, selectedUUID, (result) => {
      if (result.pmExist === false) {
        const message = {
          uuid: user.uid,
          touuid: selectedUUID,
          message: messageInfo.message,
          files: fileList,
        };

        // New Message
        postNewMessage(message, () => {
          // document.getElementById("fileuploader").reset();
          document.getElementById("message_form").reset();
        });
      } else {
        // Reply to exisitng message
        const message = {
          pmid: result.pmid,
          uuid: user.uid,
          touuid: selectedUUID,
          message: messageInfo.message,
          files: fileList,
        };

        postReplyMessage(message, () => {
          // document.getElementById("fileuploader").reset();
          document.getElementById("message_form").reset();
        });
      }
    });
  };

  useEffect(() => {
    if (user.uid !== "") {
      getMessageUserList(user.uid, setMessageUserList);
    }
  }, [user.uid]);

  return (
    <div>
      <BrowserView>
        <Col className="container-fluid">
          <Row style={LAYOUTS.bodyStyle}>
            <Col
              md={8}
              className="d-none d-lg-block"
              style={LAYOUTS.mainSectionStyle}
            >
              <PagePlaceholderText>New Message</PagePlaceholderText>
              <Row style={styles.messageSectionStyle}>
                <Form
                  id="message_form"
                  method="post"
                  encType="multipart/form-data"
                  style={styles.formStyle}
                >
                  <Row style={styles.usernameInputSection}>
                    <input
                      type="text"
                      className="my-2 p-1 w-50"
                      name="touser"
                      placeholder="Type to search for user..."
                      id="touser"
                      onChange={handleChange}
                      autoComplete="off"
                    />
                  </Row>
                  <Row style={styles.userSuggestionSection}>
                    <UserSuggestions />
                  </Row>
                  <Row>
                    <Col md={7}>
                      <Form.Control
                        as="textarea"
                        rows={17}
                        id="message"
                        name="message"
                        maxLength="200"
                        placeholder="Type a message..."
                        value={messageInfo.message}
                        onChange={handleChange}
                        style={styles.textAreaStyle}
                      />
                    </Col>
                    <Col md={5}>
                      <Picker
                        title="Pick your emoji…"
                        emoji="point_up"
                        onSelect={(emoji) => handlePickerOnSelect(emoji)}
                      />
                    </Col>
                  </Row>
                  <Row
                    style={{ marginTop: 15, marginLeft: 0, marginRight: -3 }}
                  >
                    <FileUploader
                      onChangeStatus={handleChangeStatus}
                      disabledSubmit={true}
                      submitButtonContent={null}
                      maxFiles={1}
                    />
                  </Row>
                  <Row style={styles.messageInteractionSectionStyle}>
                    <Col className="d-flex justify-content-end">
                      <button
                        type="button"
                        className="btn white-text display_modal"
                        onClick={handleSubmit}
                        style={styles.sendButtonStyle}
                      >
                        Send
                      </button>
                    </Col>
                  </Row>
                </Form>
              </Row>
            </Col>
            <Col md={4} style={LAYOUTS.sideSectionStyle}>
              {messageUserList.length > 0 ? (
                <MessageFeed
                  messageList={messageUserList}
                  hideNewMessageButton={true}
                />
              ) : null}
            </Col>
          </Row>
        </Col>
      </BrowserView>
      <MobileView>
        <Col className="container-fluid" style={LAYOUTS.mobileMainSectionStyle}>
          <Row
            style={{
              paddingLeft: 5,
              paddingRight: 5,
            }}
          >
            <Col>
              <Row
                className="d-flex align-self-end justify-content-start"
                style={{ paddingTop: 10, paddingLeft: 5 }}
              >
                <BoldText>NEW MESSAGE</BoldText>
              </Row>
              <Row style={styles.messageSectionMobileStyle}>
                <Form
                  id="message_form"
                  method="post"
                  encType="multipart/form-data"
                  style={styles.formStyle}
                >
                  <Row style={styles.usernameInputMobileSection}>
                    <input
                      type="text"
                      className="my-2 p-1 w-50"
                      name="touser"
                      placeholder="Type to search for user..."
                      id="touser"
                      onChange={handleChange}
                      autoComplete="off"
                    />
                  </Row>
                  <Row style={styles.userSuggesionSection}>
                    <UserSuggestions />
                  </Row>
                  <Row style={{ marginBottom: 10 }}>
                    <FileUploader
                      onChangeStatus={handleChangeStatus}
                      disabledSubmit={true}
                      submitButtonContent={null}
                      maxFiles={1}
                    />
                  </Row>
                  <Row style={{ marginBottom: 10 }}>
                    <Col>
                      <Form.Control
                        as="textarea"
                        rows={4}
                        id="message"
                        name="message"
                        placeholder="Type a message..."
                        style={styles.textAreaStyle}
                      />
                    </Col>
                  </Row>
                  <Row style={styles.messageInteractionSectionStyle}>
                    <Col className="d-flex justify-content-end">
                      <BackButton to="/messages" />
                      <BasicButton text="Send" onClick={handleSubmit} />
                    </Col>
                  </Row>
                </Form>
              </Row>
            </Col>
          </Row>
        </Col>
      </MobileView>
    </div>
  );
};

export default MessageNew;

const styles = {
  formStyle: {
    marginTop: 0,
    width: "100%",
  },
  sendButtonStyle: {
    backgroundColor: COLORS.darkPurple,
    marginRight: 0,
  },
  messageInteractionSectionStyle: {
    marginTop: 10,
  },
  messageSectionMobileStyle: {
    marginTop: 10,
    paddingLeft: 5,
    paddingRight: 5,
  },
  messageSectionStyle: {
    marginTop: 20,
    paddingLeft: 10,
    paddingRight: 30,
  },
  suggestionItemStyle: {
    paddingBottom: 5,
    marginTop: 0,
    width: 300,
  },
  suggestionSectionStyle: {
    paddingLeft: 5,
    listStyle: "none",
    textAlign: "left",
    backgroundColor: "white",
    position: "relative",
    zIndex: 1,
    width: 450,
  },
  textAreaStyle: {
    width: "100%",
    resize: "none",
  },
  usernameInputMobileSection: {
    paddingLeft: 15,
    marginBottom: 15,
  },
  usernameInputSection: {
    paddingLeft: 1,
    marginBottom: 15,
    marginLeft: 0,
    marginTop: 30,
  },
  userSuggestionSection: {
    marginTop: -20,
    marginLeft: 0,
    height: 30,
  },
};
