import React from "react";
import {
  Link,
} from "react-router-dom";

const Sidebar = (props) => {


  const menu = [
    { id: 1, text: "All Bookmarks", to:'/bookmarks/all' },
    { id: 2, text: "Photos", to:'/bookmarks/photos'},
    { id: 3, text: "Videos", to:'/bookmarks/videos' },
    { id: 4, text: "Audios", to:'/bookmarks/audios' },
    { id: 5, text: "Others", to:'/bookmarks/others' }

  ];

  return (
    <div className="d-flex align-items-start">
      <ul className="bookmarkNav nav nav-tabs flex-column profileHeader" style={{ borderBottom: "unset"}}>

      {menu.map((val) => (
        <li key={val.id} className= "nav-item">
          <Link  className= {window.location.pathname === val.to ? "nav-link active" : "nav-link"} to={val.to} style={{ borderTop: "none" }}>{val.text}</Link>
          </li>
        ))}
      </ul> </div>
  );
};

export default Sidebar;