import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import AvatarIcon from "../../components/profile/AvatarIcon";
import { COLORS } from "../../constants";
import { TipButton } from "../buttons/Buttons";
import { BoldText } from "../../components/texts/PageText";
import TipModal from "../modals/TipModal";

const UserCard = (props) => {
  const { data } = props;
  const [showTipModal, setShowTipModal] = useState(false);

  // Modals
  const toggleTipModal = () => {
    setShowTipModal(!showTipModal);
  };

  return (
    <div style={styles.cardStyle}>
      <TipModal
        show={showTipModal}
        toggleModal={toggleTipModal}
        content={data}
      />
      <Col>
        <Link to={`/im/${data.userName}`}>
          <Row
            style={{
              ...styles.backgroundHeaderStyle,
              ...{ backgroundImage: "url('" + data.backgroundImageUrl + "')" },
            }}
          ></Row>
          <Row>
            <Col md={4} xs={4} style={{ marginTop: -50 }}>
              <AvatarIcon user={data} size={100} />
            </Col>
            <Col md={8} xs={4}>
              <BoldText>{data.displayName}</BoldText>
            </Col>
          </Row>
        </Link>
        <Row style={{ marginTop: 20 }}>
          <TipButton onClick={() => setShowTipModal(true)} />
        </Row>
      </Col>
    </div>
  );
};

export default UserCard;

const styles = {
  backgroundHeaderStyle: {
    backgroundColor: "lightblue",
    height: 100,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  cardStyle: {
    height: 320,
    width: 320,
    marginBottom: 20,
    marginRight: 20,
    borderWidth: 1,
    borderColor: COLORS.darkPurple,
    borderStyle: "solid",
    borderRadius: 5,
  },
};
