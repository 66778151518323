import React from "react";
import { MDBModal, MDBModalBody, MDBModalHeader } from "mdbreact";
import { Col, Row } from "react-bootstrap";
import { BasicButton } from "../buttons/Buttons";

const OkOnlyModal = (props) => {
  return (
    <MDBModal
      id="okonlymodal"
      centered
      className="modal-dialog modal-md modal-notify"
      isOpen={props.show}
      toggle={props.toggleModal}
    >
      <MDBModalBody></MDBModalBody>
    </MDBModal>
  );
};

const YesNoModal = (props) => {
  const { message, title, yesClick } = props;
  return (
    <MDBModal
      id="yesnomodal"
      centered
      className="modal-dialog modal-md modal-notify"
      isOpen={props.show}
      toggle={props.toggleModal}
    >
      <MDBModalHeader>{title.toUpperCase()}</MDBModalHeader>
      <MDBModalBody>
        <Row style={styles.messageSectionStyle}>
          <span style={styles.messageStyle}>{message}</span>
        </Row>
        <Row>
          <Col style={styles.buttonSectionStyle}>
            <BasicButton text="Yes" onClick={yesClick} />
          </Col>
          <Col style={styles.buttonSectionStyle}>
            <BasicButton text="No" onClick={props.toggleModal} />
          </Col>
        </Row>
      </MDBModalBody>
    </MDBModal>
  );
};

export { OkOnlyModal, YesNoModal };

const styles = {
  buttonSectionStyle: {
    display: "flex",
    justifyContent: "center",
  },
  messageSectionStyle: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 15,
  },
  messageStyle: {
    textAlign: "center",
    fontSize: 20,
    fontWeight: "bold",
  },
};
