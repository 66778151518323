import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { COLORS } from "../../constants";
import AvatarIcon from "../../components/profile/AvatarIcon";
import { truncate } from "../../lib/utils";

const SuggestionCard = (props) => {
  const { data } = props;

  return (
    <div
      style={{
        ...styles.cardStyle,
        ...{ backgroundImage: "url('" + data.backgroundImageUrl + "')" },
      }}
    >
      <Link to={`/im/${data.userName}`}>
        <div style={styles.leftSectionStyle}>
          <AvatarIcon user={data} size={70} />
        </div>
        <div style={styles.bottomSectionStyle}>
          <Col style={styles.buttonBackgroundStyle} />
          <Col style={styles.buttonTextContainerStyle}>
            <Row>
              <span style={styles.usernameStyle}>
                @{truncate(data.userName, 20)}
              </span>
            </Row>
            <Row>
              <span style={styles.nameStyle}>{data.displayName}</span>
            </Row>
          </Col>
        </div>
      </Link>
    </div>
  );
};

export default SuggestionCard;

const styles = {
  bottomSectionStyle: {
    height: 60,
    width: "100%",
    marginTop: -55,
    position: "relative",
    zIndex: 0,
  },
  buttonBackgroundStyle: {
    height: 60,
    width: "100%",
    opacity: 0.5,
    backgroundColor: COLORS.darkPurple,
  },
  buttonTextContainerStyle: {
    position: "relative",
    marginTop: -55,
    paddingLeft: 110,
  },
  cardStyle: {
    backgroundColor: "lightblue",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    height: 80,
    minWidth: "99%",
    maxWidth: "99%",
    marginBottom: 10,
  },
  leftSectionStyle: {
    width: 90,
    paddingLeft: 10,
    paddingTop: 5,
    position: "relative",
    zIndex: 11,
    // backgroundColor: "red"
  },
  nameStyle: {
    color: "white",
    fontSize: 15,
  },
  usernameStyle: {
    color: "white",
    fontWeight: "bold",
    fontSize: 15,
  },
};
