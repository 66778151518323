import React from "react";
import { Link } from "react-router-dom";
import { MDBIcon } from "mdbreact";
import { Col, Container } from "react-bootstrap";
import { MediaObject } from "../media/MediaObject";
import { COLORS } from "../../constants";

const ContentGridCard = (props) => {
  const { content } = props;

  return (
    <Container style={styles.cardStyle}>
      <Col style={styles.mediaSectionStyle}>
        <div style={styles.editButtonSectionStyle}>
          <Link to={`../editpost/${content.postId}`}>
            <MDBIcon
              icon="ellipsis-v"
              size="2x"
              style={styles.editButtonStyle}
            />
          </Link>
        </div>
        <MediaObject content={content} maxHeight={290} />
      </Col>
    </Container>
  );
};

export default ContentGridCard;

const styles = {
  cardStyle: {
    height: 320,
    width: 320,
    marginBottom: 20,
    marginRight: 20,
    borderWidth: 1,
    borderColor: COLORS.darkPurple,
    borderStyle: "solid",
    borderRadius: 5,
  },
  editButtonSectionStyle: {
    position: "absolute",
    right: 5,
    top: 5,
    zIndex: 9
  },
  mediaSectionStyle: {
    paddingLeft: 0,
    paddingRight: 0,
    marginTop: 15,
    height: 290,
    display: "flex",
    alignItems: "center",
  },
};
