import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import NumberFormat from "react-number-format";
import { AuthContext } from "../../providers/AuthProvider";
import { BrowserView, MobileView, isMobile } from "react-device-detect";
import { Row, Col, Tabs, Tab, Container } from "react-bootstrap";
import { COLORS, LAYOUTS } from "../../constants";
import {
  getUserHasSubscription,
  getUserPageFeed,
  getUserPageSubscriptionFeed,
  postSubscriptionFree,
} from "../../api/ApiManager";
import { BoldText, PrimaryText } from "../../components/texts/PageText";
import UserFeed from "../../components/feeds/UserFeed";
import AvatarIcon from "../../components/profile/AvatarIcon";
import SubscriptionCard from "../../components/cards/SubscriptionCard";
import "./UserPage.css";
import backImage from "../../assets/icons/back.svg";

const UserPage = () => {
  const { user } = useContext(AuthContext);
  const [subscription, setSubscription] = useState({});
  const [hasSubscription, setHasSubscription] = useState(false);
  const { username } = useParams();

  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const loader = useRef(null);

  const BundlesSection = (props) => {
    const bundleItems = props.bundles.map((bundle) => {
      return (
        <Row key={bundle.bundleId}>
          <Col>
            <SubscriptionCard
              subscription={bundle}
              onClick={() => handleSubscription(bundle)}
            />
          </Col>
        </Row>
      );
    });

    return (
      <div style={styles.bundleSectionStyle}>
        <BoldText>SUBSCRIPTION BUNDLES</BoldText>
        {bundleItems}
      </div>
    );
  };

  const ContentSection = () => {
    const posts = `${subscription.postsInfo.postsCount} Posts`;
    const images = `${subscription.postsInfo.imageCount} Images`;
    const videos = `${subscription.postsInfo.videoCount} Videos`;

    return (
      <div style={styles.contentSectionStyle}>
        <Row>
          <Col>
            <Tabs
              fill
              justify
              id="content-tab"
              className="userpage"
              defaultActiveKey="posts"
              style={styles.tabsStyle}
            >
              <Tab eventKey="posts" title={posts}></Tab>
              <Tab eventKey="images" title={images}></Tab>
              <Tab eventKey="videos" title={videos}></Tab>
            </Tabs>
          </Col>
        </Row>
        <Row>
          <Col className="card-footer">
            <button
              type="button"
              className="btn white-text"
              style={styles.footerButtonStyle}
              onClick={() => handleSubscription(subscription)}
            >
              SUBSCRIBE TO SEE USER'S POST
            </button>
          </Col>
        </Row>
      </div>
    );
  };

  const HeaderSection = () => {
    return (
      <div
        style={{
          ...styles.headerStyle,
          ...{
            backgroundImage: "url('" + subscription.backgroundImageUrl + "')",
          },
        }}
      >
        <Row>
          <Col className="ps-0 d-flex justify-content-start">
            <Link to="/">
              <img src={backImage} width="30" alt="back" />
            </Link>
          </Col>
        </Row>
        <Row style={styles.headerNameStyle}>{subscription.displayName}</Row>
        <Row>
          <Col className="ps-0 d-flex justify-content-start">
            <span style={styles.headerStatsStyle}>
              <NumberFormat
                value={subscription.postsCount}
                displayType={"text"}
                thousandSeparator={true}
              />
              {" Posts | "}
              <NumberFormat
                value={subscription.likesCount}
                displayType={"text"}
                thousandSeparator={true}
              />
              {" Likes"}
            </span>
          </Col>
        </Row>
      </div>
    );
  };

  const SubscriptionSection = () => {
    const subscriptionPrice =
      subscription.subscriptionId !== "" ? subscription.price : "FREE";

    return (
      <div style={styles.subscriptionSectionStyle}>
        <BoldText>SUBSCRIPTION</BoldText>
        <Row>
          <Col>
            <SubscriptionCard
              subscription={{ price: subscriptionPrice }}
              onClick={() => handleSubscription(subscription)}
            />
          </Col>
        </Row>
        {typeof subscription.bundles !== "undefined" &&
        subscription.bundles.length > 0 ? (
          <BundlesSection bundles={subscription.bundles} />
        ) : null}
        {typeof subscription.bundles !== "undefined" ? (
          <ContentSection />
        ) : null}
      </div>
    );
  };

  const PostSection = () => {
    if (typeof subscription.posts !== "undefined") {
      return (
        <div>
          <UserFeed contentList={subscription.posts} />
        </div>
      );
    }
    return <div />;
  };

  const UserInfoSection = () => {
    return (
      <div style={styles.userInfoStyle}>
        <Row>
          <AvatarIcon user={subscription} size={145} />
        </Row>
        <Row>
          <BoldText>{subscription.displayName}</BoldText>
        </Row>
        <Row>
          <PrimaryText>{subscription.userName}</PrimaryText>
        </Row>
      </div>
    );
  };

  const handleSubscription = (subscription) => {
    if (subscription.price === "0.00") {
      const jsonBody = {
        uuid: user.uid,
        subuuid: subscription.userId,
      };

      postSubscriptionFree(jsonBody, () => {
        window.location.reload();
      });
    } else {
      alert(`Subscribed for ${subscription.price} currently not available!`);
    }
  };

  // Data Load
  const handleObserver = useCallback((entries) => {
    const target = entries[0];
    if (target.isIntersecting) {
      setPage((prev) => prev + 1);
    }
  }, []);

  const fetchContent = useCallback(async () => {
    try {
      setLoading(true);
      getUserPageFeed(user.uid, username, page, (data) => {
        setSubscription((prev) => {
          if (typeof prev.posts === "undefined") {
            return data;
          }
          return { ...prev, posts: [...prev.posts, ...data.posts] };
        });
      });
      setLoading(false);
    } catch (err) {}
  }, [user.uid, username, page]);

  const loadInitialData = useCallback(async () => {
    if (user.uid !== "" && username !== "") {
      getUserHasSubscription(user.uid, username, (results) => {
        setHasSubscription(results.userHasSubscription);
        if (results.userHasSubscription) {
          fetchContent(user.uid, username);
        } else {
          getUserPageSubscriptionFeed(username, (results) => {
            setSubscription(results);
          });
        }
      });
    }
  }, [fetchContent, user.uid, username]);

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: "20px",
      threshold: 0,
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (loader.current) observer.observe(loader.current);
  }, [handleObserver]);

  useEffect(() => {
    loadInitialData(user.uid, username);
  }, [loadInitialData, user, username]);

  // If logged in user goes to their own page, redirect to user profile
  if (user.userName === `@${username}`) {
    return <Navigate to="/userprofile" />;
  }

  return (
    <div>
      <BrowserView>
        <Container style={LAYOUTS.bodyStyle}>
          <div style={{ ...LAYOUTS.mainSectionStyle, ...{ paddingTop: 0 } }}>
            <HeaderSection />
            <UserInfoSection />
            {hasSubscription ? <PostSection /> : <SubscriptionSection />}
          </div>
        </Container>
      </BrowserView>
      <MobileView>
        <Container fluid>
          <HeaderSection />
          <UserInfoSection />
          {hasSubscription ? <PostSection /> : <SubscriptionSection />}
        </Container>
      </MobileView>
      {loading && hasSubscription && <p>Loading...</p>}
      <div ref={loader} />
    </div>
  );
};

export default UserPage;

const styles = {
  bundleSectionStyle: {
    paddingBottom: 50,
    paddingLeft: 0,
  },
  contentSectionStyle: {
    backgroundColor: "#FFF",
    minHeight: 140,
    maxWidth: 540,
    marginLeft: "auto",
    marginRight: "auto",
    borderRadius: 5,
    WebkitFilter: "drop-shadow(0px 0px 5px #555)",
    filter: "drop-shadow(0px 0px 5px #555)",
  },
  footerButtonStyle: {
    minHeight: 44,
    width: "90%",
    backgroundColor: COLORS.darkPurple,
    fontSize: 14,
    fontFamily: "Arial, Helvetica, sans-serif",
    fontWeight: "bold",
    textTransform: "uppercase",
    borderRadius: 5,
    outline: "none",
  },
  headerStyle: {
    backgroundColor: COLORS.lightPurple,
    paddingLeft: 25,
    height: 300,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    marginLeft: isMobile ? -20 : 0,
    marginRight: isMobile ? -15 : 0,
  },
  headerNameStyle: {
    color: "white",
    fontWeight: "bold",
    fontSize: 22,
  },
  headerStatsStyle: {
    color: "white",
    fontWeight: "bold",
    fontSize: 17,
  },
  subscriptionSectionStyle: {
    marginTop: 30,
    marginLeft: 0,
    marginRight: 0,
  },
  tabsStyle: {
    width: "100%",
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "stretch",
  },
  userInfoStyle: {
    marginTop: -70,
    marginLeft: 25,
  },
};
