import React, { useState } from "react";
import { Row } from "react-bootstrap";
import { MDBIcon } from "mdbreact";
import { BoldText } from "../texts/PageText";
import "./Pagination.css";

const Pagination = ({ data, RenderComponent, title, pageLimit, dataLimit }) => {
  const [pages] = useState(Math.round(data.length / dataLimit));
  const [currentPage, setCurrentPage] = useState(1);
  
  const goToNextPage = () => {
    setCurrentPage((page) => page + 1);
  };

  const goToPreviousPage = () => {
    setCurrentPage((page) => page - 1);
  };

  const changePage = (event) => {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage(pageNumber);
  };

  const getPaginatedData = () => {
    const startIndex = currentPage * dataLimit - dataLimit;
    const endIndex = startIndex + dataLimit;
    return data.slice(startIndex, endIndex);
  };

  const getPaginationGroup = () => {
    return Array.from({length: pages}, (_, i) => i + 1);
  };

  return (
    <div>
      <div className="container" style={styles.sectionStyle}>
        <Row style={styles.headerSectionStyle}>
          <div className="col-10">
            <BoldText>{title}</BoldText>
          </div>
          <div
            className="col-2 d-flex justify-content-end"
            style={styles.navSectionStyle}
          >
            <button
              onClick={goToPreviousPage}
              className={`prev ${currentPage === 1 ? "disabled" : ""}`}
            >
              <MDBIcon icon="angle-left" size="2x" style={styles.linkStyle} />
            </button>
            <button
              onClick={goToNextPage}
              className={`next ${currentPage === pages ? "disabled" : ""}`}
            >
              <MDBIcon icon="angle-right" size="2x" style={styles.linkStyle} />
            </button>
          </div>
        </Row>
        {getPaginatedData().map((d, idx) => (
          <RenderComponent key={idx} data={d} />
        ))}
      </div>
      <div className="pagination" style={styles.paginationButtonsSection}>
        {getPaginationGroup().map((item, index) => (
          <button
            key={index}
            onClick={changePage}
            className={`paginationItem ${
              currentPage === item ? "active" : null
            }`}
            // style={styles.buttonStyle}
          >
            <span>{item}</span>
          </button>
        ))}
      </div>
    </div>
  );
};

export default Pagination;

const styles = {
  buttonStyle: {
    marginLeft: 5,
    marginRight: 5,
    width: 20,
    textAlign: "center"
  },
  headerSectionStyle: {
    marginBottom: 5,
  },
  linkStyle: {
    marginLeft: 20,
  },
  navSectionStyle: {
    paddingRight: 10,
  },
  paginationButtonsSection: {
    diplay: "flex",
    justifyContent: 'center',
    alignItems: "center",
  },
  sectionStyle: {
    marginBottom: 50,
    marginTop: 20,
    minWidth: "99%",
    maxWidth: "99%",
  },
};