import React, { useContext, useEffect, useState } from "react";
import { MDBModal, MDBModalBody, MDBModalHeader } from "mdbreact";
import { Col, Form, Row } from "react-bootstrap";
import { Picker } from "emoji-mart";
import { AuthContext } from "../../providers/AuthProvider";
import { BasicButton } from "../buttons/Buttons";
import { postEditComment } from "../../api/ApiManager";

const AddEditCommentModal = (props) => {
  const { user } = useContext(AuthContext);
  const { comment, postId, commentsId } = props.comments;
  const editMode =
    typeof props.editMode != "undefined" ? props.editMode : false;
  const [commentOriginal, setCommentOriginal] = useState(editMode ? comment : "");
  const [updateButtonDisable, setUpdateButtonDisable] = useState(true);
  const [text, setText] = useState(commentOriginal);
  const title = editMode ? "EDIT COMMENTS" : "ADD COMMENTS";

  const handleChange = (e) => {
    switch (e.target.name) {
      case "comments":
        setText(e.target.value);
        break;
      default:
        break;
    }
  };

  const handlePickerOnSelect = (emoji) => {
    setText(text + emoji.native);
  };

  const handleSubmit = () => {
    const commentBody = {
      uuid: user.uid,
      postid: postId,
      commentid: commentsId,
      comment: text,
    };
    console.log("****", commentBody)
    if (editMode) {
      postEditComment(commentBody, () => {
        setCommentOriginal(text);
        props.toggleModal();
        window.location.reload();
      });
    } else {
    }
  };

  useEffect(() => {
    if (text === 0) {
      setUpdateButtonDisable(true);
    } else {
      setUpdateButtonDisable(false);
    }
  }, [text]);

  useEffect(() => {
    if (!props.show) {
      setText(commentOriginal);
    }
  }, [props.show, commentOriginal])

  return (
    <MDBModal
      id="centralModalInfo"
      centered
      className="modal-dialog modal-md modal-notify"
      isOpen={props.show}
      toggle={props.toggleModal}
      size="lg"
    >
      <MDBModalHeader>{title}</MDBModalHeader>
      <MDBModalBody style={styles.formStyle}>
        <Form style={styles.formStyle}>
          <Form.Group>
            <Row>
              <Col md={7}>
                <Form.Control
                  as="textarea"
                  rows={17}
                  id="comments"
                  name="comments"
                  maxLength="200"
                  placeholder="Compose new comment..."
                  value={text}
                  onChange={handleChange}
                  style={styles.textAreaStyle}
                />
              </Col>
              <Col className="d-flex justify-content-end" md={5}>
                <Picker
                  title="Pick your emoji…"
                  emoji="point_up"
                  onSelect={(emoji) => handlePickerOnSelect(emoji)}
                />
              </Col>
            </Row>
            <Row className="d-flex justify-content-end">
              <BasicButton text="CANCEL" onClick={() => props.toggleModal()} />
              <BasicButton
                text="Send"
                onClick={handleSubmit}
                disabled={updateButtonDisable}
              />
            </Row>
          </Form.Group>
        </Form>
      </MDBModalBody>
    </MDBModal>
  );
};

export default AddEditCommentModal;

const styles = {
  formStyle: {
    width: "100%",
  },
  textAreaStyle: {
    width: "100%",
    resize: "none",
  },
};
