import React from "react";
import { Link } from "react-router-dom";

const Sidebar = (props) => {
  const menu = [
    { id: 1, text: "Profile", to: "/settings/profile" },
    { id: 2, text: "Account", to: "/settings/account" },
    { id: 3, text: "Privacy & Safety", to: "/settings/privacy" },
    { id: 4, text: "Subscription & bundles", to: "/settings/subscription" },
    { id: 5, text: "Fans and followings", to: "/settings/fansfollwers" },
    { id: 6, text: "Notifications", to: "/settings/notification" },
    // { id: 7, text: "Chats", to:'/' },
    // { id: 8, text: "Story", to:'/' },
    // { id: 9, text: "Streaming", to:'/' },
  ];

  return (
    <div
      className={`d-flex align-items-start ${
        props.isMobile === true && props.isMenuOpen === true
          ? "sidebar-menu open"
          : props.isMobile === true && props.isMenuOpen === false
          ? "sidebar-menu"
          : props.isMobile === false
          ? ""
          : ""
      }`}
    >
      <ul
        className="nav nav-tabs flex-column profileHeader"
        style={{ borderBottom: "unset" }}
      >
        {menu.map((val) => (
          <li
            key={val.id}
            className="nav-item"
          >
            <Link
              className={
                window.location.pathname === val.to
                  ? "nav-link active"
                  : "nav-link"
              }
              to={val.to}
              style={{ borderTop: "none" }}
            >
              {val.text}
            </Link>
          </li>
        ))}
      </ul>{" "}
    </div>
  );
};

export default Sidebar;