import React from "react";
import { BoldText } from "../../../components/texts/PageText";
import { Row, Col, Button, Card } from 'react-bootstrap';
import { FaChevronCircleRight, FaArrowAltCircleDown, FaArrowAltCircleUp, FaCheck, FaTimesCircle } from 'react-icons/fa';
import { MDBDataTableV5 } from 'mdbreact';

const Statement = (props) => {

    const [datatable] = React.useState({
        columns: [
          {
            label: 'Date',
            field: 'date',
            sort: 'disabled',
            width: 150,
            attributes: {
              'aria-controls': 'DataTable',
              'aria-label': 'Name',
            },
          },
          {
            label: 'Time',
            field: 'time',
            width: 270,
          },
          {
            label: 'Amount',
            field: 'amount',
            width: 100,
          },
          {
            label: 'Fee',
            field: 'fee',
            sort: 'disabled',
            width: 100,
          },
          {
            label: 'Net',
            field: 'net',
            sort: 'disabled',
            width: 50,
          },
          {
            label: 'Description',
            field: 'description',
            sort: 'disabled',
            width: 250,
          },
          {
            label: 'Status',
            field: 'status',
            width: 100,
          },
        ],
        rows: [
          {
            time: '10:00 am',
            amount: '$9.99',
            fee: '$8.00',
            net: '$9.99',
            date: '2011/04/25',
            description: 'Subscription from DSDSD',
            status: <span style={{color: '#4f0259'}}><FaCheck /></span>
          },
          {
            time: '10:00 am',
            amount: '$9.99',
            fee: '$8.00',
            net: '$9.99',
            date: '2011/07/25',
            description: 'Subscription from DSDSD',
            status: <span style={{color: '#4f0259'}}><FaTimesCircle /></span>
          },
          {
            time: '10:00 am',
            amount: '$9.99',
            fee: '$8.00',
            net: '$9.99',
            date: '2009/01/12',
            description: 'Subscription from DSDSD',
            status: <span style={{color: '#4f0259'}}><FaCheck /></span>
          },
          {
            time: '10:00 am',
            amount: '$9.99',
            fee: '$8.00',
            net: '$9.99',
            date: '2012/03/29',
            description: 'Subscription from DSDSD',
            status: <span style={{color: '#4f0259'}}><FaCheck /></span>
          },
          {
            time: '10:00 am',
            amount: '$9.99',
            fee: '$8.00',
            net: '$9.99',
            date: '2008/11/28',
            description: 'Subscription from DSDSD',
            status: <span style={{color: '#4f0259'}}><FaCheck /></span>
          },
          {
            time: '10:00 am',
            amount: '$9.99',
            fee: '$8.00',
            net: '$9.99',
            date: '2012/12/02',
            description: 'Subscription from DSDSD',
            status: <span style={{color: '#4f0259'}}><FaTimesCircle /></span>
          },
          {
            time: '10:00 am',
            amount: '$9.99',
            fee: '$8.00',
            net: '$9.99',
            date: '2012/08/06',
            description: 'Subscription from DSDSD',
            status: <span style={{color: '#4f0259'}}><FaTimesCircle /></span>
          },
          {
            time: '10:00 am',
            amount: '$9.99',
            fee: '$8.00',
            net: '$9.99',
            date: '2010/10/14',
            description: 'Subscription from DSDSD',
            status: <span style={{color: '#4f0259'}}><FaTimesCircle /></span>
          },
          {
            time: '10:00 am',
            amount: '$9.99',
            fee: '$8.00',
            net: '$9.99',
            date: '2009/09/15',
            description: 'Subscription from DSDSD',
            status: <span style={{color: '#4f0259'}}><FaCheck /></span>
          },
          {
            time: '10:00 am',
            amount: '$9.99',
            fee: '$8.00',
            net: '$9.99',
            date: '2008/12/13',
            description: 'Subscription from DSDSD',
            status: <span style={{color: '#4f0259'}}><FaCheck /></span>
          },
          {
            time: '10:00 am',
            amount: '$9.99',
            fee: '$8.00',
            net: '$9.99',
            date: '2008/12/19',
            description: 'Subscription from DSDSD',
            status: <span style={{color: '#4f0259'}}><FaCheck /></span>
          },
          {
            time: '10:00 am',
            amount: '$9.99',
            fee: '$8.00',
            net: '$9.99',
            date: '2013/03/03',
            description: 'Subscription from DSDSD',
            status: <span style={{color: '#4f0259'}}><FaCheck /></span>
          },
          {
            time: '10:00 am',
            amount: '$9.99',
            fee: '$8.00',
            net: '$9.99',
            date: '2008/10/16',
            description: 'Subscription from DSDSD',
            status: <span style={{color: '#4f0259'}}><FaCheck /></span>
          },
          {
            time: '10:00 am',
            amount: '$9.99',
            fee: '$8.00',
            net: '$9.99',
            date: '2012/12/18',
            description: 'Subscription from DSDSD',
            status: <span style={{color: '#4f0259'}}><FaCheck /></span>
          },
          {
            time: '10:00 am',
            amount: '$9.99',
            fee: '$8.00',
            net: '$9.99',
            date: '2010/03/17',
            description: 'Subscription from DSDSD',
            status: <span style={{color: '#4f0259'}}><FaCheck /></span>
          },
          {
            time: '10:00 am',
            amount: '$9.99',
            fee: '$8.00',
            net: '$9.99',
            date: '2012/11/27',
            description: 'Subscription from DSDSD',
            status: <span style={{color: '#4f0259'}}><FaCheck /></span>
          },
          {
            time: '10:00 am',
            amount: '$9.99',
            fee: '$8.00',
            net: '$9.99',
            date: '2010/06/09',
            description: 'Subscription from DSDSD',
            status: <span style={{color: '#4f0259'}}><FaCheck /></span>
          },
          {
            time: '10:00 am',
            amount: '$9.99',
            fee: '$8.00',
            net: '$9.99',
            date: '2009/04/10',
            description: 'Subscription from DSDSD',
            status: <span style={{color: '#4f0259'}}><FaTimesCircle /></span>
          },
          {
            time: '10:00 am',
            amount: '$9.99',
            fee: '$8.00',
            net: '$9.99',
            date: '2012/10/13',
            description: 'Subscription from DSDSD',
            status: <span style={{color: '#4f0259'}}><FaTimesCircle /></span>
          },
          {
            time: '10:00 am',
            amount: '$9.99',
            fee: '$8.00',
            net: '$9.99',
            date: '2012/09/26',
            description: 'Subscription from DSDSD',
            status: <span style={{color: '#4f0259'}}><FaTimesCircle /></span>
          },
          {
            time: '10:00 am',
            amount: '$9.99',
            fee: '$8.00',
            net: '$9.99',
            date: '2011/09/03',
            description: 'Subscription from DSDSD',
            status: <span style={{color: '#4f0259'}}><FaTimesCircle /></span>
          },
          {
            time: '10:00 am',
            amount: '$9.99',
            fee: '$8.00',
            net: '$9.99',
            date: '2009/06/25',
            description: 'Subscription from DSDSD',
            status: <span style={{color: '#4f0259'}}><FaTimesCircle /></span>
          },
          {
            time: '10:00 am',
            amount: '$9.99',
            fee: '$8.00',
            net: '$9.99',
            date: '2011/12/12',
            description: 'Subscription from DSDSD',
            status: <span style={{color: '#4f0259'}}><FaTimesCircle /></span>
          },
          {
            time: '10:00 am',
            amount: '$9.99',
            fee: '$8.00',
            net: '$9.99',
            date: '2010/09/20',
            description: 'Subscription from DSDSD',
            status: <span style={{color: '#4f0259'}}><FaCheck /></span>
          },
          {
            time: '10:00 am',
            amount: '$9.99',
            fee: '$8.00',
            net: '$9.99',
            date: '2009/10/09',
            description: 'Subscription from DSDSD',
            status: <span style={{color: '#4f0259'}}><FaCheck /></span>
          },
          {
            time: '10:00 am',
            amount: '$9.99',
            fee: '$8.00',
            net: '$9.99',
            date: '2010/12/22',
            description: 'Subscription from DSDSD',
            status: <span style={{color: '#4f0259'}}><FaCheck /></span>
          },
          {
            time: '10:00 am',
            amount: '$9.99',
            fee: '$8.00',
            net: '$9.99',
            date: '2010/11/14',
            description: 'Subscription from DSDSD',
            status: <span style={{color: '#4f0259'}}><FaCheck /></span>
          },
          {
            time: '10:00 am',
            amount: '$9.99',
            fee: '$8.00',
            net: '$9.99',
            date: '2011/06/07',
            description: 'Subscription from DSDSD',
            status: <span style={{color: '#4f0259'}}><FaCheck /></span>
          },
          {
            time: '10:00 am',
            amount: '$9.99',
            fee: '$8.00',
            net: '$9.99',
            date: '2010/03/11',
            description: 'Subscription from DSDSD',
            status: <span style={{color: '#4f0259'}}><FaCheck /></span>
          },
          {
            time: '10:00 am',
            amount: '$9.99',
            fee: '$8.00',
            net: '$9.99',
            date: '2011/08/14',
            description: 'Subscription from DSDSD',
            status: <span style={{color: '#4f0259'}}><FaCheck /></span>
          },
          {
            time: '10:00 am',
            amount: '$9.99',
            fee: '$8.00',
            net: '$9.99',
            date: '2011/06/02',
            description: 'Subscription from DSDSD',
            status: <span style={{color: '#4f0259'}}><FaCheck /></span>
          },
          {
            time: '10:00 am',
            amount: '$9.99',
            fee: '$8.00',
            net: '$9.99',
            date: '2009/10/22',
            description: 'Subscription from DSDSD',
            status: <span style={{color: '#4f0259'}}><FaCheck /></span>
          },
          {
            time: '10:00 am',
            amount: '$9.99',
            fee: '$8.00',
            net: '$9.99',
            date: '2011/05/07',
            description: 'Subscription from DSDSD',
            status: <span style={{color: '#4f0259'}}><FaCheck /></span>
          },
          {
            time: '10:00 am',
            amount: '$9.99',
            fee: '$8.00',
            net: '$9.99',
            date: '2008/10/26',
            description: 'Subscription from DSDSD',
            status: <span style={{color: '#4f0259'}}><FaCheck /></span>
          },
          {
            time: 'Martena Mccray',
            amount: '$9.99',
            fee: '$8.00',
            net: '$9.99',
            date: '2011/03/09',
            description: 'Subscription from DSDSD',
            status: <span style={{color: '#4f0259'}}><FaCheck /></span>
          },
          {
            time: 'Unity Butler',
            amount: '$9.99',
            fee: '$8.00',
            net: '$9.99',
            date: '2009/12/09',
            description: 'Subscription from DSDSD',
            status: <span style={{color: '#4f0259'}}><FaCheck /></span>
          },
          {
            time: 'Howard Hatfield',
            amount: '$9.99',
            fee: '$8.00',
            net: '$9.99',
            date: '2008/12/16',
            description: '$164',
             status: <span style={{color: '#4f0259'}}><FaCheck /></span>
          },
          {
            time: 'Hope Fuentes',
            amount: 'Secretary',
            fee: 'San Francisco',
            net: '41',
            date: '2010/02/12',
            description: '$109',
          },
          {
            time: 'Vivian Harrell',
            amount: 'Financial Controller',
            fee: 'San Francisco',
            net: '62',
            date: '2009/02/14',
            description: '$452',
          },
          {
            time: 'Timothy Mooney',
            amount: 'Office Manager',
            fee: 'London',
            net: '37',
            date: '2008/12/11',
            description: '$136',
          },
          {
            time: 'Jackson Bradshaw',
            amount: 'Director',
            fee: 'New York',
            net: '65',
            date: '2008/09/26',
            description: '$645',
          },
          {
            time: 'Olivia Liang',
            amount: 'Support Engineer',
            fee: 'Singapore',
            net: '64',
            date: '2011/02/03',
            description: '$234',
          },
          {
            time: 'Bruno Nash',
            amount: 'Software Engineer',
            fee: 'London',
            net: '38',
            date: '2011/05/03',
            description: '$163',
          },
          {
            time: 'Sakura Yamamoto',
            amount: 'Support Engineer',
            fee: 'Tokyo',
            net: '37',
            date: '2009/08/19',
            description: '$139',
          },
          {
            time: 'Thor Walton',
            amount: 'Developer',
            fee: 'New York',
            net: '61',
            date: '2013/08/11',
            description: '$98',
          },
          {
            time: 'Finn Camacho',
            amount: 'Support Engineer',
            fee: 'San Francisco',
            net: '47',
            date: '2009/07/07',
            description: '$87',
          },
          {
            time: 'Serge Baldwin',
            amount: 'Data Coordinator',
            fee: 'Singapore',
            net: '64',
            date: '2012/04/09',
            description: '$138',
          },
          {
            time: 'Zenaida Frank',
            amount: 'Software Engineer',
            fee: 'New York',
            net: '63',
            date: '2010/01/04',
            description: '$125',
          },
          {
            time: 'Zorita Serrano',
            amount: 'Software Engineer',
            fee: 'San Francisco',
            net: '56',
            date: '2012/06/01',
            description: '$115',
          },
          {
            time: 'Jennifer Acosta',
            amount: 'Junior Javascript Developer',
            fee: 'Edinburgh',
            net: '43',
            date: '2013/02/01',
            description: '$75',
          },
          {
            time: 'Cara Stevens',
            amount: 'Sales Assistant',
            fee: 'New York',
            net: '46',
            date: '2011/12/06',
            description: '$145',
          },
          {
            time: 'Hermione Butler',
            amount: 'Regional Director',
            fee: 'London',
            net: '47',
            date: '2011/03/21',
            description: '$356',
          },
          {
            time: 'Lael Greer',
            amount: 'Systems Administrator',
            fee: 'London',
            net: '21',
            date: '2009/02/27',
            description: '$103',
          },
          {
            time: 'Jonas Alexander',
            amount: 'Developer',
            fee: 'San Francisco',
            net: '30',
            date: '2010/07/14',
            description: '$86',
          },
          {
            time: 'Shad Decker',
            amount: 'Regional Director',
            fee: 'Edinburgh',
            net: '51',
            date: '2008/11/13',
            description: '$183',
          },
          {
            time: 'Michael Bruce',
            amount: 'Javascript Developer',
            fee: 'Singapore',
            net: '29',
            date: '2011/06/27',
            description: '$183',
          },
          {
            time: 'Donna Snider',
            amount: 'Customer Support',
            fee: 'New York',
            net: '27',
            date: '2011/01/25',
            description: '$112',
          },
        ],
      });

  return (

<Row>
<Col md={6} xs={12}>
<Card>
  <Card.Body>
  <Row>
    <Col md={6} ><h6> <BoldText>Avaliable Funds</BoldText></h6></Col><Col md={6} className="text-right">688.11$</Col>
    <Col md={6} ><h6> Pending Funds</h6></Col><Col md={6} className="text-right">28.51$</Col>
</Row>
<Button variant="secondary" style={{float: 'right'}} size="sm">Transfer Money</Button>{' '}
  </Card.Body>
</Card>
</Col>





<Col md={6} xs={12}>
<Card>
  <Card.Body>
  <Row>
    <Col md={6} ><h6> <BoldText>Cash Flow</BoldText></h6></Col><Col md={6} className="text-right"><span style={{color: '#a6c'}}><FaChevronCircleRight /></span></Col>
</Row>
<Row>
    <Col md={4} className="text-center"><h6>Daily</h6></Col>
    <Col md={4} className="text-center"><h6>Weekly</h6></Col>
    <Col md={4} className="text-center"><h6>Monthly</h6></Col>
</Row>

<Row className="pt-3">
    <Col md={4} className="text-center"><h6><span style={{color: '#4f0259'}}><FaArrowAltCircleDown /></span>285.30</h6></Col>
    <Col md={4} className="text-center"><h6><span style={{color: '#a6c'}}><FaArrowAltCircleUp /></span>1487.12</h6></Col>
    <Col md={4} className="text-center"><h6><span style={{color: '#a6c'}}><FaArrowAltCircleUp /></span>7400.00</h6></Col>
</Row>
  </Card.Body>
</Card>

</Col>




<Col md={12} xs={12} className="mt-3">
<h4>Latest Transactions</h4>  
<MDBDataTableV5 hover entriesOptions={[5, 20, 25]} entries={5} pagesAmount={4} data={datatable} responsive />
</Col>
</Row>



  );
};

export default Statement;