import React, { useContext, useEffect, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { AuthContext } from "../../../providers/AuthProvider";
import { LAYOUTS } from "../../../constants";
import { BoldText } from "../../../components/texts/PageText";
import {
  getUserSubscriptionInfo,
  postUserSubscriptionInfo,
} from "../../../api/ApiManager";
import Header from "../../../components/profile/Header";
import Sidebar from "../../../components/profile/Sidebar";
import { Row, Col, Form, Button, Container } from "react-bootstrap";
import BundleSubscriptionModal from "./../../../components/modals/BundleSubscriptionModal";
import MobileSidebar from "../../../components/profile/MobileSidebar";

const EditSubscription = () => {
  const { user } = useContext(AuthContext);
  const [showBundleModal, setShowBundleModal] = useState({});
  const [subscriptionPrice, setSubscriptionPrice] = useState({ price: "0.0" });

  const handleChange = (e) => {
    switch (e.target.name) {
      case "price":
        let { value, min, max } = e.target;
        value = Math.max(Number(min), Math.min(Number(max), Number(value)));
        e.target.value = value;
        break;
      default:
        break;
    }
  };

  const handleSave = () => {
    const userSubscriptionInfo = {
      uuid: user.uid,
      price: subscriptionPrice.price,
    };
    postUserSubscriptionInfo(userSubscriptionInfo, () => {
      alert("Updated!");
    });
  };

  const addBundleClick = () => {
    setShowBundleModal({ status: true });
  };

  const BodySection = () => {
    return (
      <div>
        <Row>
          <Col sm={12} md={8}>
            <Form>
              <Form.Group className="pt-3">
                <Form.Control
                  className="shadow-none"
                  type="number"
                  placeholder="Price Per Month"
                  defaultValue={subscriptionPrice.price}
                  name="price"
                  min="0"
                  max="500"
                  onChange={(e) => handleChange(e)}
                />
              </Form.Group>
            </Form>
          </Col>
          <Col md={2} xs={6} className="pt-2">
            <Button variant="secondary" size="md" onClick={handleSave}>
              Save
            </Button>{" "}
          </Col>
          <Col md={2} xs={6} className="pt-2">
            <Button variant="light" size="md">
              Cancel
            </Button>{" "}
          </Col>
        </Row>
        <hr style={styles.hr} />
        <Row>
          <Col xs={7} md={8}>
            <h5>
              <BoldText>Promotions</BoldText>
            </h5>
            <p>This is for testing purpose</p>
          </Col>
          <Col xs={5} md={4} className="text-right">
            <Button variant="secondary" size="md">
              START A PROMOTION
            </Button>{" "}
          </Col>
        </Row>
        <hr style={styles.hr} />
        <Row>
          <Col xs={8} md={9}>
            <h5>
              <BoldText>Bundles</BoldText>
            </h5>
            <p>
              Your current subscription bundle:{" "}
              <BoldText>"Here we have user bundle detail status"</BoldText>
            </p>
          </Col>
          <Col xs={4} md={3} className="text-right">
            <Button variant="secondary" size="md" onClick={addBundleClick}>
              Add Bundle
            </Button>{" "}
          </Col>
        </Row>
      </div>
    );
  };

  useEffect(() => {
    if (user.uid !== "") {
      getUserSubscriptionInfo(user.uid, setSubscriptionPrice);
    }
  }, [user.uid]);

  // Sets once user leaves the text field
  onchange = (e) => {
    setSubscriptionPrice({
      ...subscriptionPrice,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div>
      <BrowserView>
        <Container style={LAYOUTS.bodyStyle}>
          <BundleSubscriptionModal
            show={showBundleModal}
            hide={() => setShowBundleModal({ status: false })}
          />
          <Row style={LAYOUTS.mainSectionStyleBlank}>
            <Header />
            <Col md={3}>
              <Sidebar />
            </Col>
            <Col md={9} className="text-left">
              <h4>
                <BoldText>Subscription</BoldText>
              </h4>
              <hr style={styles.hr} />
              <BodySection />
            </Col>
          </Row>
        </Container>
      </BrowserView>
      <MobileView>
        <div className="container header" style={LAYOUTS.profileHeaderStyle}>
          <Row>
            <Header />
          </Row>
        </div>
        <div className="container pb-4 headerBody" style={LAYOUTS.bodyStyle}>
          <BundleSubscriptionModal
            show={showBundleModal}
            hide={() => setShowBundleModal({ status: false })}
          />
          ;
          <Row>
            <MobileSidebar />
            <span style={styles.profileComponentHeader}>Subscription</span>
            <Col sm={12} className="text-left mt-2">
              <BodySection />
            </Col>
          </Row>
        </div>
      </MobileView>
    </div>
  );
};

export default EditSubscription;

const styles = {
  headerStyle: {
    paddingLeft: 25,
    height: 300,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  profileComponentHeader: {
    fontSize: "25px",
    fontWeight: 600,
  },
  group: {
    borderBottom: "1px solid black",
  },
  hr: {
    borderTop: "4px solid rgba(0,0,0,.1)",
  },
};
