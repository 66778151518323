import React from "react";
import { LAYOUTS } from "../../constants";
import "./Notifications.css";
import { BrowserView, MobileView, isMobile } from "react-device-detect";
import { Row, Col, Container, Tabs, Tab } from "react-bootstrap";
import NotificationCard from "../../components/cards/NotificationCard";
import { PagePlaceholderText } from "../../components/texts/PageText";
import notificationImage from "../../assets/icons/navbar/notification.svg"

const Notifications = () => {
  const HeaderSection = () => {
    return (
      <Tabs
        id="notification-tab"
        className="notifications"
        defaultActiveKey="all"
      >
        <Tab eventKey="all" title="All">
          <Row>
            <Col md={9}>
              <NotificationCard />
            </Col>
          </Row>
        </Tab>
        <Tab eventKey="interactions" title="INTERACTIONS">
          <Row>
            <Col md={9}>
              <NotificationCard />
            </Col>
          </Row>
        </Tab>
        <Tab eventKey="liked" title="LIKED">
          <Row>
            <Col md={9}>
              <NotificationCard />
            </Col>
          </Row>
        </Tab>
        <Tab eventKey="tipped" title="TIPPED">
          <Row>
            <Col md={9}>
              <NotificationCard />
            </Col>
          </Row>
        </Tab>
      </Tabs>
    );
  };

  const BodySection = () => {
    const topMargin = isMobile ? 20 : 200;

    return (
      <Col>
        <Row
          className="row justify-content-center"
          style={{ marginTop: topMargin }}
        >
          <img
            src={notificationImage}
            width="30"
            style={styles.iconStyle}
            alt="message"
          />
        </Row>
        <Row className="row justify-content-center">
          <PagePlaceholderText>Notifications</PagePlaceholderText>
        </Row>
      </Col>
    );
  };

  return (
    <div>
      <BrowserView>
        <Container fluid style={LAYOUTS.bodyStyle}>
          <Col style={LAYOUTS.mainSectionStyle}>
            <Row>
              <Col md={2}></Col>
              <Col md={10}>{/* <HeaderSection /> */}</Col>
            </Row>
            <BodySection />
          </Col>
        </Container>
      </BrowserView>
      <MobileView>
        <Container fluid style={LAYOUTS.mainSectionStyle}>
          {/* <Row>
            <HeaderSection />
          </Row> */}
          <Row>
            <BodySection />
          </Row>
        </Container>
      </MobileView>
    </div>
  );
};

export default Notifications;

const styles = {
  iconStyle: {
    width: 60,
  },
};
