import React, { useContext, useState } from "react";
import { Picker } from "emoji-mart";
import { PageTitle } from "../../components/texts/PageTitles";
import { COLORS, LAYOUTS } from "../../constants";
import FileUploader from "../../components/FileUploader";
import { uploadFileToFirebase } from "../../api/StorageManager";
import { AuthContext } from "../../providers/AuthProvider";
import { postNewPost } from "../../api/ApiManager";
import { Form, Col, Row } from "react-bootstrap";
import { BrowserView, MobileView, isMobile } from "react-device-detect";

const NewPost = () => {
  const { user } = useContext(AuthContext);
  const PRICE_DEFAULT = "0.00";
  const POST_INFO_DEFAULT = {
    desc: "",
    isFree: true,
    price: PRICE_DEFAULT,
  };
  const viewPadding = isMobile ? 15 : 0;

  const [postInfo, setPostInfo] = useState(POST_INFO_DEFAULT);
  const [disabledPrice, setDisabledPrice] = useState("disabled");

  const handleChange = (e) => {
    let value = e.target.value;

    switch (e.target.name) {
      case "desc":
        setPostInfo({ ...postInfo, [e.target.name]: e.target.value });
        break;
      case "isFree":
        value = e.target.checked;
        setDisabledPrice(e.target.checked ? "disabled" : "");
        setPostInfo({ ...postInfo, isFree: e.target.checked, price: "0.00" });
        break;
      case "price":
        let { min, max } = e.target;
        value = Math.max(Number(min), Math.min(Number(max), Number(value)));
        document.getElementById("price").value = value;
        break;
      default:
        break;
    }

    setPostInfo({
      ...postInfo,
      [e.target.name]: value,
    });
  };

  const handlePickerOnSelect = (emoji) => {
    setPostInfo({
      ...postInfo,
      desc: postInfo.desc + emoji.native,
    });
  };

  const handleSubmit = (files, allFiles) => {
    // console.log(files.map((f) => f.meta));
    allFiles.forEach((f) => f.remove());

    const fileList = [];
    files.forEach((file) => {
      // Upload files to firebase
      let fileName = uploadFileToFirebase(user.uid, file);
      fileList.push(fileName);
    });

    const post = {
      uuid: user.uid,
      desc: postInfo.desc,
      isfree: postInfo.isFree,
      price: postInfo.price,
      files: fileList,
    };

    postNewPost(post, () => {
      setTimeout(() => {
        window.location.href = "/userprofile";
      }, 3000);
    });
  };

  return (
    <div>
      <BrowserView>
        <Col style={{ backgroundColor: COLORS.athensGray }}>
          <div className="container" style={LAYOUTS.bodyStyle}>
            <div style={LAYOUTS.mainSectionStyle}>
              <PageTitle>New Post</PageTitle>
              <Row
                className="ps-0"
                style={{ paddingLeft: viewPadding, paddingRight: viewPadding }}
              >
                <Form
                  id="post_form"
                  method="post"
                  encType="multipart/form-data"
                  style={styles.formStyle}
                >
                  <Form.Group>
                    <Row>
                      <Col className="d-flex d-flex justify-content-start">
                        <Form.Check
                          type="checkbox"
                          id="isFree-checkbox"
                          label="Free Post"
                          onChange={(e) => handleChange(e)}
                          name="isFree"
                          checked={postInfo.isFree}
                          size="lg"
                          style={styles.formCheckboxStyle}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col className="d-flex d-flex justify-content-start">
                        <Form.Label
                          column
                          md={2}
                          class="text-left"
                          style={styles.formLabelStyle}
                        >
                          Price
                        </Form.Label>
                        <Form.Control
                          className="shadow-none"
                          type="number"
                          id="price"
                          placeholder={postInfo.price}
                          defaultValue={postInfo.price}
                          style={styles.formInputStyle}
                          disabled={disabledPrice}
                          name="price"
                          min="0"
                          max="200"
                          onChange={(e) => handleChange(e)}
                        />
                      </Col>
                    </Row>
                    <Row style={{ marginTop: 15 }}>
                      <Col md={8}>
                        <Form.Control
                          as="textarea"
                          rows={17}
                          id="desc"
                          name="desc"
                          maxLength="200"
                          placeholder="Compose new post..."
                          value={postInfo.desc}
                          onChange={handleChange}
                          style={styles.textAreaStyle}
                        />
                      </Col>
                      <Col className="d-flex justify-content-end" md={4}>
                        <Picker
                          title="Pick your emoji…"
                          emoji="point_up"
                          onSelect={(emoji) => handlePickerOnSelect(emoji)}
                        />
                      </Col>
                    </Row>
                    <Row style={styles.fileUploaderSectionStyle}>
                      <FileUploader
                        onSubmit={handleSubmit}
                        submitButtonContent={"Submit Post"}
                      />
                    </Row>
                  </Form.Group>
                </Form>
              </Row>
            </div>
          </div>
        </Col>
      </BrowserView>
      <MobileView>
        <Col className="container" style={LAYOUTS.mobileMainSectionStyle}>
          <PageTitle>New Post</PageTitle>
          <Row style={{ paddingLeft: viewPadding, paddingRight: viewPadding }}>
            <Form
              id="post_form"
              method="post"
              encType="multipart/form-data"
              style={styles.formStyle}
            >
              <Form.Group>
                <Row>
                  <Form.Check
                    type="checkbox"
                    id="isFree-checkbox"
                    label="Free Post"
                    onChange={(e) => handleChange(e)}
                    name="isFree"
                    checked={postInfo.isFree}
                    size="lg"
                    style={styles.formCheckboxStyle}
                  />
                </Row>
                <Row>
                  <Form.Label
                    column
                    md={2}
                    className="text-left"
                    style={styles.formLabelStyle}
                  >
                    Price
                  </Form.Label>
                  <Col md={2}>
                    <Form.Control
                      className="shadow-none"
                      type="number"
                      id="price"
                      placeholder={postInfo.price}
                      defaultValue={postInfo.price}
                      style={styles.formInputStyle}
                      disabled={disabledPrice}
                      name="price"
                      min="0"
                      max="200"
                      onChange={(e) => handleChange(e)}
                    />
                  </Col>
                </Row>
                <Row>
                  <Form.Control
                    as="textarea"
                    rows={4}
                    id="desc"
                    name="desc"
                    maxLength="200"
                    placeholder="Compose new post..."
                    value={postInfo.desc}
                    onChange={handleChange}
                    style={styles.textAreaStyle}
                  />
                </Row>
                <Row style={styles.fileUploaderSectionStyle}>
                  <FileUploader
                    onSubmit={handleSubmit}
                    submitButtonContent={"Submit Post"}
                  />
                </Row>
              </Form.Group>
            </Form>
          </Row>
        </Col>
      </MobileView>
    </div>
  );
};

const styles = {
  fileUploaderSectionStyle: {
    backgroundColor: "white",
    marginTop: 15,
    marginLeft: 0,
    marginRight: 0
  },
  formCheckboxStyle: {
    fontSize: 20,
    fontWeight: 400,
    marginTop: 15,
  },
  formGroupStyle: {
    borderBottom: "1px solid black",
  },
  formInputStyle: {
    width: 150,
    // border: "unset",
    // background: "unset",
  },
  formLabelStyle: {
    fontSize: 20,
    fontWeight: 400,
    textAlign: "left",
  },
  formStyle: {
    width: "100%",
  },
  textAreaStyle: {
    width: "100%",
    resize: "none",
  },
};

export default NewPost;
