import React, { useContext, useEffect } from "react";
import { Row, Col, Stack } from "react-bootstrap";
import { Link } from "react-router-dom";
import { MDBModal, MDBModalBody } from "mdbreact";
import { AuthContext } from "../../providers/AuthProvider";
import { BoldText, PrimaryText } from "../../components/texts/PageText";
import { COLORS } from "../../constants";
import AvatarIcon from "../profile/AvatarIcon";
import bookmarkImage from "../../assets/icons/bookmark.svg";
import helpImage from "../../assets/icons/help.svg";
import listImage from "../../assets/icons/lists.svg";
import logoutImage from "../../assets/icons/log-out.svg";
import settingsImage from "../../assets/icons/settings.svg";
import userImage from "../../assets/icons/navbar/user.svg";

const ProfileModal = (props) => {
  const { fetchUser, handleSignOut, user } = useContext(AuthContext);

  const ListItemText = (props) => {
    return <span style={styles.listItemTextStyle}>{props.children}</span>;
  };

  const ListDivider = () => {
    return <hr style={styles.dividerStyle} />;
  };

  const handleLogout = async (e) => {
    props.toggleModal();
    handleSignOut();
  };

  const ListItem = (props) => {
    const functionCall = props.function !== "undefined" ? props.function : null;

    return (
      <Row style={styles.listItemStyle}>
        <Col className="ps-0 d-flex justify-content-start">
          <Link to={props.link} onClick={functionCall}>
            <img
              id="profile_modal"
              src={props.image}
              width="30"
              style={styles.iconStyle}
              alt="settings"
            />
            <ListItemText>{props.title}</ListItemText>
          </Link>
        </Col>
      </Row>
    );
  };

  useEffect(() => {
    if (user.avatar === null) {
      fetchUser();
    }
  }, [user.avatar, fetchUser]);

  return (
    <MDBModal
      id="centralModalInfo"
      className="modal-dialog modal-md modal-notify"
      isOpen={props.show}
      side
      position="top-right"
      toggle={props.toggleModal}
    >
      <MDBModalBody>
        <Row>
          <Stack gap={1}>
            <AvatarIcon user={user} />
            <BoldText style={styles.headerItemStyle}>
              {user.displayName}
            </BoldText>
            <PrimaryText style={styles.headerItemStyle}>
              {user.userName}
            </PrimaryText>
            <PrimaryText>
              <BoldText style={styles.headerItemStyle}>
                {user.subscribersCount}
              </BoldText>
              {" Fans "}
              <BoldText>{user.subscribedCount}</BoldText>
              {"  Following"}
            </PrimaryText>
          </Stack>
        </Row>
        <ListDivider />
        <ListItem
          image={userImage}
          title="Profile"
          link="/userprofile"
          function={() => props.toggleModal()}
        />
        <ListItem
          image={bookmarkImage}
          title="Bookmarks"
          link="/bookmarks/all"
          function={() => props.toggleModal()}
        />
        <ListItem
          image={listImage}
          title="Lists"
          link="/my/lists"
          function={() => props.toggleModal()}
        />
        <ListItem
          image={settingsImage}
          title="Settings"
          link="/settings/profile"
          function={() => props.toggleModal()}
        />
        {/* <ListDivider image="" title="" />
        <ListItem
          image={require("../../assets/icons/credit-card.svg")}
          title="Your Cards"
          link="/my/payments"
          function={() => props.toggleModal()}
        /> */}
        <ListDivider />
        <ListItem
          image={helpImage}
          title={"Help & Support"}
          link="/support"
          function={() => props.toggleModal()}
        />
        <ListDivider />
        <ListItem
          image={logoutImage}
          title="Logout"
          link="/"
          function={(event) => handleLogout()}
        />
      </MDBModalBody>
    </MDBModal>
  );
};

export default ProfileModal;

const styles = {
  avatarStyle: {
    width: 80,
    height: 80,
    borderRadius: "50%",
  },
  avatarContainerStyle: {
    width: 85,
    height: 85,
    borderRadius: "50%",
    borderWidth: 3,
    borderColor: COLORS.lightPurple,
    borderStyle: "solid",
    lineHeight: "80px",
  },
  avatarInitialStyle: {
    backgroundColor: COLORS.darkPurple,
    color: "white",
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 44,
  },
  dividerStyle: {
    color: COLORS.darkPurple,
    backgroundColor: COLORS.darkPurple,
    height: 1,
  },
  headerItemStyle: {
    paddingLeft: 17,
  },
  iconStyle: {
    marginBottom: 10,
  },
  modalStyle: {
    marginTop: 20,
  },
  listItemStyle: {
    paddingLeft: 17,
    alignItems: "flex-start",
  },
  listItemButtonStyle: {
    background: "none",
    border: "none",
    outline: "none",
    padding: 0,
  },
  listItemTextStyle: {
    color: "black",
    fontSize: 30,
    fontWeight: "bold",
    marginLeft: 15,
  },
};
