import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyDcOAWg__UMexarXsTLeRdK0kQDOdDldj8",
  authDomain: "intrigueme-b7be5.firebaseapp.com",
  projectId: "intrigueme-b7be5",
  storageBucket: "intrigueme-b7be5.appspot.com",
  messagingSenderId: "991125854717",
  appId: "1:991125854717:web:74cc9187a2a28420c8e194",
};

export const app = initializeApp(firebaseConfig);