import React, { useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { Row, Col, Form } from "react-bootstrap";
import { MDBIcon } from "mdbreact";
import { COLORS, LAYOUTS } from "../../constants";
import { PageTitle } from "../../components/texts/PageTitles";
import { postContact } from "../../api/ApiManager";
import { validateEmail } from "../../lib/Validation";
import { BasicButton } from "../../components/buttons/Buttons";

const Contact = () => {
  const [content, setContent] = useState({ errors: [] });

  const handleChange = (e) => {
    setContent({ ...content, [e.target.name]: e.target.value });
  };

  const handleValidation = () => {
    let errors = [];
    let formIsValid = true;

    //name
    if (!content.name || content.name === "") {
      formIsValid = false;
      errors.push("Name can't be empty");
    }

    //email
    if (!content.email || content.email === "") {
      formIsValid = false;
      errors.push("Email can't be empty");
    }

    if (!validateEmail(content.email)) {
      formIsValid = false;
      errors.push("Email is not valid");
    }

    //message
    if (!content.message || content.message === "") {
      formIsValid = false;
      errors.push("Message can't be empty");
    }

    setContent({ ...content, ...{ errors } });
    return formIsValid;
  };

  const handleSend = () => {
    handleValidation()
      ? postContact(content.name, content.email, content.message, () => {
          setContent({ errors: [] });
          document.getElementById("content_form").reset();
          alert("Sent!");
        })
      : alert("Please enter valid values");
  };

  const HeaderSection = () => {
    return (
      <div>
        <PageTitle>Contact</PageTitle>
        <h2>
          We'd{" "}
          <MDBIcon
            icon="heart"
            size="1x"
            style={{ color: COLORS.darkPurple }}
          />{" "}
          to help!
        </h2>
        <h3>
          We like to create things with fun, open-minded people. Feel free to
          say hello!
        </h3>
      </div>
    );
  };

  return (
    <div>
      <BrowserView>
        <div className="container-fluid" style={LAYOUTS.bodyStyle}>
          <div style={LAYOUTS.mainSectionStyle}>
            <HeaderSection />
            <Row
              style={{
                width: "70%",
                marginTop: 20,
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <Col md={6} style={{ padding: 20 }}>
                <Form id="content_form" style={{ padding: 20 }}>
                  <Form.Group>
                    <Row style={{ marginBottom: 15 }}>
                      <Form.Control
                        className="shadow-none"
                        id="name"
                        name="name"
                        type="text"
                        placeholder="Your Name"
                        style={styles.formInputStyle}
                        onChange={(e) => handleChange(e)}
                      />
                    </Row>
                    <Row style={{ marginBottom: 15 }}>
                      <Form.Control
                        className="shadow-none"
                        id="email"
                        name="email"
                        type="text"
                        placeholder="Email"
                        style={styles.formInputStyle}
                        onChange={(e) => handleChange(e)}
                      />
                    </Row>
                    <Row style={{ marginBottom: 15 }}>
                      <Form.Control
                        as="textarea"
                        rows={4}
                        id="message"
                        name="message"
                        placeholder="Message"
                        style={styles.textAreaStyle}
                        onChange={(e) => handleChange(e)}
                      />
                    </Row>
                    <Row className="text-center">
                      <Col style={{ paddingRight: 0 }}>
                        {content.errors.length > 0
                          ? content.errors.map((error) => (
                              <Row>
                                <Col
                                  style={{ color: "red", textAlign: "center" }}
                                >
                                  {error}
                                </Col>
                              </Row>
                            ))
                          : null}
                        <BasicButton text="Send" onClick={handleSend} />
                      </Col>
                    </Row>
                  </Form.Group>
                </Form>
              </Col>
              <Col md={6} style={{ padding: 20 }}>
                <Row style={{ marginBottom: 15 }}>
                  <Col md={2} style={{paddingTop: 10}}>
                    <MDBIcon
                      icon="map-marker-alt"
                      size="2x"
                      style={{ color: COLORS.darkPurple, marginRight: 8 }}
                    />
                  </Col>
                  <Col md={10} style={styles.infoDetailsStyle}>
                    <Row>IntrigueMe</Row>
                    <Row>Las Vegas, NV, USA</Row>
                  </Col>
                </Row>
                <Row style={{ marginBottom: 15 }}>
                  <Col md={2} style={{paddingTop: 10}}>
                    <MDBIcon
                      icon="envelope"
                      size="2x"
                      style={{ color: COLORS.darkPurple, marginRight: 8 }}
                    />
                  </Col>
                  <Col md={10} style={styles.infoDetailsStyle}>
                    <Row>
                      <a href="mailto:support@intrigueme.com">
                        support@intrigueme.com
                      </a>
                    </Row>
                    <Row>
                      <a href="mailto:support@ccbill.com">support@ccbill.com</a>
                    </Row>
                  </Col>
                </Row>
                <Row style={{ width: 200, marginTop: 30, marginLeft: 0 }}>
                  {/* Facebook */}
                  <a
                    className="fb-ic"
                    href="https://fb.me/intriguemeofficial"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i
                      className="fab fa-facebook-f fa-lg mr-md-4 mr-3 fa-3x"
                      style={{ color: COLORS.darkPurple }}
                    >
                      {" "}
                    </i>
                  </a>
                  {/* Twitter */}
                  <a
                    className="tw-ic"
                    href="https://twitter.com/intrigueme_"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i
                      className="fab fa-twitter fa-lg mr-md-4 mr-3 fa-3x"
                      style={{ color: COLORS.darkPurple }}
                    >
                      {" "}
                    </i>
                  </a>
                  {/* Instagram */}
                  <a
                    className="ins-ic"
                    href="https://www.instagram.com/intriguemeofficial"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i
                      className="fab fa-instagram fa-lg mr-md-4 mr-3 fa-3x"
                      style={{ color: COLORS.darkPurple }}
                    >
                      {" "}
                    </i>
                  </a>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <Col className="container-fluid" style={LAYOUTS.mobileMainSectionStyle}>
          <HeaderSection />
          <Row
            style={{
              width: "90%",
              marginTop: 20,
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <Col xs={12} style={{ marginTop: 20 }}>
              <Form id="content_form">
                <Form.Group>
                  <Row style={{ marginBottom: 15 }}>
                    <Form.Control
                      className="shadow-none"
                      id="name"
                      name="name"
                      type="text"
                      placeholder="Your Name"
                      style={styles.formInputStyle}
                      onChange={(e) => handleChange(e)}
                    />
                  </Row>
                  <Row style={{ marginBottom: 15 }}>
                    <Form.Control
                      className="shadow-none"
                      id="email"
                      name="email"
                      type="text"
                      placeholder="Email"
                      style={styles.formInputStyle}
                      onChange={(e) => handleChange(e)}
                    />
                  </Row>
                  <Row style={{ marginBottom: 15 }}>
                    <Form.Control
                      as="textarea"
                      rows={4}
                      id="message"
                      name="message"
                      placeholder="Message"
                      style={styles.textAreaStyle}
                      onChange={(e) => handleChange(e)}
                    />
                  </Row>
                  <Row className="text-center" style={{}}>
                    <Col>
                      {content.errors.length > 0
                        ? content.errors.map((error) => (
                            <Row>
                              <Col
                                style={{ color: "red", textAlign: "center" }}
                              >
                                {error}
                              </Col>
                            </Row>
                          ))
                        : null}
                      <BasicButton text="Send" onClick={handleSend} />
                    </Col>
                  </Row>
                </Form.Group>
              </Form>
            </Col>
            <Col xs={12} style={{ padding: 20 }}>
              <Row style={{ marginBottom: 15 }}>
                <Col xs={3}>
                  <MDBIcon
                    icon="map-marker-alt"
                    size="2x"
                    style={{ color: COLORS.darkPurple, marginRight: 8 }}
                  />
                </Col>
                <Col xs={9} style={styles.infoDetailsStyle}>
                  <Row>IntrigueMe</Row>
                  <Row>Las Vegas, NV, USA</Row>
                </Col>
              </Row>
              <Row style={{ marginBottom: 15 }}>
                <Col xs={3}>
                  <MDBIcon
                    icon="envelope"
                    size="2x"
                    style={{ color: COLORS.darkPurple, marginRight: 8 }}
                  />
                </Col>
                <Col xs={9} style={styles.infoDetailsStyle}>
                  <Row>
                    <a href="mailto:support@intrigueme.com">
                      support@intrigueme.com
                    </a>
                  </Row>
                  <Row>
                    <a href="mailto:support@ccbill.com">support@ccbill.com</a>
                  </Row>
                </Col>
              </Row>
              <Row className="justify-content-center" style={{ marginTop: 30, marginLeft: 0 }}>
                {/* Facebook */}
                <a
                  className="fb-ic"
                  href="https://fb.me/intriguemeofficial"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i
                    className="fab fa-facebook-f fa-lg mr-md-4 mr-3 fa-3x"
                    style={{ color: COLORS.darkPurple }}
                  >
                    {" "}
                  </i>
                </a>
                {/* Twitter */}
                <a
                  className="tw-ic"
                  href="https://twitter.com/intrigueme_"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i
                    className="fab fa-twitter fa-lg mr-md-4 mr-3 fa-3x"
                    style={{ color: COLORS.darkPurple }}
                  >
                    {" "}
                  </i>
                </a>
                {/* Instagram */}
                <a
                  className="ins-ic"
                  href="https://www.instagram.com/intriguemeofficial"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i
                    className="fab fa-instagram fa-lg mr-md-4 mr-3 fa-3x"
                    style={{ color: COLORS.darkPurple }}
                  >
                    {" "}
                  </i>
                </a>
              </Row>
            </Col>
          </Row>
        </Col>
      </MobileView>
    </div>
  );
};

export default Contact;

const styles = {
  formInputStyle: {
    width: 300,
    marginButton: 10,
  },
  infoDetailsStyle: {
    textAlign: "left",
    lineHeight: 2,
  },
  infoIconStyle: {},
};
