import React, { useContext, useEffect, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { AuthContext } from "../../../providers/AuthProvider";
import { LAYOUTS } from "../../../constants";
import { BoldText } from "../../../components/texts/PageText";
import { getUserAccount, postUserAccount } from "../../../api/ApiManager";
import Header from "../../../components/profile/Header";
import Sidebar from "../../../components/profile/Sidebar";
import { Row, Col, Form, Container } from "react-bootstrap";
import MobileSidebar from "../../../components/profile/MobileSidebar";
import { BasicButton } from "../../../components/buttons/Buttons";

const AccountSettings = () => {
  const { user } = useContext(AuthContext);
  const [content, setContent] = useState({});

  const handleChange = (e) => {
    setContent({ ...content, [e.target.name]: e.target.value });
  };

  const handleSave = () => {
    const userAccountContent = {
      uuid: user.uid,
      phone: content.phone,
      twitter: content.twitter,
      instagram: content.instagram,
      google: content.google,
    };
    
    handleValidation()
      ? postUserAccount(userAccountContent, () => {
          alert("Updated!");
          window.location.reload();
        })
      : alert("Please enter valid values");
  };

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;

    setContent({ ...content, errors });
    return formIsValid;
  };

  useEffect(() => {
    getUserAccount(user.uid, (content) => {
      if (typeof content !== "undefined") {
        setContent(content);
      }
    });
  }, [user.uid]);
  
  return (
    <div>
      <BrowserView>
        <Container style={LAYOUTS.bodyStyle}>
          <Row style={LAYOUTS.mainSectionStyleBlank}>
            <Header />
            <Col md={3}>
              <Sidebar />
            </Col>
            <Col md={9} className="text-left">
              <h4>
                <BoldText>Account</BoldText>
              </h4>

              <Form.Group>
                <Row style={styles.group}>
                  <Form.Label
                    column
                    md={4}
                    className="text-left"
                    style={styles.label}
                  >
                    Email
                  </Form.Label>
                  <Col>
                    <Form.Control
                      className="shadow-none"
                      type="email"
                      placeholder="me@email.com"
                      style={styles.input || ""}
                      value={user.email}
                      name="email"
                      disabled={true}
                      onChange={(e) => handleChange(e)}
                      isInvalid={
                        content.errors
                          ? content.errors.email
                            ? true
                            : false
                          : false
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {content.errors
                        ? content.errors.email
                          ? content.errors.email.msg
                          : ""
                        : ""}
                    </Form.Control.Feedback>
                  </Col>
                </Row>

                <Row style={styles.group}>
                  <Form.Label
                    column
                    md={4}
                    className="text-left"
                    style={styles.label}
                  >
                    Phone Number
                  </Form.Label>
                  <Col>
                    <Form.Control
                      className="shadow-none"
                      type="text"
                      placeholder="123-456-7890"
                      style={styles.input}
                      value={content.phone || ""}
                      name="phone"
                      pattern="[7-9]{1}[0-9]{9}"
                      onChange={(e) => handleChange(e)}
                      isInvalid={
                        content.errors
                          ? content.errors.phone
                            ? true
                            : false
                          : false
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {content.errors
                        ? content.errors.phone
                          ? content.errors.phone.msg
                          : ""
                        : ""}
                    </Form.Control.Feedback>
                  </Col>
                </Row>

                <Row className="pt-3">
                  <BoldText>Linked Accounts</BoldText>{" "}
                </Row>

                <Row className="pt-3" style={styles.group}>
                  <Form.Label
                    column
                    md={4}
                    className="text-left"
                    style={styles.label}
                  >
                    Twitter
                  </Form.Label>
                  <Col>
                    <Form.Control
                      className="shadow-none"
                      type="text"
                      style={styles.input}
                      placeholder="@emmasophia"
                      value={content.twitter || ""}
                      name="twitter"
                      onChange={(e) => handleChange(e)}
                    />
                  </Col>
                </Row>

                <Row style={styles.group}>
                  <Form.Label
                    column
                    md={4}
                    className="text-left"
                    style={styles.label}
                  >
                    Instagram
                  </Form.Label>
                  <Col>
                    <Form.Control
                      className="shadow-none"
                      style={styles.input}
                      type="text"
                      placeholder="emmasophia123"
                      value={content.instagram || ""}
                      name="instagram"
                      onChange={(e) => handleChange(e)}
                    />
                  </Col>
                </Row>
                <Row style={styles.group}>
                  <Form.Label
                    column
                    md={4}
                    className="text-left"
                    style={styles.label}
                  >
                    Google
                  </Form.Label>
                  <Col>
                    <Form.Control
                      className="shadow-none"
                      style={styles.input}
                      type="text"
                      placeholder="emma123@gmail.com"
                      value={content.google || ""}
                      name="google"
                      onChange={(e) => handleChange(e)}
                    />
                  </Col>
                </Row>
                <Row className="text-right">
                  <Col>
                    <BasicButton text="Save" onClick={handleSave} />
                  </Col>
                </Row>
              </Form.Group>
            </Col>
          </Row>
        </Container>
      </BrowserView>

      <MobileView>
        <div className="container header" style={LAYOUTS.profileHeaderStyle}>
          <Row>
            <Header />
          </Row>
        </div>
        <div className="container pb-4 headerBody" style={LAYOUTS.bodyStyle}>
          <Row style={LAYOUTS.mainSectionStyleBlank}>
            <MobileSidebar />
            <span style={styles.profileComponentHeader}>Account</span>
            <Col md={12} className="text-left mt-2">
              <div>
                <Form.Group>
                  <Row style={styles.group}>
                    <Form.Label
                      column
                      md={4}
                      className="text-left"
                      style={styles.label}
                    >
                      Email
                    </Form.Label>
                    <Col>
                      <Form.Control
                        className="shadow-none"
                        type="email"
                        placeholder="me@email.com"
                        value={user.email}
                        style={styles.input}
                        name="email"
                        onChange={(e) => handleChange(e)}
                        isInvalid={
                          content.errors
                            ? content.errors.email
                              ? true
                              : false
                            : false
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {content.errors
                          ? content.errors.email
                            ? content.errors.email.msg
                            : ""
                          : ""}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>

                  <Row style={styles.group}>
                    <Form.Label
                      column
                      md={4}
                      className="text-left"
                      style={styles.label}
                      name="phoneNumber"
                    >
                      Phone Number
                    </Form.Label>
                    <Col>
                      <Form.Control
                        className="shadow-none"
                        type="text"
                        placeholder="123-456-7890"
                        style={styles.input}
                        value={content.phone || ""}
                        name="phone"
                        pattern="[7-9]{1}[0-9]{9}"
                        onChange={(e) => handleChange(e)}
                        isInvalid={
                          content.errors
                            ? content.errors.phone
                              ? true
                              : false
                            : false
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {content.errors
                          ? content.errors.phone
                            ? content.errors.phone.msg
                            : ""
                          : ""}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>

                  <Row className="pt-3">
                    <BoldText>Linked Accounts</BoldText>{" "}
                  </Row>

                  <Row className="pt-3" style={styles.group}>
                    <Form.Label
                      column
                      md={4}
                      className="text-left"
                      style={styles.label}
                    >
                      Twitter
                    </Form.Label>
                    <Col>
                      <Form.Control
                        className="shadow-none"
                        style={styles.input}
                        type="text"
                        placeholder="@emmasophia"
                        value={content.twitter || ""}
                        onChange={(e) => handleChange(e)}
                      />
                    </Col>
                  </Row>

                  <Row style={styles.group}>
                    <Form.Label
                      column
                      md={4}
                      className="text-left"
                      style={styles.label}
                    >
                      Instagram
                    </Form.Label>
                    <Col>
                      <Form.Control
                        className="shadow-none"
                        style={styles.input}
                        type="text"
                        placeholder="emmasophia123"
                        value={content.instagram || ""}
                        onChange={(e) => handleChange(e)}
                      />
                    </Col>
                  </Row>
                  <Row style={styles.group}>
                    <Form.Label
                      column
                      md={4}
                      className="text-left"
                      style={styles.label}
                    >
                      Google
                    </Form.Label>
                    <Col>
                      <Form.Control
                        className="shadow-none"
                        style={styles.input}
                        type="text"
                        placeholder="emma123@gmail.com"
                        value={content.google || ""}
                        onChange={(e) => handleChange(e)}
                      />
                    </Col>
                  </Row>
                  <Row className="text-right">
                    <Col>
                      <BasicButton text="Save" onClick={handleSave} />
                    </Col>
                  </Row>
                </Form.Group>
              </div>
            </Col>
          </Row>
        </div>
      </MobileView>
    </div>
  );
};

export default AccountSettings;

const styles = {
  headerStyle: {
    paddingLeft: 25,
    height: 300,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  profileComponentHeader: {
    fontSize: "25px",
    fontWeight: 600,
  },
  input: {
    border: "unset",
    background: "unset",
  },
  label: {
    fontWeight: 400,
  },
  group: {
    borderBottom: "1px solid black",
  },
};
