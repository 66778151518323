import React from "react";
import { Row } from "react-bootstrap";
import ContentDetailCard from "../cards/ContentDetailCard/ContentDetailCard";

const UserFeed = (props) => {
  const contentItem = props.contentList.map((content) => {
    return <ContentDetailCard key={content.postId} content={content} />;
  });

  return (
    <div className="container-fluid" style={styles.sectionStyle}>
      <Row>
        <div className="col-12" style={{ paddingLeft: 0, paddingRight: 0 }}>
          {contentItem}
        </div>
      </Row>
    </div>
  );
};

export default UserFeed;

const styles = {
  sectionStyle: {
    marginBottom: 50,
  },
};
