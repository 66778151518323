import React, { useContext, useEffect, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { LAYOUTS } from "../../constants";
import { AuthContext } from "../../providers/AuthProvider";
import "./Bookmarks.css";
import { COLORS } from "../../constants";
import { Row, Col, Container } from "react-bootstrap";
import Sidebar from "../../components/bookmark/Sidebar";
import ContentDetailCard from "../../components/cards/ContentDetailCard/ContentDetailCard";
import { getUserBookmarks } from "../../api/ApiManager";

const Video = () => {
  const { user } = useContext(AuthContext);
  const [content, setContent] = useState();

  useEffect(() => {
    getUserBookmarks(user.uid, (content) => {
      if (typeof content !== "undefined") {
        setContent(content);
      }
    });
  }, [user.uid]);

  const BodySection = () => {
    return (
      <Row>
        {/* Loop through over column when we get user bookmarks and paste the bookmark layout*/}
        <Col md={12} xs={12}>
          {content
            ? content.map((contentData, i) =>
                contentData["postType"] === "Videos" ? (
                  <ContentDetailCard key={i} content={contentData} />
                ) : (
                  "No Videos Bookmarks"
                )
              )
            : "No Bookmarks"}
        </Col>
      </Row>
    );
  };

  return (
    <div>
      <BrowserView>
        <div className="container pb-4" style={LAYOUTS.bodyStyle}>
          <Row style={LAYOUTS.mainSectionStyleBlank}>
            <Col md={3}>
              <Sidebar />
            </Col>
            <Col md={8} className="text-left">
              <span style={styles.profileComponentHeader}>Videos</span>
              <BodySection />
            </Col>
          </Row>
        </div>
      </BrowserView>
      <MobileView>
        <Container>
          <Sidebar />
          <Col className="text-left">
            <span style={styles.profileComponentHeader}>Videos</span>
          </Col>
          <BodySection />
        </Container>
      </MobileView>
    </div>
  );
};

const styles = {
  cardStyle: {
    display: "inline-block",
    backgroundColor: "white",
    borderColor: COLORS.darkPurple,
    borderStyle: "solid",
    borderWidth: 2,
    marginBottom: 25,
    minWidth: "75%",
    maxWidth: "75%",
  },
  profileComponentHeader: {
    fontSize: "25px",
    fontWeight: 600,
  },
};
export default Video;
