import React, { useContext, useEffect, useState } from "react";
import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";
import { BrowserView, MobileView, isMobile } from "react-device-detect";
import { Row, Col, Container, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../providers/AuthProvider";
import ContentDetailCard from "../../components/cards/ContentDetailCard/ContentDetailCard";
import CommentCard from "../../components/cards/CommentCard";
import { LAYOUTS } from "../../constants";
import { getPostComments, postNewComment } from "../../api/ApiManager";
import { BackButton, BasicButton } from "../../components/buttons/Buttons";

const PostComments = () => {
  const { user } = useContext(AuthContext);
  const { postid } = useParams();
  const [post, setPost] = useState([]);
  const [text, setText] = useState("");
  const [updateButtonDisable, setUpdateButtonDisable] = useState(true);

  const handleChange = (e) => {
    switch (e.target.name) {
      case "comment":
        setText(e.target.value);
        break;
      default:
        break;
    }
  };

  const handlePickerOnSelect = (emoji) => {
    setText(text + emoji.native);
  };

  const handleSubmit = () => {
    const comments = {
      uuid: user.uid,
      postid: postid,
      comments: text,
    };

    postNewComment(comments, () => {
      window.location.reload();
    });
  };

  const CommentSection = () => {
    const commentsItems =
      typeof post.comments === "undefined"
        ? null
        : post.comments.map((comment) => {
            return <CommentCard key={comment.commentsId} content={comment} />;
          });

    return <Row style={styles.commentSectionStyle}>{commentsItems}</Row>;
  };

  const PostSection = () => {
    if (typeof post === "undefined" || post.length === 0) {
      return null;
    } else if (isMobile) {
      return (
        <ContentDetailCard key={post.postId} content={post} singleMode={true} />
      );
    } else {
      return (
        <Row className="justify-content-center">
          <ContentDetailCard
            key={post.postId}
            content={post}
            singleMode={true}
          />
        </Row>
      );
    }
  };

  useEffect(() => {
    getPostComments(postid, setPost);
  }, [postid]);

  useEffect(() => {
    if (text.length === 0) {
      setUpdateButtonDisable(true);
    } else {
      setUpdateButtonDisable(false);
    }
  }, [text]);

  return (
    <div>
      <BrowserView>
        <Container style={LAYOUTS.bodyStyle}>
          <Col style={LAYOUTS.mainSectionStyle}>
            <PostSection />
            <CommentSection />
            <Row style={styles.textInputSectionStyle}>
              <Col md={12}>
                <Form
                  id="comments_form"
                  method="post"
                  encType="multipart/form-data"
                >
                  <Form.Group>
                    <Row>
                      <Col md={8} style={{ paddingLeft: 35 }}>
                        <Row>
                          <Form.Control
                            as="textarea"
                            rows={15}
                            id="comment"
                            name="comment"
                            maxLength="200"
                            placeholder="Compose new comment..."
                            value={text}
                            onChange={(e) => handleChange(e)}
                            style={styles.textAreaStyle}
                          />
                        </Row>
                        <Row className="d-flex justify-content-end">
                          <BackButton onClick={() => window.history.back()} />
                          <BasicButton
                            text="Send"
                            onClick={handleSubmit}
                            disabled={updateButtonDisable}
                          />
                        </Row>
                      </Col>
                      <Col className="d-flex justify-content-end" md={4}>
                        <Picker
                          title="Pick your emoji…"
                          emoji="point_up"
                          onSelect={(emoji) => handlePickerOnSelect(emoji)}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                </Form>
              </Col>
            </Row>
          </Col>
        </Container>
      </BrowserView>
      <MobileView>
        <Container fluid style={LAYOUTS.mainSectionStyle}>
          <Col style={{ paddingLeft: 0 }}>
            <PostSection />
            <CommentSection />
            <Row style={styles.textInputSectionStyle}>
              <Col md={12}>
                <Form
                  id="comments_form"
                  method="post"
                  encType="multipart/form-data"
                >
                  <Form.Group>
                    <Row>
                      <Form.Control
                        className="shadow-none"
                        as="textarea"
                        id="comment"
                        placeholder="Compose new comment..."
                        name="comment"
                        maxLength="200"
                        rows={15}
                        value={text}
                        onChange={(e) => handleChange(e)}
                        style={styles.textAreaStyle}
                      />
                    </Row>
                    <Row className="d-flex justify-content-end">
                      <BackButton onClick={() => window.history.back()} />
                      <BasicButton
                        text="Send"
                        onClick={handleSubmit}
                        disabled={updateButtonDisable}
                      />
                    </Row>
                    <Row className="d-flex justify-content-end">
                      <Picker
                        title="Pick your emoji…"
                        emoji="point_up"
                        onSelect={(emoji) => handlePickerOnSelect(emoji)}
                      />
                    </Row>
                  </Form.Group>
                </Form>
              </Col>
            </Row>
          </Col>
        </Container>
      </MobileView>
    </div>
  );
};

export default PostComments;

const styles = {
  commentSectionStyle: {
    paddingLeft: isMobile ? -0 : 25,
    paddingRight: isMobile ? -0 : 35,
  },
  textInputSectionStyle: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
    paddingRight: isMobile ? 0 : 20,
  },
  textAreaStyle: {
    resize: "none",
  },
};
//
