import React from "react";
import { Row, Col, Card, Accordion } from 'react-bootstrap';

const GeneralQuestion = (props) => {

  return (

<Row>


<Col md={12}>

<h6 className="pb-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit, ed doeiusmod tempor incididunt ut labore et dolore.</h6>
<Accordion defaultActiveKey="0">
  <Card>
    <Accordion.Toggle as={Card.Header} eventKey="0">
    Lorem ipsum dolor sit amet, consectetur adipiscing elit?
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="0">
      <Card.Body>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit
in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
mollit anim id est laborum.</Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Accordion.Toggle as={Card.Header} eventKey="1">
    Lorem ipsum dolor sit amet, consectetur adipiscing elit?
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="1">
      <Card.Body>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit
in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
mollit anim id est laborum.</Card.Body>
    </Accordion.Collapse>
  </Card>
</Accordion>

</Col>



</Row>



  );
};

export default GeneralQuestion;