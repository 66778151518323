import React from "react";
import { Row, Col } from "react-bootstrap";
import { MDBIcon } from "mdbreact";
import { isMobile } from "react-device-detect";
import AvatarIcon from "../../components/profile/AvatarIcon";
import { COLORS } from "../../constants";
import { dateTime } from "../../lib/utils";
import ContentImage from "../images/ContentImage";
import { getContentUrl } from "../../api/StorageManager";

const MessageDetailCard = (props) => {
  const { message } = props;

  const MediaObject = () => {
    if (message.files.length > 1) {
      return null; //<Slider />;
    } else if (message.files.length === 0) {
      return null;
    } else {
      const fileName =
        message.files.length > 0 ? message.files[0].fileName : "";
      const ext = fileName.split(".").pop().toLowerCase();
      const mediaUrl = getContentUrl(message.uuid, fileName);
      const imgStyle = isMobile ? styles.imageMobileStyle : styles.imageStyle;

      switch (ext) {
        case "":
          return (
            <div style={styles.lockedContentStyle}>
              <MDBIcon
                icon="lock"
                size="3x"
                style={{ color: COLORS.darkPurple }}
              />
            </div>
          );
        case "png":
        case "jpg":
        case "jpeg":
          return (
            <ContentImage
              src={mediaUrl}
              style={imgStyle}
              alt="image"
              hasAccess={message.hasAccess}
            />
          );
        case "mov":
        case "mp4":
          return (
            <video
              style={{
                width: "100%",
                height: "100%",
                position: "relative",
                top: 0,
                left: 0,
                objectFit: "cover",
              }}
              controls
              src={mediaUrl}
            />
          );
        default:
          return <div />;
      }
    }
  };

  const MediaSection = () => {
    return (
      <Row style={styles.imageRowStyle}>
        <MediaObject />
      </Row>
    );
  };

  return (
    <div className="container" style={styles.cardStyle}>
      <Row>
        <Col className="align-self-end" style={styles.leftSectionStyle}>
          <AvatarIcon user={message} size={40} />
        </Col>
        <Col style={styles.rightSectionStyle}>
          <Row>
            <Col className="d-flex justify-content-end">
              <span style={styles.dateStyle}>
                {dateTime(message.timestamp)}
              </span>
            </Col>
          </Row>
          <MediaSection />
          <Row>
            <span style={styles.textStyle}>{message.message}</span>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default MessageDetailCard;

const styles = {
  cardStyle: {
    marginBottom: 15,
    paddingBottom: 20,
  },
  dateStyle: {
    color: COLORS.darkPurple,
    fontSize: 16,
    fontWeight: "bold",
    marginRight: 10,
  },
  imageStyle: {
    height: "auto",
    width: "68%",
  },
  imageMobileStyle: {
    height: "auto",
    width: "100%",
  },
  leftSectionStyle: {
    paddingLeft: 10,
    maxWidth: 60,
  },
  rightSectionStyle: {
    marginTop: 10,
  },
  textStyle: {
    color: "black",
    fontSize: 20,
    textAlign: "left",
    marginTop: 25,
  },
};
