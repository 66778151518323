import React, { useContext, useState } from "react";
import { Row, Col, Container, Stack } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { AuthContext } from "../../providers/AuthProvider";
import { BoldText, PrimaryText } from "../../components/texts/PageText";
import AvatarIcon from "../../components/profile/AvatarIcon";
import { Link } from "react-router-dom";
import { timeAgo } from "../../lib/utils";
import {
  deleteRemoveComment,
  deleteRemoveCommentLike,
  postAddCommentLike,
} from "../../api/ApiManager";
import { ClearButton } from "../buttons/Buttons";
import { COLORS } from "../../constants";
import { YesNoModal } from "../modals/Modals";
import AddEditCommentModal from "../modals/AddEditCommentModal";
import heartImage from "../../assets/icons/heart.svg";
import heartFilledImage from "../../assets/icons/heart-filled.svg";

const CommentCard = (props) => {
  const [comments, setComments] = useState(props.content);
  const { user } = useContext(AuthContext);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const avatarSectionStyle = isMobile
    ? styles.avatarSectionStyleMobile
    : styles.avatarSectionStyle;

  // Sections
  const DeleteItem = () => {
    return user.displayName === comments.displayName ? (
      <ClearButton onClick={toggleDeleteModal}>
        <span style={styles.interactionLinkItemStyle}>Delete</span>
      </ClearButton>
    ) : null;
  };

  const EditItem = () => {
    return user.displayName === comments.displayName ? (
      <ClearButton onClick={toggleEditModal}>
        <span style={styles.interactionLinkItemStyle}>Edit</span>
      </ClearButton>
    ) : null;
  };

  // Handlers
  const handleDelete = () => {
    deleteRemoveComment(comments.commentsId, user.uid, () => {
      window.location.reload();
      toggleDeleteModal();
    });
  };

  const toggleLike = () => {
    const currentLike = !comments.isLiked;

    if (currentLike) {
      postAddCommentLike(comments.commentsId, user.uid, () => {});
      setComments({
        ...comments,
        isLiked: currentLike,
        likes: parseInt(comments.likes) + 1,
      });
    } else {
      deleteRemoveCommentLike(comments.commentsId, user.uid, () => {});
      setComments({
        ...comments,
        isLiked: currentLike,
        likes: parseInt(comments.likes) - 1,
      });
    }
  };

  // Modals
  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  const toggleEditModal = () => {
    setShowEditModal(!showEditModal);
  };

  const likedButtonState = (isLiked) => {
    return isLiked ? heartFilledImage : heartImage;
  };

  return (
    <Container style={styles.cardStyle}>
      <AddEditCommentModal
        show={showEditModal}
        toggleModal={toggleEditModal}
        comments={comments}
        editMode={true}
      />
      <YesNoModal
        show={showDeleteModal}
        toggleModal={toggleDeleteModal}
        title="Delete"
        message="Are you sure you want to delete this comment?"
        yesClick={handleDelete}
      />
      <Row>
        <Col style={avatarSectionStyle}>
          <AvatarIcon user={comments} size={55} />
        </Col>
        <Col style={styles.contentSectionStyle}>
          <Row style={styles.userSectionStyle}>
            <Col className="ps-0 d-flex justify-content-start">
              <Link to={`../im/${comments.userName}`}>
                <BoldText>{comments.displayName}</BoldText>
              </Link>
            </Col>
          </Row>
          <Row style={styles.commentSectionStyle}>
            <Col className="ps-0 d-flex justify-content-start">
              <PrimaryText>{comments.comment}</PrimaryText>
            </Col>
          </Row>
          <Row style={styles.interactionSectionStyle}>
            <Col className="ps-0">
              <Stack direction="horizontal" gap={1}>
                <span style={styles.interactionItemStyle}>
                  {timeAgo(comments.createdAt)}
                </span>
                <span
                  style={styles.interactionItemStyle}
                >{`${comments.likes} Likes`}</span>
                <EditItem />
                <DeleteItem />
              </Stack>
            </Col>
          </Row>
        </Col>
        <Col style={styles.likeButtonSectionStyle}>
          <button onClick={toggleLike} style={styles.clearButtonStyle}>
            <img
              id="like_button"
              src={likedButtonState(comments.isLiked)}
              width={isMobile ? 15 : 25}
              alt="like link"
            />
          </button>
        </Col>
      </Row>
    </Container>
  );
};

export default CommentCard;

const styles = {
  avatarSectionStyle: {
    minWidth: 70,
    maxWidth: 70,
    paddingTop: 5,
  },
  avatarSectionStyleMobile: {
    paddingLeft: 0,
    minWidth: 60,
    maxWidth: 60,
    paddingTop: 5,
  },
  avatarStyle: {
    width: 50,
    height: 50,
    borderRadius: "50%",
  },
  cardStyle: {
    marginBottom: 5,
    minHeight: 90,
    minWidth: "99%",
    maxWidth: "99%",
    paddingBottom: 10,
    paddingTop: 10,
  },
  clearButtonStyle: {
    background: "none",
    border: "none",
    outline: "none",
    padding: 0,
    minHeight: 52,
  },
  commentSectionStyle: {
    paddingLeft: 10,
  },
  contentSectionStyle: {},
  interactionItemStyle: {
    paddingLeft: 10,
  },
  interactionLinkItemStyle: {
    color: COLORS.darkPurple,
    paddingLeft: 10,
  },
  interactionSectionStyle: {
    color: COLORS.darkGray,
  },
  likeButtonSectionStyle: {
    minWidth: 15,
    maxWidth: 15,
    display: "flex",
    justifyContent: "center",
  },
  userSectionStyle: {
    paddingLeft: 10,
  },
};
