import React from "react";
import { Link } from "react-router-dom";
import { Col, Row, Container } from "react-bootstrap";
import { LAYOUTS } from "../constants";
import topBackgroundImage from "../assets/images/landing/top_background.jpg";
import logoImage from "../assets/images/logos/im_logo_white.svg";

const NotFound = () => {
  return (
    <div>
      <Container fluid style={LAYOUTS.bodyStyle}>
        <Row
          style={{
            ...styles.topSectionStyle,
            ...LAYOUTS.mainSectionStyleBlank,
          }}
        >
          <Col>
            <Row className="justify-content-center">
              <img
                src={logoImage}
                style={styles.logoStyle}
                alt="logo"
              />
            </Row>
            <Row
              className="justify-content-center"
              style={{ marginTop: 100, marginBottom: 100 }}
            >
              <h1 style={styles.headerTextStyle}>
                404 - Not Found! How did you get here?
              </h1>
            </Row>
            <Row className="justify-content-center">
              <Link to="/">
                <h2 style={styles.headerTextStyle}>Go Home</h2>
              </Link>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default NotFound;

const styles = {
  headerTextStyle: {
    color: "white",
    fontSize: 48,
    fontWeight: "bold",
    letterSpacing: 3,
    textAlign: "center",
  },
  logoStyle: {
    height: "auto",
    width: 200,
  },
  topSectionStyle: {
    backgroundImage: `url(${topBackgroundImage})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    textAlign: "center",
    paddingTop: 10,
    paddingBottom: 30,
  },
};
