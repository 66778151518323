import React from "react";
import { COLORS } from "../../constants";

const BoldText = (props) => {
  return <span style={styles.boldRightTextStyle}>{props.children}</span>;
};

const PagePlaceholderText = (props) => {
  return <span style={styles.pagePlaceholderTextStyle}>{props.children}</span>;
};

const PrimaryText = (props) => {
  return (
    <span
      className="second-word-formatting"
      style={styles.primaryRightTextStyle}
    >
      {props.children}
    </span>
  );
};

const BlackContentText = (props) => {
  return (
    <span
      className="second-word-formatting"
      style={styles.blackContentTextStyle}
    >
      {props.children}
    </span>
  );
}

export { BoldText, PagePlaceholderText, PrimaryText, BlackContentText};

const styles = {
  blackContentTextStyle: {
    fontSize: 18,
    fontWeight: "bold",
  },
  boldRightTextStyle: {
    color: COLORS.darkPurple,
    textAlign: "left",
    fontWeight: "bold",
    fontSize: 18,
  },
  pagePlaceholderTextStyle: {
    color: COLORS.darkPurple,
    fontSize: 54,
    fontWeight: "bold",
  },
  primaryRightTextStyle: {
    color: COLORS.darkPurple,
    textAlign: "left",
    fontSize: 18,
  },
};
