import React from "react";
import { Row, Form, Col, Button } from 'react-bootstrap';
import Sidebar from "../../../../components/profile/Sidebar";
import Header from "../../../../components/profile/Header";
import { BrowserView, MobileView } from "react-device-detect";
import { LAYOUTS } from "../../../../constants";
import { BoldText } from "../../../../components/texts/PageText";
import MobileSidebar from "../../../../components/profile/MobileSidebar";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { Link } from "react-router-dom";

const SmsNotification = (props) => {

  const BodySection = () => {
    return (
      <div>
        <Row>
          <Col xs={12} className="mb-2">
            <h5 className="text-muted font-weight-bold">
              Subscriptions and following{" "}
            </h5>
          </Col>
          <Col xs={12}>
            <Form>
              <Form.Check type="checkbox" label="New Stream" />
            </Form>
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <div>
      <BrowserView>
        <div className="container" style={LAYOUTS.profileHeaderStyle}>
          <Row>
            <Header />
          </Row>
        </div>
        <div className="container pb-4" style={LAYOUTS.bodyStyle}>
          <Row>
            <Col md={3}>
              <Sidebar />
            </Col>
            <Col md={9} className="text-left">
              <h4>
                <BoldText>SMS Notifications</BoldText>
              </h4>
              <hr style={styles.hr} />
              <BodySection />

              <Link to={"/settings/notification"}>
                <Button
                  variant="secondary"
                  size="md"
                  style={{ float: "right" }}
                >
                  <span style={{ float: "left" }}>
                    <FaArrowAltCircleLeft size={17} />
                  </span>
                  <span style={styles.notificationBackbutton}>Back</span>
                </Button>
              </Link>
            </Col>
          </Row>
        </div>
      </BrowserView>
      <MobileView>
        <div className="container header" style={LAYOUTS.profileHeaderStyle}>
          <Row>
            {" "}
            <Header />{" "}
          </Row>
        </div>
        <div className="container pb-4 headerBody" style={LAYOUTS.bodyStyle}>
          <Row>
            <MobileSidebar />
            <span style={styles.profileComponentHeader}>SMS Notifications</span>
            <Col md={12} className="text-left mt-2">
              <BodySection />

              <Link to={"/settings/notification"}>
                <Button
                  variant="secondary"
                  size="md"
                  style={{ float: "right" }}
                >
                  <span style={{ float: "left" }}>
                    <FaArrowAltCircleLeft size={17} />
                  </span>
                  <span style={styles.notificationBackbutton}>Back</span>
                </Button>
              </Link>
            </Col>
          </Row>
        </div>
      </MobileView>
    </div>
  );
};

const styles = {
  headerStyle: {
    paddingLeft: 25,
    height: 300,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  profileComponentHeader: {
    fontSize: "25px",
    fontWeight: 600,
  },
  notificationBackbutton: {
    paddingLeft: "10px",
    fontWeight: "600",
    fontSize: "small",
  },
};

export default SmsNotification;
