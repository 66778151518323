import React, { useContext, useEffect, useState } from "react";
import { ListGroup } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import { BrowserView, MobileView } from "react-device-detect";
import { LAYOUTS } from "../../constants";
import { BoldText } from "../../components/texts/PageText";
import "./ListSettings.css";
import { Link } from "react-router-dom";
import { AuthContext } from "../../providers/AuthProvider";
import { getUserLists } from "../../api/ApiManager";

const ListSettings = (props) => {
  const { user } = useContext(AuthContext);
  const [lists, setLists] = useState({});

  const BodySection = () => {
    const followingString =
      typeof lists.following !== "undefined"
        ? `${lists.following.peopleCount} people   ${lists.following.postCount} posts`
        : "empty";

    return (
      <ListGroup style={{ textAlign: "left" }}>
        <ListGroup.Item>
          <BoldText>CUSTOM ORDER</BoldText>
        </ListGroup.Item>

        <Link to="/my/lists/following">
          {" "}
          <ListGroup.Item className="TFList bold-heading">
            {" "}
            Following <br />
            <span className="text-light">{followingString}</span>
          </ListGroup.Item>
        </Link>

        <Link to="/my/lists/close_friends">
          <ListGroup.Item className="TFList bold-heading">
            Close Friends
            <br />
            <span className="text-light">empty</span>
          </ListGroup.Item>
        </Link>

        <Link to="/my/lists/bookmarks">
          <ListGroup.Item className="TFList bold-heading">
            Bookmarks
            <br />
            <span className="text-light">empty</span>
          </ListGroup.Item>
        </Link>

        <Link to="/my/lists/restricted">
          <ListGroup.Item className="TFList bold-heading">
            Restricted
            <br />
            <span className="text-light">empty</span>
          </ListGroup.Item>
        </Link>

        <Link to="/my/lists/blocked">
          <ListGroup.Item className="TFList bold-heading">
            Blocked
            <br />
            <span className="text-light">empty</span>
          </ListGroup.Item>
        </Link>
      </ListGroup>
    );
  };

  useEffect(() => {
    if (user.uid !== "") {
      getUserLists(user.uid, setLists);
    }
  }, [user.uid]);

  return (
    <div>
      <BrowserView>
        <div className="container pb-4" style={LAYOUTS.bodyStyle}>
          <Row style={LAYOUTS.mainSectionStyleBlank}>
            <Col md={12} className="text-left">
              <BodySection />
            </Col>
          </Row>
        </div>
      </BrowserView>
      <MobileView>
        <div className="container pb-4 headerBody" style={LAYOUTS.bodyStyle}>
          <Row>
            <Col md={12} className="text-left mt-2">
              <BodySection />
            </Col>
          </Row>
        </div>
      </MobileView>
    </div>
  );
};

export default ListSettings;
