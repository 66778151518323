import React, { useState } from "react";
import { Col, Row, Form, Button, Modal } from "react-bootstrap";

const BundleSubscriptionModal = (props) => {
  const [selectedDiscount, setDiscount] = useState({discount: "0"});
  const [selectedDuration, setDuration] = useState({duration: "3"});

  const discount = [
    { id: "1", value: "0", text: "0% discount" },
    { id: "2", value: "5", text: "5% discount" },
    { id: "3", value: "10", text: "10% discount" },
    { id: "4", value: "15", text: "15% discount" },
    { id: "5", value: "20", text: "20% discount" },
    { id: "6", value: "25", text: "25% discount" },
    { id: "7", value: "30", text: "30% discount" },
    { id: "8", value: "35", text: "35% discount" },
    { id: "9", value: "40", text: "40% discount" },
    { id: "10", value: "45", text: "45% discount" },
    { id: "11", value: "50", text: "50% discount" }
  ];
  const duration = [
    { id: "1", value: "3", text: "3 months" },
    { id: "2", value: "6", text: "6 months" },
    { id: "3", value: "9", text: "9 months" },
    { id: "4", value: "12", text: "12 months" }
  ];

  const onDiscountSelect = (e) => {
    setDiscount({ ...selectedDiscount, discount: e.target.value });
  }
  const onDurationSelect = (e) => {
    setDuration({ ...selectedDuration, duration: e.target.value });
  }
  const saveBundle = () => {
    alert('subscription bundle updated!')
    props.hide()
    setDiscount({ ...selectedDiscount, discount: "0" });
    setDuration({ ...selectedDuration, duration: "3" });
  }
  return (
    <Modal show={props.show.status} onHide={props.hide.status} backdrop="static">
      <Modal.Header>
        <Modal.Title>Add Subscription Bundle</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Discount percent</Form.Label>
                <Form.Control as="select" onChange={onDiscountSelect}>

                  {discount.map((val) => (
                    <option key={val.id} value={val.value}>
                      {val.text}
                    </option>)
                  )}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Duration</Form.Label>
                <Form.Control as="select" onChange={onDurationSelect}>
                  {duration.map((val) => (
                    <option key={val.id} value={val.value}>
                      {val.text}
                    </option>)
                  )}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" size="sm" onClick={() => props.hide()}>
          Cancel
          </Button>
        <Button variant="secondary" size="sm" onClick={saveBundle}>
          Save
          </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default BundleSubscriptionModal;

