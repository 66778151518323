import React from "react";
import AwesomeSlider from "react-awesome-slider";
import { MDBIcon } from "mdbreact";
import { isMobile } from "react-device-detect";
import { Row, Col } from "react-bootstrap";
import "react-awesome-slider/dist/styles.css";
import ContentImage from "../images/ContentImage";
import { COLORS } from "../../constants";
import "./AwesomeSliderStyle.scss";
import { BasicButton } from "../buttons/Buttons";
import { Button } from "react-bootstrap";

export const MediaObject = (props) => {
  const { content, maxHeight, onClick, showImageOverride } = props;

  const hasAccess = content.hasAccess || showImageOverride;

  if (content.files.length > 1) {
    return <Slider content={content} maxHeight={maxHeight} />;
  } else {
    const ext =
      content.files.length > 0
        ? content.files[0].fileExtension.toLowerCase()
        : "";
    const mediaUrl = content.files.length > 0 ? content.files[0].fileUrl : "";
    const imgStyle = isMobile
      ? { ...styles.imageMobileStyle, ...{ maxHeight: maxHeight } }
      : { ...styles.imageStyle, ...{ maxHeight: maxHeight } };

    switch (ext) {
      case "":
        return (
          <Col style={styles.lockedContentStyle}>
            <Row style={styles.lockedImageStyle}>
              <MDBIcon
                icon="lock"
                size="3x"
                style={{ color: COLORS.darkPurple }}
              />
            </Row>
            <Row style={styles.unlockButtonSectionStyle}>
              <Col className="d-flex justify-content-center">
                <BasicButton
                  text={`Unlock for $${content.price}`}
                  onClick={onClick}
                />
              </Col>
            </Row>
          </Col>
        );
      case "png":
      case "jpg":
      case "jpeg":
        return (
          <ContentImage
            src={mediaUrl}
            style={imgStyle}
            alt="image"
            hasAccess={hasAccess}
          />
        );
      case "mov":
      case "mp4":
        return <video style={styles.videoStyle} controls src={mediaUrl} />;
      default:
        return <div />;
    }
  }
};

const Slider = (props) => {
  const { content, maxHeight } = props;

  const mediaDivs = content.files.map((file, index) => {
    switch (file.fileExtension.toLowerCase()) {
      case "mp4":
      case "mov":
        return (
          <div key={file.fileId}>
            <video
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
              controls
              src={file.fileUrl}
            />
          </div>
        );
      default:
        return <div key={index} data-src={file.fileUrl} />;
    }
  });

  const awesomeSliderStyle =
    typeof maxHeight !== "undefined"
      ? { ...styles.sliderStyle, ...{ height: maxHeight - 80 } }
      : styles.sliderStyle;

  return (
    <AwesomeSlider className="aws-btn" style={awesomeSliderStyle}>
      {mediaDivs}
    </AwesomeSlider>
  );
};

const styles = {
  imageStyle: {
    height: "auto",
    width: "100%",
    marginRight: "auto",
    marginLeft: "auto",
  },
  imageMobileStyle: {
    width: "100%",
  },
  lockedContentStyle: {
    backgroundColor: COLORS.whisper,
    width: "100%",
    height: 220,
    padding: 10,
    paddingTop: 50,
  },
  lockedImageStyle: {
    justifyContent: "center",
  },
  sliderStyle: {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  unlockButtonSectionStyle: {
    justifyContent: "center",
    marginTop: 30,
  },
  videoStyle: {
    width: "100%",
    height: "auto",
    objectFit: "cover",
  },
};
