import React, { useContext, useEffect, useState } from "react";
import { Col, Row, Container } from "react-bootstrap";
import NumberFormat from "react-number-format";
import { BrowserView, MobileView, isMobile } from "react-device-detect";
import { MDBIcon } from "mdbreact";
import { AuthContext } from "../../providers/AuthProvider";
import { COLORS, LAYOUTS } from "../../constants";
import UserProfileFeed from "../../components/feeds/UserProfileFeed";
import UserProfileFeedGrid from "../../components/feeds/UserProfileFeedGrid";
import { BoldText, PrimaryText } from "../../components/texts/PageText";
import { getUserProfileFeed } from "../../api/ApiManager";
import AvatarIcon from "../../components/profile/AvatarIcon";
import { ClearButton, MainButton } from "../../components/buttons/Buttons";

const UserProfile = () => {
  const [content, setContent] = useState([]);
  const [isGrid, setIsGrid] = useState(false);
  const { user } = useContext(AuthContext);
  const backgroundImageUrl =
    typeof content.posts !== "undefined" && content.posts.length > 0
      ? content.posts[0].backgroundImageUrl
      : "";

  const HeaderSection = () => {
    return (
      <div
        style={{
          ...styles.headerStyle,
          ...{ backgroundImage: "url('" + backgroundImageUrl + "')" },
        }}
      >
        <Row style={styles.headerNameStyle}>{user.displayName}</Row>
        <Row>
          <Col className="ps-0 d-flex justify-content-start">
            <div style={styles.headerStatsStyle}>
              <NumberFormat
                value={content.postsCount}
                displayType={"text"}
                thousandSeparator={true}
              />
              {" Posts"}
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  const PostSection = () => {
    if (typeof content.posts !== "undefined") {
      if (isGrid) {
        return <UserProfileFeedGrid contentList={content.posts} />;
      } else {
        return <UserProfileFeed contentList={content.posts} />;
      }
    }
    return <div />;
  };

  const ViewSelectionSection = () => {
    if (isMobile) {
      return null;
    } else {
      return (
        <Row style={{ marginTop: 10, paddingRight: 5 }}>
          <Col className="pe-0 d-flex justify-content-end">
            <ClearButton
              style={{ marginRight: 20 }}
              onClick={() => setIsGrid(false)}
            >
              <MDBIcon
                icon="bars"
                size="3x"
                style={{ color: isGrid ? "gray" : COLORS.darkPurple }}
              />
            </ClearButton>
            <ClearButton onClick={() => setIsGrid(true)}>
              <MDBIcon
                icon="th"
                size="3x"
                style={{ color: isGrid ? COLORS.darkPurple : "gray" }}
              />
            </ClearButton>
          </Col>
        </Row>
      );
    }
  };

  const UserInfoSection = (props) => {
    return (
      <div style={styles.userInfoStyle}>
        <Row style={{ marginRight: 0, paddingRight: 0 }}>
          <Col>
            <AvatarIcon user={user} size={145} />
          </Col>
          <Col
            style={{
              marginTop: 70,
              paddingRight: 5,
            }}
          >
            <Row>
              <Col className="pe-0 d-flex justify-content-end">
                <MainButton text="Edit Profile" to="../settings/profile" />
              </Col>
            </Row>
            <ViewSelectionSection />
          </Col>
        </Row>
        <Row>
          <BoldText>{user.displayName}</BoldText>
        </Row>
        <Row>
          <PrimaryText>{user.userName}</PrimaryText>
        </Row>
      </div>
    );
  };

  useEffect(() => {
    getUserProfileFeed(user.uid, setContent);
  }, [user.uid]);

  return (
    <div>
      <BrowserView>
        <Container style={LAYOUTS.bodyStyle}>
          <Col style={LAYOUTS.mainSectionStyleBlack}>
            <HeaderSection />
            <UserInfoSection />
            <Row style={{ marginTop: 30 }}>
              <PostSection />
            </Row>
          </Col>
        </Container>
      </BrowserView>
      <MobileView>
        <Container fluid>
          <HeaderSection />
          <UserInfoSection />
          <PostSection />
        </Container>
      </MobileView>
    </div>
  );
};

export default UserProfile;

const styles = {
  avatarStyle: {
    width: 140,
    height: 140,
    borderRadius: "50%",
  },
  avatarContainerStyle: {
    width: 145,
    height: 145,
    borderRadius: "50%",
    borderWidth: 3,
    borderColor: COLORS.lightPurple,
    borderStyle: "solid",
  },
  headerStyle: {
    backgroundColor: COLORS.lightPurple,
    paddingLeft: 25,
    height: 300,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    marginLeft: isMobile ? -20 : 0,
    marginRight: isMobile ? -20 : 0,
  },
  headerNameStyle: {
    color: "white",
    fontWeight: "bold",
    fontSize: 22,
  },
  headerStatsStyle: {
    color: "white",
    fontWeight: "bold",
    fontSize: 17,
  },
  userInfoStyle: {
    marginTop: -70,
    marginLeft: 25,
  },
};
