import React from "react";
import { Row } from "react-bootstrap";
import ContentDetailCard from "../cards/ContentDetailCard/ContentDetailCard";

const UserProfileFeed = (props) => {
  const contentItem = props.contentList.map((content) => {
    return (
      <ContentDetailCard
        key={content.postId}
        content={content}
        canEdit={true}
      />
    );
  });

  return (
    <div className="container-fluid" style={styles.sectionStyle}>
      <Row>
        <div className="col-12" style={{ paddingLeft: 0, paddingRight: 0 }}>
          {contentItem}
        </div>
      </Row>
    </div>
  );
};

export default UserProfileFeed;

const styles = {
  sectionStyle: {
    marginBottom: 50,
  },
};
