import React from "react";
import { FaAlignJustify } from 'react-icons/fa';
import Sidebar from './Sidebar';

const MobileSidebar = () => {

  const [isMenuOpen, setisMenuOpen] = React.useState(false);

  const toggleMenu = () => {
    setisMenuOpen(!isMenuOpen);
  }

  React.useEffect(() => {
    let mounted = true;
    document.body.addEventListener("click", async (e) => {
      if(mounted){
      setisMenuOpen(false);
    }
    });
    return () => mounted = false;
  }, []);

  return (
    <div className="ml-3 mr-2">
        <Sidebar isMenuOpen={isMenuOpen} isMobile={true} />
        <span style={styles.mobileComponentHeader} onClick={toggleMenu}><FaAlignJustify /></span>
    </div>
  );
};

export default MobileSidebar;

const styles = {
  mobileComponentHeader: {
    fontSize: "25px",
    fontWeight: 600,
    color: 'rgb(79, 2, 89)'
  }

};