import React from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import { BrowserView, MobileView } from "react-device-detect";
import { LAYOUTS } from "../../../constants";
import { BoldText } from "../../../components/texts/PageText";
import { Link } from "react-router-dom";
import { FaPlus, FaArrowAltCircleLeft } from "react-icons/fa";

const CloseFriend = (props) => {
  const BodySection = () => {
    return (
      <Row>
        <Col xs={12} lg={4}></Col>
        <Col xs={12} lg={4}>
          <div
            className="alert alert-secondary"
            role="alert"
            style={{ padding: "5em" }}
          >
            <span className="alert-link">
              {" "}
              <span>
                <FaPlus />
              </span>
            </span>
          </div>
        </Col>
        <Col xs={12} lg={4}></Col>
      </Row>
    );
  };

  return (
    <div>
      <BrowserView>
        <Container className="pb-4" style={LAYOUTS.bodyStyle}>
          <Row style={LAYOUTS.mainSectionStyleBlank}>
            <Col lg={12} className="text-center">
              <h4>
                <BoldText>CLOSED FRIENDS</BoldText>
              </h4>
              <BodySection />
              <Link to={"/my/lists"}>
                <Button
                  variant="secondary"
                  size="md"
                  style={{ float: "right" }}
                >
                  <span style={{ float: "left" }}>
                    <FaArrowAltCircleLeft size={17} />
                  </span>
                  <span style={styles.notificationBackbutton}>Back</span>
                </Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </BrowserView>
      <MobileView>
        <Container className="pb-4 headerBody" style={LAYOUTS.bodyStyle}>
          <Row>
            <Col md={12} className="text-left mt-2">
              <BodySection />
              <Link to={"/my/lists"}>
                <Button
                  variant="secondary"
                  size="md"
                  style={{ float: "right" }}
                >
                  <span style={{ float: "left" }}>
                    <FaArrowAltCircleLeft size={17} />
                  </span>
                  <span style={styles.notificationBackbutton}>Back</span>
                </Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </MobileView>
    </div>
  );
};

const styles = {
  headerStyle: {
    paddingLeft: 25,
    height: 300,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  notificationBackbutton: {
    paddingLeft: "10px",
    fontWeight: "600",
    fontSize: "small",
  },
};

export default CloseFriend;
