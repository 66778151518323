import React from "react";
import { Row } from "react-bootstrap";
import ContentDetailCard from "../cards//ContentDetailCard/ContentDetailCard";
import SuggestionFeed from "./SuggestionFeed";
import { isMobile } from "react-device-detect";

const ContentFeed = (props) => {
  const contentItem = props.contentList.map((content, index) => {
    if (isMobile && index === 5) {
      return (
        <div>
          <SuggestionFeed suggestionList={props.suggestionList} dataLimit={3} />
          <ContentDetailCard key={content.postId} content={content} />
        </div>
      );
    }
    return <ContentDetailCard key={content.postId} content={content} />;
  });

  return (
    <div className="container-fluid">
      <Row>
        <div className="col-12" style={{ paddingLeft: 0, paddingRight: 0 }}>
          {contentItem}
        </div>
      </Row>
    </div>
  );
};

export default ContentFeed;
