import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../providers/AuthProvider";
import { Row, Form, Col, Button } from "react-bootstrap";
import Sidebar from "../../../../components/profile/Sidebar";
import Header from "../../../../components/profile/Header";
import { BrowserView, MobileView } from "react-device-detect";
import { BoldText } from "../../../../components/texts/PageText";
import { LAYOUTS } from "../../../../constants";
import {
  getEmailNotifications,
  postEmailNotifications,
  postEmailNotificationsShowFullText,
  postEmailNotificationsNewsletter,
  postEmailNotificationsReceiveLess,
} from "../../../../api/ApiManager";
import MobileSidebar from "../../../../components/profile/MobileSidebar";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { Link } from "react-router-dom";

const EmailNotification = (props) => {
  const { user } = useContext(AuthContext);
  const [content, setContent] = useState({});

  const handleChange = (e) => {
    switch (e.target.name) {
      case "email":
        postEmailNotifications(e.target.checked, user.uid, () => {
          alert("Email Notification updated!");
        });
        break;
      case "fullTextMessages":
        postEmailNotificationsShowFullText(e.target.checked, user.uid, () => {
          alert("Full-Text-Msgs Notification updated!");
        });
        break;
      case "newsLetter":
        postEmailNotificationsNewsletter(e.target.checked, user.uid, () => {
          alert("News-Letter Notification updated!");
        });
        break;
      case "receiveLess":
        postEmailNotificationsReceiveLess(e.target.checked, user.uid, () => {
          alert("Receive-Less Notification updated!");
        });
        break;

      default:
        break;
    }

    setContent({ ...content, [e.target.name]: e.target.checked });
  };

  useEffect(() => {
    getEmailNotifications(user.uid, setContent);
  }, [user.uid]);

  const BodySection = () => {
    let state = {
      email: true,
    };

    let element = (
      <div className="isActive">
        <hr style={styles.hr} />
        <Row>
          <Col xs={10}>
            <h5 className="text-muted">
              Show full text of the message in the notification email
            </h5>
          </Col>
          <Col xs={2} className="text-right">
            <Form>
              <Form.Check
                type="switch"
                id="full-text-custom-switch"
                onChange={(e) => handleChange(e)}
                name="fulltextmessages"
                checked={content.fulltextmessages}
              />
            </Form>
          </Col>
        </Row>

        <hr style={styles.hr} />
        <Row>
          <Col xs={10}>
            <h5 className="text-muted">Monthly Newsletter</h5>
          </Col>
          <Col xs={2} className="text-right">
            <Form>
              <Form.Check
                type="switch"
                id="newsletter-custom-switch"
                onChange={(e) => handleChange(e)}
                name="newsletter"
                checked={content.newsletter}
              />
            </Form>
          </Col>
        </Row>
        <hr style={styles.hr} />
        <Row>
          <Col xs={10}>
            <h5 className="text-muted">Receive less notifications</h5>
          </Col>
          <Col xs={2} className="text-right">
            <Form>
              <Form.Check
                type="switch"
                id="less-custom-switch"
                onChange={(e) => handleChange(e)}
                name="receiveless"
                checked={content.receiveless}
              />
            </Form>
          </Col>
        </Row>
      </div>
    );

    return (
      <div>
        <Row>
          <Col xs={10}>
            <h5 className="text-muted">Email Notification</h5>
          </Col>
          <Col xs={2} className="text-right">
            <Form>
              <Form.Check
                type="switch"
                id="email-custom-switch"
                onChange={(e) => handleChange(e)}
                name="email"
                checked={content.email}
              />
            </Form>
          </Col>
        </Row>

        <h6>
          Get emails to find out what’s going on when you’re not on IntrigueMe
          You can turn them off anytime.
        </h6>

        {state.email === true ? element : ""}
      </div>
    );
  };

  return (
    <div>
      <BrowserView>
        <div className="container" style={LAYOUTS.profileHeaderStyle}>
          <Row>
            <Header />
          </Row>
        </div>
        <div className="container pb-4" style={LAYOUTS.bodyStyle}>
          <Row>
            <Col md={3}>
              <Sidebar />
            </Col>
            <Col md={9} className="text-left">
              <h4>
                <BoldText>Email Notifications</BoldText>
              </h4>
              <hr style={styles.hr} />
              <BodySection />
              <Link to={"/settings/notification"}>
                <Button
                  variant="secondary"
                  size="md"
                  style={{ float: "right" }}
                >
                  <span style={{ float: "left" }}>
                    <FaArrowAltCircleLeft size={17} />
                  </span>
                  <span style={styles.notificationBackbutton}>Back</span>
                </Button>
              </Link>
            </Col>
          </Row>
        </div>
      </BrowserView>
      <MobileView>
        <div className="container header" style={LAYOUTS.profileHeaderStyle}>
          <Row>
            {" "}
            <Header />{" "}
          </Row>
        </div>
        <div className="container pb-4 headerBody" style={LAYOUTS.bodyStyle}>
          <Row>
            <MobileSidebar />
            <span style={styles.profileComponentHeader}>
              Email Notifications
            </span>
            <Col xs={12} className="text-left mt-2">
              <BodySection />

              <Link to={"/settings/notification"}>
                <Button
                  variant="secondary"
                  size="md"
                  style={{ float: "right" }}
                >
                  <span style={{ float: "left" }}>
                    <FaArrowAltCircleLeft size={17} />
                  </span>
                  <span style={styles.notificationBackbutton}>Back</span>
                </Button>
              </Link>
            </Col>
          </Row>
        </div>
      </MobileView>
    </div>
  );
};

const styles = {
  headerStyle: {
    paddingLeft: 25,
    height: 300,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  profileComponentHeader: {
    fontSize: "25px",
    fontWeight: 600,
  },
  notificationBackbutton: {
    paddingLeft: "10px",
    fontWeight: "600",
    fontSize: "small",
  },
};

export default EmailNotification;
