import React from "react";
import { COLORS } from "../../constants";

const SubscriptionCard = (props) => {
  const { subscriptionid, price, percentage, period } = props.subscription;
  const subscriptionText =
    price === "FREE"
      ? `Subscribe for ${price}`
      : `Subscribe ${price} per month`;
  const title =
    typeof percentage === "undefined"
      ? subscriptionText
      : `${percentage} OFF! ${price} for ${period} months`;

  return (
    <div style={styles.cardStyle}>
      <div style={styles.bodyStyle}>
        <h2 style={styles.titleStyle}>{title}</h2>
      </div>
      <div className="card-footer">
        <button
          type="button"
          className="btn white-text"
          id={subscriptionid}
          style={styles.footerButtonStyle}
          onClick={props.onClick}
        >
          SUBSCRIBE FOR {price}
        </button>
      </div>
    </div>
  );
};

export default SubscriptionCard;

const styles = {
  cardStyle: {
    borderRadius: 5,
    justifiedContent: "center",
    margin: 15,
    marginLeft: "auto",
    marginRight: "auto",
    height: 150,
    maxWidth: 540,
    padding: 0,
    backgroundColor: "#FFF",
    WebkitFilter: "drop-shadow(0px 0px 5px #555)",
    filter: "drop-shadow(0px 0px 5px #555)",
  },
  bodyStyle: {
    height: 44,
    backgroundColor: "#FFF",
  },
  footerButtonStyle: {
    height: 44,
    width: "90%",
    backgroundColor: COLORS.darkPurple,
    fontSize: 14,
    fontFamily: "Arial, Helvetica, sans-serif",
    fontWeight: "bold",
    textTransform: "uppercase",
    borderRadius: 5,
    outline: "none",
  },
  titleStyle: {
    fontSize: 20,
    textAlign: "center",
    color: COLORS.darkPurple,
    fontFamily: "Arial, Helvetica, sans-serif",
    marginTop: 20,
  },
};
