import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";

TimeAgo.addDefaultLocale(en);

export const WEB_DOMAIN =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_DOMAIN_DEV
    : process.env.REACT_APP_DOMAIN_PROD;
export const API_PATH = (type) => {
  let path = "";

  // if (process.env.NODE_ENV === 'development') {
  //     path = '/trufanz/src/api/'
  // } else {
  path = `/${type}`;
  // }
  //"**",WEB_DOMAIN);
  return `${WEB_DOMAIN}${path}`;
};

// Date Time
const dateCreate = (dt) => {
  return new Date(dt.replace(/-/g, "/"));
};

export const dateTime = (dt) => {
  let date = dateCreate(dt);
  let formattedDate =
    date.toLocaleDateString() + " " + date.toLocaleTimeString();
  return formattedDate;
};

export const truncate = (str, len) => {
  return str.length > len ? str.substring(0, len - 3) + "..." : str;
};

export const timeAgo = (ts) => {
  const timeAgo = new TimeAgo("en-US");
  const current_ts = Math.round(new Date().getTime() / 1000);
  const timeSince = (current_ts - ts) * 1000;
  const timeAgoString = timeAgo.format(Date.now() - timeSince);
  return timeAgoString;
};