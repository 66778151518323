import React, { useContext, useEffect, useState } from "react";
import FileUploader from "../../components/FileUploader";
import { uploadFileToFirebase } from "../../api/StorageManager";
import { Form, Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import MessageDetailFeed from "../../components/feeds/MessageDetailFeed";
import MessageFeed from "../../components/feeds/MessageFeed";
import { AuthContext } from "../../providers/AuthProvider";
import { COLORS, LAYOUTS } from "../../constants";
import { BoldText, PagePlaceholderText } from "../../components/texts/PageText";
import {
  getMessages,
  getMessageUserList,
  postReplyMessage,
} from "../../api/ApiManager";
import { BrowserView, MobileView } from "react-device-detect";
import { BackButton, BasicButton } from "../../components/buttons/Buttons";

const Messages = () => {
  const [messages, setMessages] = useState([]);
  const [messageFile, setMessageFile] = useState([]);
  const [messageUserList, setMessageUserList] = useState([]);
  const [messageInfo, setMessageInfo] = useState(null);
  const [retrieveMsg, setRetrieveMsg] = useState(true);
  const { user } = useContext(AuthContext);
  const { pmid } = useParams();

  const HeaderTitle = () => {
    let title = "Messages";
    title += messages.length > 0 ? ` with ${messages[0].withUsername}` : "";

    return (
      <div style={{ marginBottom: 5, marginTop: 10 }}>
        <BrowserView>
          <Col>
            <PagePlaceholderText>{title}</PagePlaceholderText>
          </Col>
        </BrowserView>
        <MobileView>
          <Col
            style={{
              marginBottom: 0,
              paddingLeft: 5,
            }}
          >
            <BoldText>{title}</BoldText>
          </Col>
        </MobileView>
      </div>
    );
  };

  const onChangeStatus = ({ meta, file }, status) => {
    if (status === "done") {
      setMessageFile([file]);
    }
  };

  const handleSubmit = () => {
    const fileList = [];
    messageFile.forEach((file) => {
      // Upload files to firebase
      let fileName = uploadFileToFirebase(user.uid, file);
      fileList.push(fileName);
    });

    const message = {
      pmid: pmid,
      uuid: user.uid,
      touuid: messages[0].withUUID,
      message: messageInfo.message,
      files: fileList,
    };

    postReplyMessage(message, () => {
      document.getElementById("message_form").reset();
      setRetrieveMsg(true);
    });
  };

  useEffect(() => {
    setMessages([]);
    if (user.uid !== "" && pmid !== "") {
      getMessages(user.uid, pmid, setMessages);
      getMessageUserList(user.uid, setMessageUserList);
      setRetrieveMsg(false);
    }
  }, [user.uid, pmid, retrieveMsg]);

  // Sets once user leaves the text field
  onchange = (e) => {
    setMessageInfo({
      ...messageInfo,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div>
      <BrowserView>
        <Col className="container-fluid">
          <Row style={LAYOUTS.bodyStyle}>
            <Col
              md={8}
              xs={12}
              className="d-none d-lg-block"
              style={LAYOUTS.mainSectionStyle}
            >
              <Row>
                <HeaderTitle />
              </Row>
              <Row style={styles.messageSectionStyles}>
                <MessageDetailFeed messages={messages} />
              </Row>
              <Row style={styles.replySectionStyle}>
                <Form
                  id="message_form"
                  method="post"
                  encType="multipart/form-data"
                  style={styles.formStyle}
                >
                  <Row>
                    <Col md={4} xs={12}>
                      <FileUploader
                        onChangeStatus={onChangeStatus}
                        disabledSubmit={true}
                        submitButtonContent={null}
                        maxFiles={1}
                      />
                    </Col>
                    <Col md={8} xs={12}>
                      <Form.Control
                        as="textarea"
                        rows={4}
                        id="message"
                        name="message"
                        placeholder="Type a message..."
                        style={styles.textAreaStyle}
                      />
                    </Col>
                  </Row>
                  <Row style={styles.replyInteractionSectionStyle}>
                    <Col className="d-flex justify-content-end">
                      <button
                        type="button"
                        className="btn white-text display_modal"
                        onClick={handleSubmit}
                        style={styles.sendButtonStyle}
                      >
                        Send
                      </button>
                    </Col>
                  </Row>
                </Form>
              </Row>
            </Col>
            <Col md={4} xs={0} style={LAYOUTS.sideSectionStyle}>
              {messageUserList.length > 0 ? (
                <MessageFeed messageList={messageUserList} activeID={pmid} />
              ) : null}
            </Col>
          </Row>
        </Col>
      </BrowserView>
      <MobileView>
        <Col className="container-fluid" style={LAYOUTS.mobileMainSectionStyle}>
          <Row
            style={{
              paddingLeft: 5,
              paddingRight: 5,
            }}
          >
            <Col>
              <Row style={{ paddingTop: 10 }}>
                <HeaderTitle />
              </Row>
              <Row style={styles.messageSectionMobileStyles}>
                <MessageDetailFeed messages={messages} />
              </Row>
              <Row style={styles.replySectionMobileStyle}>
                <Form
                  id="message_form"
                  method="post"
                  encType="multipart/form-data"
                  style={styles.formStyle}
                >
                  <Row style={{ marginBottom: 10 }}>
                    <FileUploader
                      onChangeStatus={onChangeStatus}
                      disabledSubmit={true}
                      submitButtonContent={null}
                      maxFiles={1}
                    />
                  </Row>
                  <Row>
                    <Form.Control
                      as="textarea"
                      rows={4}
                      id="message"
                      name="message"
                      placeholder="Type a message..."
                      style={styles.textAreaStyle}
                    />
                  </Row>
                  <Row style={styles.replyInteractionSectionStyle}>
                    <Col className="d-flex justify-content-end">
                      <BackButton to="/messages" />
                      <BasicButton text="Send" onClick={handleSubmit} />
                    </Col>
                  </Row>
                </Form>
              </Row>
            </Col>
          </Row>
        </Col>
      </MobileView>
    </div>
  );
};

export default Messages;

const styles = {
  formStyle: {
    width: "100%",
  },
  messageSectionMobileStyles: {
    paddingLeft: 5,
    paddingRight: 5,
  },
  messageSectionStyles: {
    paddingLeft: 10,
    paddingRight: 30,
  },
  sendButtonStyle: {
    backgroundColor: COLORS.darkPurple,
    marginRight: 0,
  },
  replyInteractionSectionStyle: {
    marginTop: 10,
  },
  replySectionMobileStyle: {
    marginTop: 10,
    paddingLeft: 10,
    paddingRight: 10,
  },
  replySectionStyle: {
    marginTop: 20,
    paddingLeft: 10,
    paddingRight: 30,
  },
  textAreaStyle: {
    width: "100%",
    height: 120,
    resize: "none",
  },
};
