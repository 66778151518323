import React, { useContext, useEffect, useState } from "react";
import { Picker } from "emoji-mart";
import { BoldText, PagePlaceholderText } from "../../components/texts/PageText";
import { Form, Col, Row } from "react-bootstrap";
import { BrowserView, MobileView } from "react-device-detect";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../providers/AuthProvider";
import { COLORS, LAYOUTS } from "../../constants";
import { BackButton, BasicButton } from "../../components/buttons/Buttons";
import { MediaObject } from "../../components/media/MediaObject";
import { getPostEditById, postEditPost } from "../../api/ApiManager";

const EditPost = () => {
  const { user } = useContext(AuthContext);
  const { postid } = useParams();
  const [postInfo, setPostInfo] = useState({
    desc: "",
    isFree: false,
    files: [],
  });
  const [updateButtonDisable, setUpdateButtonDisable] = useState(true);

  const FormSection = () => {
    return (
      <Col>
        <Row className="justify-content-center">
          <Col md={8} style={styles.mediaSectionStyle}>
            <MediaObject content={postInfo} showImageOverride={true} />
          </Col>
        </Row>
        <Row>
          <Form
            id="post_form"
            method="post"
            encType="multipart/form-data"
            style={styles.formStyle}
          >
            <Form.Group>
              <Row>
                <Col md={8}>
                  <Form.Control
                    as="textarea"
                    rows={17}
                    id="desc"
                    name="desc"
                    placeholder="Compose new post..."
                    defaultValue={postInfo.desc}
                    onChange={(e) => handleChange(e)}
                    style={styles.textAreaStyle}
                  />
                </Col>
                <Col className="d-flex justify-content-end" md={4}>
                  <Picker
                    title="Pick your emoji…"
                    emoji="point_up"
                    onSelect={(emoji) => handlePickerOnSelect(emoji)}
                  />
                </Col>
              </Row>
              <Row style={styles.interactionSectionStyle}>
                <Col
                  className="d-flex justify-content-end"
                  style={{ paddingRight: 0 }}
                >
                  <BackButton to="/userprofile" />
                  <BasicButton
                    text="Update"
                    onClick={handleSubmit}
                    disabled={updateButtonDisable}
                  />
                </Col>
              </Row>
            </Form.Group>
          </Form>
        </Row>
      </Col>
    );
  };

  const handleChange = (e) => {
    switch (e.target.name) {
      case "desc":
        setPostInfo({
          ...postInfo,
          [e.target.name]: e.target.value,
        });
        setUpdateButtonDisable(false);
        break;
      default:
        break;
    }
  };

  const handlePickerOnSelect = (emoji) => {
    setPostInfo({
      ...postInfo,
      desc: postInfo.desc + emoji.native,
    });
    setUpdateButtonDisable(false);
  };

  const handleSubmit = () => {
    const post = {
      uuid: user.uid,
      postid: postid,
      desc: postInfo.desc,
    };

    postEditPost(post, () => {
      window.location.href = "/userprofile";
    });
  };

  useEffect(() => {
    if (user.uid !== "" && postid !== "") {
      getPostEditById(postid, user.uid, setPostInfo);
    }
  }, [user.uid, postid]);

  return (
    <div>
      <BrowserView>
        <Col style={{ backgroundColor: COLORS.athensGray }}>
          <div className="container" style={LAYOUTS.bodyStyle}>
            <div style={LAYOUTS.mainSectionStyle}>
              <PagePlaceholderText>Edit Post</PagePlaceholderText>
              <FormSection />
            </div>
          </div>
        </Col>
      </BrowserView>
      <MobileView>
        <Col className="container" style={LAYOUTS.mobileMainSectionStyle}>
          <Row style={styles.headerStyle}>
            <BoldText>Edit Post</BoldText>
          </Row>
          <FormSection />
        </Col>
      </MobileView>
    </div>
  );
};

export default EditPost;

const styles = {
  formStyle: {
    width: "100%",
  },
  headerStyle: {
    paddingLeft: 15,
    paddingTop: 15,
    marginBottom: 15,
  },
  interactionSectionStyle: {
    marginTop: 10,
  },
  mediaSectionStyle: {
    marginBottom: 10,
    paddingLeft: 0,
    paddingRight: 0,
  },
};
