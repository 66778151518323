import React, { createContext, useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { app } from "../lib/Firebase/app";
import { authMethods } from "../lib/Firebase/authMethods";
import { getUserInfo } from "../api/ApiManager";

export const AuthContext = createContext();

const auth = getAuth(app);

const AuthProvider = (props) => {
  const [inputs, setInputs] = useState({
    email: "",
    password: "",
    displayName: "",
  });
  const [errors, setErrors] = useState([]);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [user, setUser] = useState({
    uid: "",
    userName: "",
    displayName: "",
    email: "",
    avatarUrl: "",
    backgroundImageUrl: "",
    subscribersCount: 0,
    subscribedCount: 0,
  });

  const handleSignIn = () => {
    return authMethods.signIn(
      inputs.email,
      inputs.password,
      setErrors,
      setToken
    );
  };

  const handleSignInFacebook = () => {
    authMethods.signInWithFacebook(setErrors, setToken, () => {
      fetchUser();
    });
  };

  const handleSignInGoogle = () => {
    authMethods.signInWithGoogle(setErrors, setToken, () => {
      fetchUser();
    });
  };

  const handleSignUp = (displayname) => {
    authMethods.signUp(
      inputs.email,
      inputs.password,
      inputs.displayName,
      setErrors,
      setToken,
      () => {
        fetchUser();
      }
    );
  };

  const handleSignOut = () => {
    authMethods.signOut(setErrors, setToken);
  };

  const createUser = async (userAuth) => {
    getUserInfo(userAuth.uid, (userInfo) => {
      setUser({
        uid: userAuth.uid,
        userName: `@${userInfo.userName}`,
        displayName: userInfo.displayName,
        email: userAuth.email,
        avatarUrl: userInfo.avatarUrl,
        backgroundImageUrl: userInfo.backgroundImageUrl,
        subscribedCount: userInfo.subscribedCount,
        subscribersCount: userInfo.subscribersCount,
      });
    });
  };

  const updateUser = (user, callback) => {
    setUser(user);
    return callback();
  };

  const fetchUser = () => {
    getUserInfo(user.uid, (userInfo) => {
      setUser(userInfo);
    });
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (userAuth) => {
      if (auth.currentUser !== null) {
        auth.currentUser.getIdToken(true).then(async (idToken) => {
          // Set token to localStorage
          await localStorage.setItem("token", idToken);

          if (userAuth) {
            createUser(userAuth);
          }
        });
      }
    });
    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        fetchUser,
        handleSignIn,
        handleSignInFacebook,
        handleSignInGoogle,
        handleSignOut,
        handleSignUp,
        token,
        inputs,
        setInputs,
        updateUser,
        errors,
        user,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
