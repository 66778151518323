import { getStorage, ref } from "firebase/storage";
import { app } from "../lib/Firebase/app";
import { v1 as uuidv1 } from "uuid";

const storage = getStorage(app);
const storageRef = ref(storage);
const baseStorageUrl =
  "https://firebasestorage.googleapis.com/v0/b/intrigueme-b7be5.appspot.com/o/";

const deleteAccountFile = (uuid, filename) => {
  const deleteRef = storageRef.child(`${uuid}/account`);
  deleteRef.child(filename).delete();
};

const deleteContentFile = (uuid, filename) => {
  const deleteRef = storageRef.child(`${uuid}/content`);
  deleteRef.child(filename).delete();
};

const getUserAvatarImage = async (uuid) => {
  const profileImagesRef = storageRef.child(`${uuid}/account`);
  const avatarRef = profileImagesRef.child("avatar.png");
  return getImageUrl(avatarRef);
};

const getUserAvatarUrl = (uuid) => {
  const baseDirectory = "%2Faccount%2F";
  const mediaName = "avatar.png?alt=media";
  const url = `${baseStorageUrl}${uuid}${baseDirectory}${mediaName}`;
  return url;
};

const getUserBackgroundImage = async (uuid) => {
  const profileImagesRef = storageRef.child(`${uuid}/account`);
  const backgroundRef = profileImagesRef.child("background.png");
  return getImageUrl(backgroundRef);
};

const getContentUrl = (uuid, filename) => {
  const baseDirectory = "%2Fcontent%2F";
  const mediaName = `${filename}?alt=media`;
  const url = `${baseStorageUrl}${uuid}${baseDirectory}${mediaName}`;
  return url;
};

const getUserBackgroundUrl = (uuid) => {
  const baseDirectory = "%2Faccount%2F";
  const mediaName = "background.png?alt=media";
  const url = `${baseStorageUrl}${uuid}${baseDirectory}${mediaName}`;
  return url;
};

const getImageUrl = (ref) => {
  const promise = ref.getDownloadURL().then((url) => {
    return url;
  });
  return promise;
};

const uploadToFirebase = (uuid, file, filename, callback) => {
  var defaultDirectory = "content";

  switch (filename) {
    case "avatar":
    case "background":
      defaultDirectory = "account";
      break;
    default:
      break;
  }

  const tmpFilename =
    typeof file.meta !== "undefined" ? file.meta.name : file.name;
  const tmpFile = typeof file.file !== "undefined" ? file.file : file;
  const fileExtension = tmpFilename.split(".").pop();
  const filenameFull = `${filename}.${fileExtension}`;
  const uploadRef = storageRef.child(`${uuid}/${defaultDirectory}`);
  uploadRef.child(filenameFull).put(tmpFile, file.meta);
  return filenameFull;
};

const uploadAvatarToFirebase = (user, file, callback) => {
  if (typeof user.avatarUrl !== "undefined" && user.avatarUrl !== "") {
    const fileExtension = user.avatarUrl.split(".").pop().slice(0, -"?alt=media".length);
    deleteAccountFile(user.uid, `avatar.${fileExtension}`);
  }

  return uploadToFirebase(user.uid, file, "avatar");
};

const uploadBackgroundToFirebase = (user, file, callback) => {
  if (typeof user.backgroundImageUrl !== "undefined" && user.backgroundImageUrl !== "") {

    const fileExtension = user.backgroundImageUrl.split(".").pop().slice(0, -"?alt=media".length);
    deleteAccountFile(user.uid, `background.${fileExtension}`);
  }

  return uploadToFirebase(user.uid, file, "background");
};

const uploadFileToFirebase = (uuid, file) => {
  const filename = `${uuidv1()}`;
  return uploadToFirebase(uuid, file, filename);
};

export {
  baseStorageUrl,
  deleteContentFile,
  getContentUrl,
  getUserAvatarImage,
  getUserAvatarUrl,
  getUserBackgroundImage,
  getUserBackgroundUrl,
  uploadAvatarToFirebase,
  uploadBackgroundToFirebase,
  uploadFileToFirebase,
};
