import React from "react";
import { COLORS } from "../../constants";

const AvatarIcon = (props) => {
  const { user, size } = props;

  const avatarStyle =
    typeof size !== "undefined"
      ? { ...styles.avatarStyle, ...{ width: size - 5, height: size - 5 } }
      : styles.avatarStyle;
  const avatarContainerStyle =
    typeof size !== "undefined"
      ? { ...styles.avatarContainerStyle, ...{ width: size, height: size } }
      : styles.avatarContainerStyle;
  const avatarContainerInitialStyle =
    typeof size !== "undefined"
      ? {
          ...styles.avatarContainerInitialStyle,
          ...{ width: size, height: size, lineHeight: `${size}px` },
        }
      : styles.avatarContainerInitialStyle;
  const avatarInitialStyle =
    typeof size !== "undefined"
      ? { ...styles.avatarInitialStyle, ...{ fontSize: size - 15 } }
      : styles.avatarInitialStyle;

  if (user.avatarUrl === "") {
    if (typeof user.displayName !== "undefined" && user.displayName !== "") {
      const initial = user.displayName.charAt(0).toUpperCase();

      return (
        <div style={avatarContainerInitialStyle}>
          <div style={{ ...avatarStyle, ...avatarInitialStyle }}>{initial}</div>
        </div>
      );
    } else {
      return (
        <div style={avatarContainerStyle}>
          <img
            src={require("../../assets/images/logos/im_logo.png")}
            style={avatarStyle}
            alt="avatar"
          />
        </div>
      );
    }
  } else {
    return (
      <div style={avatarContainerStyle}>
        <img src={user.avatarUrl} style={avatarStyle} alt="avatar" />
      </div>
    );
  }
};

export default AvatarIcon;

const styles = {
  avatarStyle: {
    width: 80,
    height: 80,
    borderRadius: "50%",
  },
  avatarContainerStyle: {
    width: 85,
    height: 85,
    borderRadius: "50%",
    borderWidth: 3,
    borderColor: COLORS.darkPurple,
    borderStyle: "solid",
    paddingLeft: 0
  },
  avatarContainerInitialStyle: {
    width: 85,
    height: 85,
    borderRadius: "50%",
    borderWidth: 3,
    borderColor: COLORS.darkPurple,
    borderStyle: "solid",
    lineHeight: "80px",
  },
  avatarInitialStyle: {
    backgroundColor: COLORS.darkPurple,
    color: "white",
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 44,
  },
};
