import React, { useContext, useEffect, useState } from "react";
import MessageFeed from "../../components/feeds/MessageFeed";
import { AuthContext } from "../../providers/AuthProvider";
import { COLORS, LAYOUTS } from "../../constants";
import { PagePlaceholderText } from "../../components/texts/PageText";
import { getMessageUserList } from "../../api/ApiManager";
import { BrowserView, MobileView } from "react-device-detect";
import { Row, Col } from "react-bootstrap";
import { MainButton } from "../../components/buttons/Buttons";
import messageImage from "../../assets/icons/navbar/message.svg";

const Messages = () => {
  const [messageList, setMessageList] = useState([]);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (user.uid !== "") {
      getMessageUserList(user.uid, setMessageList);
    }
  }, [user.uid]);

  return (
    <div>
      <BrowserView>
        <Col className="container-fluid">
          <Row style={LAYOUTS.bodyStyle}>
            <Col md={8} s={0} style={LAYOUTS.mainSectionStyle}>
              <Row style={styles.placeholderStyle}>
                <Col className="justify-content-center">
                  <img
                    src={messageImage}
                    width="30"
                    style={styles.iconStyle}
                    alt="message"
                  />
                </Col>
              </Row>
              <Row>
                <PagePlaceholderText>Your Messages</PagePlaceholderText>
              </Row>
              <Row>
                <Col className="d-flex justify-content-center">
                  <MainButton text="New Message" to="/messages/new" />
                </Col>
              </Row>
            </Col>
            <Col md={4} s={12} style={LAYOUTS.sideSectionStyle}>
              <MessageFeed messageList={messageList} />
            </Col>
          </Row>
        </Col>
      </BrowserView>
      <MobileView>
        <Col className="container-fluid" style={LAYOUTS.mobileMainSectionStyle}>
          <MessageFeed messageList={messageList} hideNewMessageButton={false} />
        </Col>
      </MobileView>
    </div>
  );
};

export default Messages;

const styles = {
  iconStyle: {
    width: 60,
  },
  newButtonStyle: {
    backgroundColor: COLORS.darkPurple,
  },
  placeholderStyle: {
    marginTop: 200,
  },
};
