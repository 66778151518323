import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { Row, Col, Container, Stack } from "react-bootstrap";
import { AuthContext } from "../providers/AuthProvider";
import topBackgroundImage from "../assets/images/landing/top_background.jpg";
import { COLORS } from "../constants";
import logoImage from "../assets/images/logos/im_logo_white.svg";

const AuthPage = (prop) => {
  const {
    handleSignIn,
    handleSignInFacebook,
    handleSignInGoogle,
    handleSignUp,
    inputs,
    setInputs,
    errors,
  } = useContext(AuthContext);
  const location = useLocation();

  const handleLogin = async (e) => {
    e.preventDefault();
    await handleSignIn();
    setInputs({ email: "", password: "", displayName: "" });
  };

  const handleLoginFacebook = async (e) => {
    e.preventDefault();
    await handleSignInFacebook();
  };

  const handleLoginGoogle = async (e) => {
    e.preventDefault();
    await handleSignInGoogle();
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    await handleSignUp();
    setInputs({ email: "", password: "", displayName: "" });
  };

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const TopSection = () => {
    return (
      <Row style={styles.topSectionStyle}>
        <Col>
          <Row className="justify-content-center">
            <img src={logoImage} style={styles.logoStyle} alt="logo" />
          </Row>
          <Row
            className="justify-content-center"
            style={{ marginTop: 100, marginBottom: 100 }}
          >
            <p style={styles.headerTextStyle}>ARE YOU INTRIGUED?</p>
          </Row>
        </Col>
      </Row>
    );
  };

  return (
    <div className="container-fluid" style={styles.constainerStyle}>
      <TopSection />
      {location.pathname === "/login" ? (
        <div>
          <div style={{ marginTop: 50 }}>
            <Row className="justify-content-center">
              <p
                style={{
                  ...styles.headerTextStyle,
                  ...{ color: COLORS.lightPurple },
                }}
              >
                Member Login
              </p>
            </Row>
            <Row className="justify-content-center">
              {errors.length > 0
                ? errors.map((error) => <p style={{ color: "red" }}>{error}</p>)
                : null}
            </Row>
            <Row className="justify-content-center">
              <form style={{ width: 250 }}>
                <div style={styles.textSectionStyle}>
                  <input
                    type="email"
                    className="my-2 p-1 w-100"
                    name="email"
                    value={inputs.email}
                    placeholder="Email"
                    id="email"
                    onChange={onChangeHandler}
                    autoComplete="username"
                    style={styles.authInputStyle}
                  />
                </div>
                <div style={styles.textSectionStyle}>
                  <input
                    type="password"
                    className="my-2 mb-3 p-1 w-100 no-shadow"
                    name="password"
                    value={inputs.password}
                    placeholder="Password"
                    id="password"
                    onChange={onChangeHandler}
                    autoComplete="current-password"
                    style={styles.authInputStyle}
                  />
                </div>
                <div>
                  <Link to="/" onClick={handleLogin}>
                    <div style={styles.loginButtonStyle}>LOG IN</div>
                  </Link>
                </div>
              </form>
            </Row>
          </div>
          <Stack
            direction="horizontal"
            gap={3}
            className="justify-content-center"
            style={styles.iconSectionStyle}
          >
            <Link to="/" onClick={handleLoginFacebook}>
              <i className="fab fa-facebook-f fa-lg white-text mr-5 fa-5x" />
            </Link>
            <Link to="/" onClick={handleLoginGoogle}>
              <i className="fab fa-google fa-lg white-text fa-5x" />
            </Link>
          </Stack>
          <Stack
            direction="horizontal"
            gap={1}
            className="justify-content-center"
            style={styles.existingAccountSection}
          >
            <span>Need an account?</span>
            <Link to="/register" style={styles.loginLinkStyle}>
              Register
            </Link>
          </Stack>
        </div>
      ) : (
        <div>
          <div style={{ marginTop: 50 }}>
            <Row className="justify-content-center">
              <p
                style={{
                  ...styles.headerTextStyle,
                  ...{ color: COLORS.lightPurple },
                }}
              >
                Sign Up
              </p>
            </Row>
            <Row className="justify-content-center">
              {errors.length > 0
                ? errors.map((error) => <p style={{ color: "red" }}>{error}</p>)
                : null}
            </Row>
            <Row className="justify-content-center">
              <form style={{ width: 250 }}>
                <div style={styles.textSectionStyle}>
                  <input
                    type="email"
                    className="my-2 p-1 w-100"
                    name="email"
                    value={inputs.email}
                    placeholder="Email"
                    id="email"
                    onChange={onChangeHandler}
                    autoComplete="username"
                    style={styles.authInputStyle}
                  />
                </div>
                <div style={styles.textSectionStyle}>
                  <input
                    type="password"
                    className="my-2 p-1 w-100 no-shadow"
                    name="password"
                    value={inputs.password}
                    placeholder="Password"
                    id="password"
                    onChange={onChangeHandler}
                    autoComplete="current-password"
                    style={styles.authInputStyle}
                  />
                </div>
                <div style={styles.textSectionStyle}>
                  <input
                    type="name"
                    className="mt-1 mb-3 p-1 w-100 no-shadow"
                    name="displayName"
                    value={inputs.name}
                    placeholder="Display Name"
                    id="displayName"
                    onChange={onChangeHandler}
                    style={styles.authInputStyle}
                  />
                </div>
                <div>
                  <Link to="/" onClick={handleSignup}>
                    <div style={styles.loginButtonStyle}>SIGN UP</div>
                  </Link>
                </div>
              </form>
            </Row>
          </div>
          <Stack
            direction="horizontal"
            gap={3}
            className="justify-content-center"
            style={styles.iconSectionStyle}
          >
            <Link to="/" onClick={handleLoginFacebook}>
              <i className="fab fa-facebook-f fa-lg white-text mr-5 fa-5x" />
            </Link>
            <Link to="/" onClick={handleLoginGoogle}>
              <i className="fab fa-google fa-lg white-text fa-5x" />
            </Link>
          </Stack>
          <Stack
            direction="horizontal"
            gap={1}
            className="justify-content-center"
            style={styles.existingAccountSection}
          >
            <span>Already have an account?</span>
            <Link to="/login" style={styles.loginLinkStyle}>
              Log In
            </Link>
          </Stack>
        </div>
      )}
    </div>
  );
};

export default AuthPage;

const styles = {
  constainerStyle: {
    backgroundColor: "black",
    minHeight: window.innerHeight - 180 - 25,
  },
  existingAccountSection: {
    color: "white",
    marginTop: 25,
  },
  headerTextStyle: {
    color: "white",
    fontSize: 40,
    fontWeight: "bold",
    letterSpacing: 3,
    textAlign: "center",
  },
  iconSectionStyle: {
    marginTop: 50,
  },
  loginButtonStyle: {
    background: COLORS.darkPurple,
    color: "white",
    fontSize: 24,
    fontWeight: "bold",
    height: 40,
    textAlign: "center",
  },
  loginLinkStyle: {
    color: COLORS.lightPurple,
    marginLeft: 10,
  },
  logoStyle: {
    height: "auto",
    width: 200,
  },
  topSectionStyle: {
    backgroundImage: `url(${topBackgroundImage})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    textAlign: "center",
    paddingTop: 10,
    paddingBottom: 30,
  },
};
