import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Row } from "react-bootstrap";
import { getContentFeed, getSuggestFeed } from "../api/ApiManager";
import { AuthContext } from "../providers/AuthProvider";
import ContentFeed from "../components/feeds/ContentFeed";
import SuggestionFeed from "../components/feeds/SuggestionFeed";
import { LAYOUTS } from "../constants";
import { BrowserView, MobileView } from "react-device-detect";
import Loader from "../components/loader/Loader";

const Home = () => {
  const [contentList, setContentList] = useState([]);
  const [more, setMore] = useState(true);

  const [suggestionList, setSuggestionList] = useState([]);
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const loader = useRef(null);

  // Data Load
  const handleObserver = useCallback((entries) => {
    const target = entries[0];
    if (target.isIntersecting) {
      setPage((prev) => prev + 1);
    }
  }, []);

  const fetchContent = useCallback(async () => {
    try {
      setLoading(true);
      getContentFeed(user.uid, page, (data) => {
        setContentList((prev) => [...prev, ...data.feed]);
        setMore(data.more);
      });
      setLoading(false);
    } catch (err) {}
  }, [user.uid, page]);

  const loadInitialData = useCallback(async () => {
    if (user.uid !== "") {
      fetchContent(user.uid);
    }
  }, [fetchContent, user.uid]);

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: "20px",
      threshold: 0,
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (loader.current) observer.observe(loader.current);
  }, [handleObserver]);

  useEffect(() => {
    loadInitialData(user.uid);
  }, [loadInitialData, user.uid]);

  useEffect(() => {
    getSuggestFeed(user.uid, setSuggestionList);
  }, [user.uid]);

  return (
    <div>
      <BrowserView>
        <div className="container-fluid" style={LAYOUTS.bodyStyle}>
          <Row>
            <div
              className="col-lg-9 col-md-12"
              style={LAYOUTS.mainSectionStyle}
            >
              <Row>
                <ContentFeed contentList={contentList} />
              </Row>
            </div>
            <div
              className="col-lg-3 d-none d-lg-block"
              style={LAYOUTS.sideSectionStyle}
            >
              <div style={styles.suggestionStyle}>
                <SuggestionFeed suggestionList={suggestionList} />
              </div>
            </div>
          </Row>
        </div>
      </BrowserView>
      <MobileView>
        <div className="container-fluid">
          <ContentFeed
            contentList={contentList}
            suggestionList={suggestionList}
          />
          <Row></Row>
        </div>
      </MobileView>
      {loading && <Loader />}
      {more ? <div ref={loader} /> : null}
    </div>
  );
};

const styles = {
  suggestionStyle: {
    position: "sticky",
    right: 0,
    top: 0,
  },
};

export default Home;
