import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { MDBIcon } from "mdbreact";
import { BrowserView, MobileView, isMobile } from "react-device-detect";
import { Row, Col } from "react-bootstrap";
import NumberFormat from "react-number-format";
import { AuthContext } from "../../../providers/AuthProvider";
import { COLORS } from "../../../constants";
import { BoldText, PrimaryText } from "../../texts/PageText";
import {
  deleteRemoveBookmark,
  deleteRemoveLike,
  postAddBookmark,
  postAddLike,
} from "../../../api/ApiManager";
import AvatarIcon from "../../profile/AvatarIcon";
import CommentCard from "../CommentCard";
import TipModal from "../../../components/modals/TipModal";
import { truncate } from "../../../lib/utils";
import { MediaObject } from "../../media/MediaObject";
import { timeAgo } from "../../../lib/utils";
import { ClearButton } from "../../buttons/Buttons";
import UnlockContentModal from "../../modals/UnlockContentModal";
import bookmarkImage from "../../../assets/icons/bookmark.svg";
import bookmarkFilledImage from "../../../assets/icons/bookmark-filled.svg";
import commentsImage from "../../../assets/icons/comment.svg";
import heartImage from "../../../assets/icons/heart.svg";
import heartFilledImage from "../../../assets/icons/heart-filled.svg";
import moneyImage from "../../../assets/icons/money.svg";

const ContentDetailCard = (props) => {
  const { user } = useContext(AuthContext);
  const { canEdit, singleMode } = props;
  const [content, setContent] = useState(props.content);
  const [showTipModal, setShowTipModal] = useState(false);
  const [showUnlockContentModal, setShowUnlockContentModal] = useState(false);

  // Modals
  const toggleTipModal = () => {
    setShowTipModal(!showTipModal);
  };

  const toggleUnlockContentModal = () => {
    setShowUnlockContentModal(!showUnlockContentModal);
  };

  const bookmarkButtonState = (isBookmarked) => {
    return isBookmarked ? bookmarkFilledImage : bookmarkImage;
  };

  const likedButtonState = (isLiked) => {
    return isLiked ? heartFilledImage : heartImage;
  };

  const AvatarSection = () => {
    const displayName =
      isMobile && content.displayName.length > 18
        ? truncate(content.displayName, 18)
        : content.displayName;

    return (
      <Col style={styles.headerStyle}>
        <Row>
          <div className="col-9 ps-0">
            <Row style={styles.headerStyle}>
              <Col md={2}>
                <AvatarIcon user={content} size={55} />
              </Col>
              <Col className="ps-0" md={10}>
                <div style={styles.headerUsernameStyle}>
                  <Link to={`../im/${content.userName}`}>
                    <BoldText>{displayName}</BoldText>
                  </Link>
                </div>
              </Col>
            </Row>
          </div>
          <div className="col-3" style={styles.avatarSectionRightColStyle}>
            {canEdit ? (
              <Link to={`../editpost/${content.postId}`}>
                <MDBIcon
                  icon="ellipsis-v"
                  size="2x"
                  style={styles.editButtonStyle}
                />
              </Link>
            ) : null}
            <button onClick={toggleBookmark} style={styles.clearButtonStyle}>
              <img
                src={bookmarkButtonState(content.isBookmarked)}
                alt="bookmark"
                style={{ width: 50, marginTop: -15 }}
              />
            </button>
          </div>
        </Row>
        <Row
          className="d-flex justify-content-end"
          style={styles.timeAgoSectionStyle}
        >
          <span style={styles.timeAgoStringStyle}>
            {timeAgo(content.createdAt)}
          </span>
        </Row>
      </Col>
    );
  };

  const CommentSection = (props) => {
    if (singleMode) {
      return null;
    } else {
      const commentsItems =
        typeof content.comments === "undefined"
          ? null
          : content.comments.map((comment) => {
              return <CommentCard key={comment.commentsId} content={comment} />;
            });

      const style = isMobile
        ? styles.commentTextMobileStyle
        : styles.commentTextStyle;

      return (
        <div style={props.style}>
          {content.hasAccess ? (
            <Link to={`../comments/${content.postId}`}>
              <div style={style}>{content.commentsCount} Comments</div>
            </Link>
          ) : null}
          <Row style={styles.commentItemsStyle}>
            {commentsItems}
          </Row>
        </div>
      );
    }
  };

  const DescriptionSection = (props) => {
    return (
      <Row
        className="ps-0"
        style={{ ...styles.descriptionStyle, ...props.style }}
      >
        <span>{content.description}</span>
      </Row>
    );
  };

  const MediaSection = () => {
    return (
      <Row style={styles.imageRowStyle}>
        <MediaObject
          content={content}
          onClick={() => setShowUnlockContentModal(true)}
        />
      </Row>
    );
  };

  const InteractiveSection = () => {
    const iconWidth = isMobile ? 25 : 50;
    const rightPadding = isMobile ? 10 : 15;
    const opacity = content.hasAccess ? 1.0 : 0.5;

    return (
      <Row style={props.style}>
        <div className="col-6" style={styles.leftDetailsStyle}>
          <ClearButton onClick={() => setShowTipModal(true)}>
            <img
              id="tip_modal"
              src={moneyImage}
              width={iconWidth}
              style={{ marginRight: rightPadding }}
              alt="tip link"
            />
          </ClearButton>
          <button
            onClick={toggleLike}
            style={styles.clearButtonStyle}
            disabled={!content.hasAccess}
          >
            <img
              id="like_button"
              src={likedButtonState(content.isLiked)}
              width={iconWidth}
              style={{ marginRight: rightPadding, opacity: opacity }}
              alt="like link"
            />
          </button>
          <Link
            to={content.hasAccess ? `../comments/${content.postId}` : ""}
            onClick={content.hasAccess ? null : (e) => e.preventDefault()}
          >
            <img
              id="comment_page"
              src={commentsImage}
              width={iconWidth}
              alt="chat link"
              style={{ opacity: opacity }}
            />
          </Link>
        </div>
        <div
          className="col-6 d-flex justify-content-end"
          style={styles.rightDetailsStyle}
        >
          <PrimaryText>
            <BoldText>{content.likes}</BoldText>
            {" Likes | "}
            <BoldText>
              <NumberFormat
                value={content.tips}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
              />
            </BoldText>
            {" Tips"}
          </PrimaryText>
        </div>
      </Row>
    );
  };

  const toggleBookmark = () => {
    const currentBookmark = !content.isBookmarked;
    setContent({ ...content, isBookmarked: currentBookmark });

    if (currentBookmark) {
      postAddBookmark(content.postId, user.uid, () => {});
    } else {
      deleteRemoveBookmark(content.postId, user.uid, () => {});
    }
  };

  const toggleLike = () => {
    const currentLike = !content.isLiked;

    if (currentLike) {
      postAddLike(content.postId, content.userId, user.uid, () => {});
      setContent({
        ...content,
        isLiked: currentLike,
        likes: parseInt(content.likes) + 1,
      });
    } else {
      deleteRemoveLike(content.postId, user.uid, () => {});
      setContent({
        ...content,
        isLiked: currentLike,
        likes: parseInt(content.likes) - 1,
      });
    }
  };

  const cardStyle = singleMode ? styles.cardSingleModeStyle : styles.cardStyle;

  return (
    <div>
      <BrowserView>
        <div className="container">
          <div className="card" style={cardStyle}>
            <div className="card-body">
              <AvatarSection />
              <MediaSection />
              <InteractiveSection />
              <DescriptionSection />
              <CommentSection />
              <TipModal
                show={showTipModal}
                toggleModal={toggleTipModal}
                content={content}
              />
              <UnlockContentModal
                show={showUnlockContentModal}
                toggleModal={toggleUnlockContentModal}
                content={content}
              />
            </div>
          </div>
        </div>
      </BrowserView>
      <MobileView style={styles.mobileSectionStyle}>
        <AvatarSection />
        <MediaSection onClick={() => setShowUnlockContentModal(true)} />
        <InteractiveSection style={{ paddingLeft: 10, paddingRight: 10 }} />
        <DescriptionSection style={{ paddingLeft: 10 }} />
        <CommentSection style={{ paddingLeft: -5 }} />
        <TipModal
          show={showTipModal}
          toggleModal={toggleTipModal}
          content={content}
        />
        <UnlockContentModal
          show={showUnlockContentModal}
          toggleModal={toggleUnlockContentModal}
          content={content}
        />
      </MobileView>
    </div>
  );
};

export default ContentDetailCard;

const styles = {
  avatarSectionRightColStyle: {
    textAlign: "right",
    paddingLeft: 0,
    paddingRight: 0,
  },
  cardSingleModeStyle: {
    display: "inline-block",
    backgroundColor: "white",
    marginBottom: 25,
    width: 650,
  },
  cardStyle: {
    display: "inline-block",
    backgroundColor: "white",
    borderColor: COLORS.darkPurple,
    borderStyle: "solid",
    borderWidth: 2,
    marginBottom: 25,
    width: 650,
  },
  clearButtonStyle: {
    background: "none",
    border: "none",
    outline: "none",
    padding: 0,
    minHeight: 52,
  },
  commentItemsStyle: {
    marginTop: 15,
  },
  commentTextMobileStyle: {
    color: COLORS.darkPurple,
    fontSize: 20,
    fontWeight: "bold",
    textAlign: "left",
  },
  commentTextStyle: {
    color: COLORS.darkPurple,
    fontSize: 24,
    fontWeight: "bold",
    textAlign: "left",
  },
  descriptionStyle: {
    textAlign: "left",
    fontSize: 20,
    fontWeight: "400",
    marginBottom: 20,
    marginTop: 20,
    paddingLeft: 15,
    paddingRight: 15,
  },
  editButtonStyle: {
    marginTop: 10,
    marginRight: 10,
    height: 35,
  },
  headerStyle: {
    marginBottom: 10,
    paddingLeft: 5,
    paddingRight: 5,
  },
  headerUsernameStyle: {
    paddingLeft: 0,
    paddingTop: 15,
    textAlign: "left",
  },
  imageListStyle: {
    listStyle: "none",
    textAlign: "left",
  },
  imageRowStyle: {
    marginBottom: 10,
    alignItems: "flex-start",
  },
  leftDetailsStyle: {
    textAlign: "left",
    paddingLeft: 10,
  },
  mobileSectionStyle: {
    paddingLeft: 0,
    paddingRight: 0,
    marginTop: 5,
    marginBottom: 25,
  },
  nameStyle: {
    color: COLORS.darkPurple,
    fontWeight: "bold",
  },
  rightDetailsStyle: {
    display: "flex",
    textAlign: "right",
    paddingRight: 10,
  },
  timeAgoSectionStyle: {
    marginTop: -10,
  },
  timeAgoStringStyle: {
    color: COLORS.darkPurple,
    fontWeight: "bold",
    textAlign: "right",
  },
};
