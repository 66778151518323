import React from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import { BrowserView, MobileView } from "react-device-detect";
import { BoldText } from "../../../components/texts/PageText";
import { LAYOUTS } from "../../../constants";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./../ListSettings.css";
const RestrictedList = (props) => {
  const BodySection = () => {
    return (
      <Row>
        <Col xs={12} lg={12}>
          <h5 className="text-muted">Restricted</h5>
          <ul
            className="nav nav-tabs nav-followingList"
            id="myTab"
            role="tablist"
          >
            <li className="nav-item followingListTab" role="presentation">
              <a
                className="nav-link active nav-link-followingList"
                id="all-tab"
                data-toggle="tab"
                href="#all"
                role="tab"
                aria-controls="all"
                aria-selected="true"
              >
                All 0
              </a>
            </li>
            <li className="nav-item followingListTab" role="presentation">
              <a
                className="nav-link nav-link-followingList"
                id="active-tab"
                data-toggle="tab"
                href="#active"
                role="tab"
                aria-controls="active"
                aria-selected="false"
              >
                Active 0
              </a>
            </li>
            <li className="nav-item followingListTab" role="presentation">
              <a
                className="nav-link nav-link-followingList"
                id="expired-tab"
                data-toggle="tab"
                href="#expired"
                role="tab"
                aria-controls="expired"
                aria-selected="false"
              >
                Expired 0
              </a>
            </li>
            <li className="nav-item followingListTab" role="presentation">
              <a
                className="nav-link nav-link-followingList"
                id="restricted-tab"
                data-toggle="tab"
                href="#restricted"
                role="tab"
                aria-controls="restricted"
                aria-selected="false"
              >
                Restricted 0
              </a>
            </li>
            <li className="nav-item followingListTab" role="presentation">
              <a
                className="nav-link nav-link-followingList"
                id="block-tab"
                data-toggle="tab"
                href="#blocked"
                role="tab"
                aria-controls="blocked"
                aria-selected="false"
              >
                Blocked 0
              </a>
            </li>
          </ul>
          <div
            className="tab-content"
            id="myTabContent"
            style={{ marginTop: "1em" }}
          >
            <div
              className="tab-pane fade show active"
              id="all"
              role="tabpanel"
              aria-labelledby="all-tab"
            >
              Nothing was found
            </div>
            <div
              className="tab-pane fade"
              id="active"
              role="tabpanel"
              aria-labelledby="active-tab"
            >
              Nothing was found
            </div>
            <div
              className="tab-pane fade"
              id="expired"
              role="tabpanel"
              aria-labelledby="expired-tab"
            >
              Nothing was found
            </div>
            <div
              className="tab-pane fade"
              id="restricted"
              role="tabpanel"
              aria-labelledby="restricted-tab"
            >
              Nothing was found
            </div>
            <div
              className="tab-pane fade"
              id="blocked"
              role="tabpanel"
              aria-labelledby="blocked-tab"
            >
              Nothing was found
            </div>
          </div>
        </Col>
      </Row>
    );
  };

  return (
    <div>
      <BrowserView>
        <Container className="pb-4" style={LAYOUTS.bodyStyle}>
          <Row style={LAYOUTS.mainSectionStyleBlank}>
            <Col md={12} className="text-left" style={LAYOUTS.bodyStyle}>
              <h4>
                <BoldText>RESTRICTED</BoldText>
              </h4>
              <hr style={styles.hr} />
              <BodySection />
              <Link to={"/my/lists"}>
                <Button
                  variant="secondary"
                  size="md"
                  style={{ float: "right" }}
                >
                  <span style={{ float: "left" }}>
                    <FaArrowAltCircleLeft size={17} />
                  </span>
                  <span style={styles.notificationBackbutton}>Back</span>
                </Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </BrowserView>
      <MobileView>
        <Container className="pb-4 headerBody" style={LAYOUTS.bodyStyle}>
          <Row>
            <span style={styles.profileComponentHeader}>RESTRICTED</span>
            <Col xs={12} className="text-left mt-2">
              <BodySection />

              <Link to={"/my/lists"}>
                <Button
                  variant="secondary"
                  size="md"
                  style={{ float: "right" }}
                >
                  <span style={{ float: "left" }}>
                    <FaArrowAltCircleLeft size={17} />
                  </span>
                  <span style={styles.notificationBackbutton}>Back</span>
                </Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </MobileView>
    </div>
  );
};

const styles = {
  headerStyle: {
    paddingLeft: 25,
    height: 300,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  profileComponentHeader: {
    fontSize: "25px",
    fontWeight: 600,
  },
  notificationBackbutton: {
    paddingLeft: "10px",
    fontWeight: "600",
    fontSize: "small",
  },
};

export default RestrictedList;
