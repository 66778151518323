import React, { useEffect } from "react";
import { Row, Form, Col, Container } from "react-bootstrap";
import Sidebar from "../../../components/profile/Sidebar";
import Header from "../../../components/profile/Header";
import { BrowserView, MobileView } from "react-device-detect";
import { LAYOUTS } from "../../../constants";
import { BoldText } from "../../../components/texts/PageText";
import MobileSidebar from "../../../components/profile/MobileSidebar";

const PrivacySettings = (props) => {
  const BodySection = () => {
    return (
      <div>
        <Row>
          <Col xs={10}>
            <h5 className="text-muted">Show activity status</h5>
          </Col>
          <Col xs={2} className="text-right">
            <Form>
              <Form.Check type="switch" label="" id="status-custom-switch" />
            </Form>
          </Col>
        </Row>
        <hr style={styles.hr} />
        <Row>
          <Col xs={10}>
            <h5 className="text-muted">Show subscription offers </h5>
          </Col>
          <Col xs={2} className="text-right">
            <Form>
              <Form.Check
                type="switch"
                label=""
                id="subscription-custom-switch"
              />
            </Form>
          </Col>
        </Row>
      </div>
    );
  };
  useEffect(() => {}, []);

  return (
    <div>
      <BrowserView>
        <Container style={LAYOUTS.bodyStyle}>
          <Row style={LAYOUTS.mainSectionStyleBlank}>
            <Header />
            <Col md={3}>
              <Sidebar />
            </Col>
            <Col md={9} className="text-left">
              <h4>
                <BoldText>Privacy & Safety</BoldText>
              </h4>
              <hr style={styles.hr} />
              <BodySection />
            </Col>
          </Row>
        </Container>
      </BrowserView>
      <MobileView>
        <div className="container header" style={LAYOUTS.profileHeaderStyle}>
          <Row>
            <Header />
          </Row>
        </div>
        <div className="container pb-4 headerBody" style={LAYOUTS.bodyStyle}>
          <Row>
            <MobileSidebar />
            <span style={styles.profileComponentHeader}>Privacy & Safety</span>
            <Col md={12} className="text-left mt-2">
              <BodySection />
            </Col>
          </Row>
        </div>
      </MobileView>
    </div>
  );
};

const styles = {
  headerStyle: {
    paddingLeft: 25,
    height: 300,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  profileComponentHeader: {
    fontSize: "25px",
    fontWeight: 600,
  },
};

export default PrivacySettings;
