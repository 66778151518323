import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { LAYOUTS } from "../../constants";
import "./Stats.css"
import Statement from "./components/statement";
import { Row, Col } from "react-bootstrap";

const Stats = () => {
  const BodySection = () => {
    return (
      <Row>
        <Col md={3} xs={12}>
          <div className="d-flex align-items-start">
            <ul
              className="nav nav-tabs flex-column statistics"
              style={{ borderBottom: "unset" }}
            >
              <li className="nav-item">
                <a
                  className="nav-link active"
                  data-toggle="tab"
                  href="#s1"
                  style={{ borderTop: "none" }}
                >
                  Statements
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-toggle="tab" href="#s2">
                  Earnings
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-toggle="tab" href="#s3">
                  Payout Requests
                </a>
              </li>

              <li className="nav-item">
                <a className="nav-link" data-toggle="tab" href="#s4">
                  Earning Statistics
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-toggle="tab" href="#s5">
                  Payout Requests
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-toggle="tab" href="#s6">
                  Earning Statistics
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-toggle="tab" href="#s7">
                  Payout Requests
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-toggle="tab" href="#s8">
                  Earning Statistics
                </a>
              </li>
            </ul>{" "}
          </div>
        </Col>
        <Col md={9} xs={12} className="statTable">
          <div className="tab-content statTab">
            <div
              className="tab-pane container fade in show active text-left"
              id="s1"
            >
              {" "}
              <span style={styles.profileComponentHeader}>STATEMENTS</span>
              <div style={styles.formDiv}>
                <Statement />
              </div>
            </div>
            <div className="tab-pane container fade text-left" id="s2">
              <span style={styles.profileComponentHeader}>EARNINGS</span>
            </div>
            <div className="tab-pane container fade text-left" id="s3">
              <span style={styles.profileComponentHeader}>PAYOUT REQUESTS</span>
            </div>
            <div className="tab-pane container fade text-left" id="s4">
              <span style={styles.profileComponentHeader}>EARNINGS</span>
            </div>
            <div className="tab-pane container fade text-left" id="s5">
              <span style={styles.profileComponentHeader}>Payout Requests</span>
            </div>
            <div className="tab-pane container fade text-left" id="s6">
              <span style={styles.profileComponentHeader}>EARNINGS</span>
            </div>
            <div className="tab-pane container fade" id="s7">
              Hello Payout Requests
            </div>
            <div className="tab-pane container fade" id="s8">
              Hello Earnings
            </div>
            <div className="tab-pane container fade" id="s9">
              Hello Payout Requests
            </div>
          </div>
        </Col>
      </Row>
    );
  };

  return (
    <div>
      <BrowserView>
        <div className="container pb-4 pt-4" style={LAYOUTS.bodyStyle}>
          <BodySection />
        </div>
      </BrowserView>
      <MobileView>
        <div
          className="container-fluid"
          style={{ paddingLeft: 0, paddingRight: 0 }}
        >
          <BodySection />
        </div>
      </MobileView>
    </div>
  );
};

export default Stats;

const styles = {
  formDiv: {
    background: "#f7f7f9",
    padding: "3%",
  },
  profileComponentHeader: {
    fontSize: "25px",
    fontWeight: 600,
  },
};
