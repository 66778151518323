import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../providers/AuthProvider";
import { Row, Form, Col, Button } from "react-bootstrap";
import Sidebar from "../../../../components/profile/Sidebar";
import Header from "../../../../components/profile/Header";
import { BrowserView, MobileView } from "react-device-detect";
import { LAYOUTS } from "../../../../constants";
import { BoldText } from "../../../../components/texts/PageText";
import {
  getOtherNotifications,
  postOtherNotifications,
} from "../../../../api/ApiManager";
import MobileSidebar from "../../../../components/profile/MobileSidebar";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { Link } from "react-router-dom";

const OtherNotification = (props) => {
  const { user } = useContext(AuthContext);
  const [content, setContent] = useState({});

  const handleChange = (e) => {
    postOtherNotifications(e.target.checked, user.uid, () => {
      alert("Other Notification updated!");
    });

    setContent({ ...content, [e.target.name]: e.target.checked });
  };

  useEffect(() => {
    getOtherNotifications(user.uid, setContent);
  }, [user.uid]);

  const BodySection = () => {
    return (
      <div>
        <Row>
          <Col xs={10}>
            <h5 className="text-muted">Other Notification</h5>
          </Col>
          <Col xs={2} className="text-right">
            <Form>
              <Form.Check
                type="switch"
                id="other-custom-switch"
                onChange={(e) => handleChange(e)}
                name="other"
                checked={content.other}
              />
            </Form>
          </Col>
        </Row>

        <h6>
          Get other notifications to find out what’s going on when you’re not on
          IntrigueMe. You can turn them off anytime.
        </h6>
      </div>
    );
  };

  return (
    <div>
      <BrowserView>
        <div className="container" style={LAYOUTS.profileHeaderStyle}>
          <Row>
            <Header />
          </Row>
        </div>
        <div className="container pb-4" style={LAYOUTS.bodyStyle}>
          <Row>
            <Col md={3}>
              <Sidebar />
            </Col>
            <Col md={9} className="text-left">
              <h4>
                <BoldText>Other Notifications</BoldText>
              </h4>
              <hr style={styles.hr} />
              <BodySection />

              <Link to={"/settings/notification"}>
                <Button
                  variant="secondary"
                  size="md"
                  style={{ float: "right" }}
                >
                  <span style={{ float: "left" }}>
                    <FaArrowAltCircleLeft size={17} />
                  </span>
                  <span style={styles.notificationBackbutton}>Back</span>
                </Button>
              </Link>
            </Col>
          </Row>
        </div>
      </BrowserView>
      <MobileView>
        <div className="container header" style={LAYOUTS.profileHeaderStyle}>
          <Row>
            {" "}
            <Header />{" "}
          </Row>
        </div>
        <div className="container pb-4 headerBody" style={LAYOUTS.bodyStyle}>
          <Row>
            <MobileSidebar />
            <span style={styles.profileComponentHeader}>
              Other Notifications
            </span>
            <Col md={12} className="text-left mt-2">
              <BodySection />

              <Link to={"/settings/notification"}>
                <Button
                  variant="secondary"
                  size="md"
                  style={{ float: "right" }}
                >
                  <span style={{ float: "left" }}>
                    <FaArrowAltCircleLeft size={17} />
                  </span>
                  <span style={styles.notificationBackbutton}>Back</span>
                </Button>
              </Link>
            </Col>
          </Row>
        </div>
      </MobileView>
    </div>
  );
};

const styles = {
  headerStyle: {
    paddingLeft: 25,
    height: 300,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  profileComponentHeader: {
    fontSize: "25px",
    fontWeight: 600,
  },
  notificationBackbutton: {
    paddingLeft: "10px",
    fontWeight: "600",
    fontSize: "small",
  },
};

export default OtherNotification;
