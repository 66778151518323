import React from "react";
import { ListGroup } from "react-bootstrap";
import { Row, Col, Container } from "react-bootstrap";
import Sidebar from "../../../../components/profile/Sidebar";
import Header from "../../../../components/profile/Header";
import { BrowserView, MobileView } from "react-device-detect";
import { LAYOUTS } from "../../../../constants";
import { BoldText } from "../../../../components/texts/PageText";
import { FaCaretRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import MobileSidebar from "../../../../components/profile/MobileSidebar";

const NotificationSettings = (props) => {
  const BodySection = () => {
    return (
      <ListGroup>
        <ListGroup.Item>
          <BoldText>Preferences</BoldText>
        </ListGroup.Item>

        <Link to="/settings/notification/push">
          {" "}
          <ListGroup.Item className="TFList">
            Push notifications{" "}
            <span className="notifSettings">
              <FaCaretRight />
            </span>
          </ListGroup.Item>
        </Link>

        <Link to="/settings/notification/email">
          <ListGroup.Item className="TFList">
            Email notifications
            <span className="notifSettings">
              <FaCaretRight />
            </span>
          </ListGroup.Item>
        </Link>

        <Link to="/settings/notification/site">
          <ListGroup.Item className="TFList">
            Site notifications
            <span className="notifSettings">
              <FaCaretRight />
            </span>
          </ListGroup.Item>
        </Link>

        <Link to="/settings/notification/toast">
          <ListGroup.Item className="TFList">
            Toast notifications
            <span className="notifSettings">
              <FaCaretRight />
            </span>
          </ListGroup.Item>
        </Link>

        <Link to="/settings/notification/sms">
          <ListGroup.Item className="TFList">
            SMS notifications
            <span className="notifSettings">
              <FaCaretRight />
            </span>
          </ListGroup.Item>
        </Link>

        <Link to="/settings/notification/other">
          <ListGroup.Item className="TFList">Other</ListGroup.Item>
        </Link>

        <Link to="/settings/notification/telegram">
          <ListGroup.Item className="TFList">
            Telegram bot
            <span className="notifSettings">
              <FaCaretRight />
            </span>
          </ListGroup.Item>
        </Link>
      </ListGroup>
    );
  };

  return (
    <div>
      <BrowserView>
        <Container style={LAYOUTS.bodyStyle}>
          <Row style={LAYOUTS.mainSectionStyleBlank}>
            <Header />
            <Col md={3}>
              <Sidebar />
            </Col>
            <Col md={9} className="text-left">
              <h4>
                <BoldText>Notification</BoldText>
              </h4>
              <BodySection />
            </Col>
          </Row>
        </Container>
      </BrowserView>
      <MobileView>
        <div className="container header" style={LAYOUTS.profileHeaderStyle}>
          <Row>
            {" "}
            <Header />{" "}
          </Row>
        </div>
        <div className="container pb-4 headerBody" style={LAYOUTS.bodyStyle}>
          <Row>
            <MobileSidebar />
            <span style={styles.profileComponentHeader}>Notification</span>
            <Col md={12} className="text-left mt-2">
              <BodySection />
            </Col>
          </Row>
        </div>
      </MobileView>
    </div>
  );
};

const styles = {
  headerStyle: {
    paddingLeft: 25,
    height: 300,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  profileComponentHeader: {
    fontSize: "25px",
    fontWeight: 600,
  },
};

export default NotificationSettings;
