import React, { useContext, useEffect, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { AuthContext } from "../../../providers/AuthProvider";
import { LAYOUTS } from "../../../constants";
import { getUserProfile, postUserProfile } from "../../../api/ApiManager";
import Header from "../../../components/profile/Header";
import Sidebar from "../../../components/profile/Sidebar";
import { Row, Col, Form, Container } from "react-bootstrap";
import MobileSidebar from "../../../components/profile/MobileSidebar";
import { BasicButton } from "../../../components/buttons/Buttons";
import { BoldText } from "../../../components/texts/PageText";

const ProfileSettings = (props) => {
  const { user } = useContext(AuthContext);
  const [content, setContent] = useState({});

  const handleChange = (e) => {
    setContent({ ...content, [e.target.name]: e.target.value });
  };

  const handleSave = () => {
    const userProfileContent = {
      uuid: user.uid,
      userName: content.userName,
      displayName: content.displayName,
      bio: content.bio,
      location: content.location,
      website: content.website,
    };

    handleValidation()
      ? postUserProfile(userProfileContent, () => {
          alert("Updated!");
          window.location.reload();
        })
      : alert("Please enter valid values");
  };

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;
    //userName
    if (!content.userName || content.userName === "") {
      formIsValid = false;
      errors["userName"] = { status: true, msg: "username can't be empty" };
    }

    if (typeof content.userName !== "undefined") {
      // if (!content.userName.match(/^[a-zA-Z0-9]+$/)) {
      //   formIsValid = false;
      //   errors["userName"] = { msg: "Only letters and numbers" };
      // }
      if (!content.userName.match(/^\S+$/)) {
        formIsValid = false;
        errors["userName"] = { msg: "no spaces" };
      }
    }

    //Display Name
    if (!content.displayName || content.displayName === "") {
      formIsValid = false;
      errors["displayName"] = { msg: "Display name can't be empty" };
    }

    setContent({ ...content, errors });
    return formIsValid;
  };
  
  useEffect(() => {
    getUserProfile(user.uid, (content) => {
      if (typeof content !== "undefined") {
        setContent(content);
      }
    });
  }, [user.uid]);

  return (
    <div>
      <BrowserView>
        <Container style={LAYOUTS.bodyStyle}>
          <Row style={LAYOUTS.mainSectionStyleBlank}>
            <Header />
            <Col md={3}>
              <Sidebar isMenuOpen={false} isMobile={false} />
            </Col>
            <Col md={9} className="text-left">
            <h4>
                <BoldText>Profile</BoldText>
              </h4>

              <Form.Group>
                <Row style={styles.group}>
                  <Form.Label
                    column
                    md={4}
                    className="text-left"
                    style={styles.label}
                  >
                    Username
                  </Form.Label>
                  <Col>
                    <Form.Control
                      className="shadow-none"
                      type="text"
                      placeholder="Username"
                      style={styles.input}
                      value={content.userName || ""}
                      name="userName"
                      onChange={(e) => handleChange(e)}
                      isInvalid={
                        content.errors
                          ? content.errors.userName
                            ? true
                            : false
                          : false
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {content.errors
                        ? content.errors.userName
                          ? content.errors.userName.msg
                          : ""
                        : ""}
                    </Form.Control.Feedback>
                  </Col>
                </Row>

                <Row style={styles.group}>
                  <Form.Label
                    column
                    md={4}
                    className="text-left"
                    style={styles.label}
                  >
                    Display Name
                  </Form.Label>
                  <Col>
                    <Form.Control
                      className="shadow-none"
                      type="text"
                      placeholder="Display Name"
                      style={styles.input}
                      value={content.displayName || ""}
                      name="displayName"
                      onChange={(e) => handleChange(e)}
                      isInvalid={
                        content.errors
                          ? content.errors.displayName
                            ? true
                            : false
                          : false
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {content.errors
                        ? content.errors.displayName
                          ? content.errors.displayName.msg
                          : ""
                        : ""}
                    </Form.Control.Feedback>
                  </Col>
                </Row>

                <Row style={styles.group}>
                  <Form.Label
                    column
                    md={4}
                    className="text-left"
                    style={styles.label}
                  >
                    Bio
                  </Form.Label>
                  <Col>
                    <Form.Control
                      className="shadow-none"
                      type="text"
                      style={styles.input}
                      placeholder="bio"
                      value={content.bio || ""}
                      name="bio"
                      onChange={(e) => handleChange(e)}
                    />
                  </Col>
                </Row>

                <Row style={styles.group}>
                  <Form.Label
                    column
                    md={4}
                    className="text-left"
                    style={styles.label}
                  >
                    Location
                  </Form.Label>
                  <Col>
                    <Form.Control
                      className="shadow-none"
                      type="text"
                      style={styles.input}
                      placeholder="Location"
                      value={content.location || ""}
                      name="location"
                      onChange={(e) => handleChange(e)}
                    />
                  </Col>
                </Row>

                <Row style={styles.group}>
                  <Form.Label
                    column
                    md={4}
                    className="text-left"
                    style={styles.label}
                  >
                    Website
                  </Form.Label>
                  <Col>
                    <Form.Control
                      className="shadow-none"
                      type="text"
                      style={styles.input}
                      placeholder="Website"
                      value={content.website || ""}
                      name="website"
                      onChange={(e) => handleChange(e)}
                    />
                  </Col>
                </Row>

                <Row className="pb-3 text-right">
                  <Col>
                    <BasicButton text="Save" onClick={handleSave} />
                  </Col>
                </Row>
              </Form.Group>
            </Col>
          </Row>
        </Container>
      </BrowserView>

      <MobileView>
        <div className="container header" style={LAYOUTS.profileHeaderStyle}>
          <Row>
            <Header />
          </Row>
        </div>
        <div className="container pb-4 headerBody" style={LAYOUTS.bodyStyle}>
          <Row>
            <MobileSidebar />
            <span style={styles.profileComponentHeader}>Profile</span>
            <Col xs={12} className="text-left">
              <Form.Group>
                <Row style={styles.group}>
                  <Form.Label
                    column
                    md={4}
                    className="text-left"
                    style={styles.label}
                  >
                    Username
                  </Form.Label>
                  <Col>
                    <Form.Control
                      className="shadow-none"
                      type="text"
                      placeholder="Username"
                      style={styles.input}
                      value={content.userName || ""}
                      name="username"
                      onChange={(e) => handleChange(e)}
                      isInvalid={
                        content.errors
                          ? content.errors.userName
                            ? true
                            : false
                          : false
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {content.errors
                        ? content.errors.userName
                          ? content.errors.userName.msg
                          : ""
                        : ""}
                    </Form.Control.Feedback>
                  </Col>
                </Row>

                <Row style={styles.group}>
                  <Form.Label
                    column
                    md={4}
                    className="text-left"
                    style={styles.label}
                  >
                    Display Name
                  </Form.Label>
                  <Col>
                    <Form.Control
                      className="shadow-none"
                      type="text"
                      placeholder="display name"
                      value={content.displayName || ""}
                      name="displayname"
                      onChange={(e) => handleChange(e)}
                      isInvalid={
                        content.errors
                          ? content.errors.displayName
                            ? true
                            : false
                          : false
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {content.errors
                        ? content.errors.displayName
                          ? content.errors.displayName.msg
                          : ""
                        : "hello"}
                    </Form.Control.Feedback>
                  </Col>
                </Row>

                <Row style={styles.group}>
                  <Form.Label
                    column
                    md={4}
                    className="text-left"
                    style={styles.label}
                  >
                    Bio
                  </Form.Label>
                  <Col>
                    <Form.Control
                      className="shadow-none"
                      type="text"
                      placeholder="bio"
                      value={content.bio || ""}
                      name="bio"
                      onChange={(e) => handleChange(e)}
                    />
                  </Col>
                </Row>

                <Row style={styles.group}>
                  <Form.Label
                    column
                    md={4}
                    className="text-left"
                    style={styles.label}
                  >
                    Location
                  </Form.Label>
                  <Col>
                    <Form.Control
                      className="shadow-none"
                      type="text"
                      placeholder="Location"
                      value={content.location || ""}
                      name="location"
                      onChange={(e) => handleChange(e)}
                    />
                  </Col>
                </Row>

                <Row style={styles.group}>
                  <Form.Label
                    column
                    md={4}
                    className="text-left"
                    style={styles.label}
                  >
                    Website
                  </Form.Label>
                  <Col>
                    <Form.Control
                      className="shadow-none"
                      type="text"
                      placeholder="Website"
                      value={content.website || ""}
                      name="website"
                      onChange={(e) => handleChange(e)}
                    />
                  </Col>
                </Row>

                <Row className="pb-3 text-right">
                  <Col>
                    <BasicButton text="Save" onClick={handleSave} />
                  </Col>
                </Row>
              </Form.Group>
            </Col>
          </Row>
        </div>
      </MobileView>
    </div>
  );
};

export default ProfileSettings;

const styles = {
  avatarStyle: {
    width: 140,
    height: 140,
    borderRadius: "50%",
  },
  profileComponentHeader: {
    fontSize: "25px",
    fontWeight: 600,
  },
  input: {
    border: "unset",
    background: "unset",
  },
  label: {
    fontWeight: 400,
  },
  group: {
    borderBottom: "1px solid black",
  },
};
