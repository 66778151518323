import React from "react";
import { Link } from "react-router-dom";
import { COLORS } from "../../constants";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { Button } from "react-bootstrap";
import moneyImage from "../../assets/icons/money.svg";

const BackButton = (props) => {
  const { onClick, to } = props;

  if (typeof onClick !== "undefined") {
    return (
      <Button
        variant="secondary"
        size="md"
        style={{ float: "right" }}
        onClick={onClick}
      >
        <span style={{ float: "left" }}>
          <FaArrowAltCircleLeft size={17} />
        </span>
        <span style={styles.backButtonTextStyle}>Back</span>
      </Button>
    );
  } else {
    return (
      <Link to={to}>
        <Button variant="secondary" size="md" style={{ float: "right" }}>
          <span style={{ float: "left" }}>
            <FaArrowAltCircleLeft size={17} />
          </span>
          <span style={styles.backButtonTextStyle}>Back</span>
        </Button>
      </Link>
    );
  }
};

const BasicButton = (props) => {
  const { onClick, text, disabled } = props;

  return (
    <Button
      variant="secondary"
      size="md"
      style={{ float: "right" }}
      onClick={onClick}
      disabled={disabled}
    >
      <span style={styles.buttonTextStyle}>{text}</span>
    </Button>
  );
};

const ClearButton = (props) => {
  const { onClick, style } = props;

  return (
    <button onClick={onClick} style={{ ...styles.clearButtonStyle, ...style }}>
      {props.children}
    </button>
  );
};
const MainButton = (props) => {
  const { to, text } = props;

  return (
    <Link to={to}>
      <Button variant="secondary" size="md" style={{ float: "right" }}>
        <span style={styles.buttonTextStyle}>{text}</span>
      </Button>
    </Link>
  );
};

const SizableButton = (props) => {
  const { to, text, width } = props;

  return (
    <Link to={to}>
      <Button
        variant="secondary"
        size="md"
        style={{ ...styles.sizableButtonStyle, ...{ width: width } }}
      >
        <span style={styles.buttonTextStyle}>{text}</span>
      </Button>
    </Link>
  );
};

const TipButton = (props) => {
  return (
    <Button
      variant="secondary"
      size="md"
      style={{ float: "right", padding: 10 }}
      onClick={props.onClick}
    >
      <span style={{ float: "left" }}>
        <img
          id="tip_modal"
          src={moneyImage}
          width={25}
          // style={}
          alt="tip link"
        />
      </span>
      <span style={styles.backButtonTextStyle}>Send a tip</span>
    </Button>
  );
};

export {
  BackButton,
  BasicButton,
  ClearButton,
  MainButton,
  SizableButton,
  TipButton,
};

const styles = {
  backButtonTextStyle: {
    paddingLeft: "10px",
    fontWeight: "600",
    fontSize: "small",
  },
  buttonTextStyle: {
    fontWeight: "600",
    fontSize: "small",
  },
  clearButtonStyle: {
    backgroundColor: "red",
    background: "none",
    border: "none",
    outline: "none",
    padding: 0,
  },
  mainButtonStyle: {
    backgroundColor: COLORS.darkPurple,
  },
  sizableButtonStyle: {
    paddingLeft: 0,
    paddingRight: 0,
    margin: 0,
  },
};
