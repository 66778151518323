import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { BrowserView, MobileView } from "react-device-detect";
import { BoldText } from "../../../components/texts/PageText";
import { LAYOUTS } from "../../../constants";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../providers/AuthProvider";
import "../ListSettings.css";
import { getUserFollowing } from "../../../api/ApiManager";
import UserCard from "../../../components/cards/UserCard";


const FollowingList = (props) => {
  const { user } = useContext(AuthContext);
  const [followingAll, setFollowingAll] = useState([]);
  const [followingActive, setFollowingActive] = useState([]);
  const [followingExpired, setFollowingExpired] = useState([]); 

  // Feeds
  const FollowingAllFeed = () => {
    const cards = followingAll.map((following) => {
      return (
        <div key={following.userId}>
          <UserCard data={following} />
        </div>
      );
    });
    return cards;
  };

  const FollowingActiveFeed = () => {
    const cards = followingActive.map((following) => {
      return <UserCard key={following.userId} data={following} />;
    });
    return cards;
  };

  const FollowingExpiredFeed = () => {
    const cards = followingExpired.map((following) => {
      return <UserCard key={following.userId} data={following} />;
    });
    return cards;
  };

  const BodySection = () => {
    return (
      <Row>
        <Col xs={12} lg={12}>
          <h5 className="text-muted">LAST ACTIVITY</h5>
          <ul
            className="nav nav-tabs nav-followingList"
            id="myTab"
            role="tablist"
          >
            <li className="nav-item followingListTab" role="presentation">
              <a
                className="nav-link active nav-link-followingList"
                id="all-tab"
                data-toggle="tab"
                href="#all"
                role="tab"
                aria-controls="all"
                aria-selected="true"
              >
                {`All ${followingAll.length}`}
              </a>
            </li>
            <li className="nav-item followingListTab" role="presentation">
              <a
                className="nav-link nav-link-followingList"
                id="active-tab"
                data-toggle="tab"
                href="#active"
                role="tab"
                aria-controls="active"
                aria-selected="false"
              >
                {`Active ${followingActive.length}`}
              </a>
            </li>
            <li className="nav-item followingListTab" role="presentation">
              <a
                className="nav-link nav-link-followingList"
                id="expired-tab"
                data-toggle="tab"
                href="#expired"
                role="tab"
                aria-controls="expired"
                aria-selected="false"
              >
                {`Expired ${followingExpired.length}`}
              </a>
            </li>
          </ul>
          <div
            className="tab-content"
            id="myTabContent"
            style={{ marginTop: "1em" }}
          >
            <div
              className="tab-pane fade show active"
              id="all"
              role="tabpanel"
              aria-labelledby="all-tab"
            >
              <div style={styles.feedSectionStyle}>
                <FollowingAllFeed />
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="active"
              role="tabpanel"
              aria-labelledby="active-tab"
            >
              <div style={styles.feedSectionStyle}>
                <FollowingActiveFeed />
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="expired"
              role="tabpanel"
              aria-labelledby="expired-tab"
            >
              <div style={styles.feedSectionStyle}>
                <FollowingExpiredFeed />
              </div>
            </div>
          </div>
        </Col>
      </Row>
    );
  };

  useEffect(() => {
    if (user.uid !== "") {
      getUserFollowing(user.uid, (followings) => {
        setFollowingAll(followings);

        setFollowingActive(
          followings.filter((following) => {
            return following.active === true;
          })
        );

        setFollowingExpired(
          followings.filter((following) => {
            return following.expired === true;
          })
        );
      });
    }
  }, [user.uid]);

  return (
    <div>
      <BrowserView>
        <div className="container pb-4">
          <Row style={LAYOUTS.bodyStyle}>
            <Col
              md={12}
              className="text-left"
              style={LAYOUTS.mainSectionStyleBlank}
            >
              <h4>
                <BoldText>FOLLOWING</BoldText>
              </h4>
              <hr style={styles.hr} />
              <BodySection />
              <Link to={"/my/lists"}>
                <Button
                  variant="secondary"
                  size="md"
                  style={{ float: "right" }}
                >
                  <span style={{ float: "left" }}>
                    <FaArrowAltCircleLeft size={17} />
                  </span>
                  <span style={styles.notificationBackbutton}>Back</span>
                </Button>
              </Link>
            </Col>
          </Row>
        </div>
      </BrowserView>
      <MobileView>
        <div className="container pb-4 headerBody" style={LAYOUTS.bodyStyle}>
          <Row>
            <span style={styles.profileComponentHeader}>LAST ACTIVITY</span>
            <Col xs={12} className="text-left mt-2">
              <BodySection />
              <Link to={"/my/lists"}>
                <Button
                  variant="secondary"
                  size="md"
                  style={{ float: "right" }}
                >
                  <span style={{ float: "left" }}>
                    <FaArrowAltCircleLeft size={17} />
                  </span>
                  <span style={styles.notificationBackbutton}>Back</span>
                </Button>
              </Link>
            </Col>
          </Row>
        </div>
      </MobileView>
    </div>
  );
};

const styles = {
  feedSectionStyle: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "left",
  },
  headerStyle: {
    paddingLeft: 25,
    height: 300,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  profileComponentHeader: {
    fontSize: "25px",
    fontWeight: 600,
  },
  notificationBackbutton: {
    paddingLeft: "10px",
    fontWeight: "600",
    fontSize: "small",
  },
};

export default FollowingList;
