import React, { useState } from "react";
import { MDBModal, MDBModalBody, MDBModalHeader } from "mdbreact";
import { Row, Form } from "react-bootstrap";
import { COLORS } from "../../constants";
import { BasicButton } from "../buttons/Buttons";

const UnlockContentModal = (props) => {
  const { content } = props;
  
  const handleSubmit = () => {
    props.toggleModal();
  };

  const price = content.price === 0 ? "$0.00" : `$${content.price}`;

  return (
    <MDBModal
      id="centralModalInfo"
      centered
      className="modal-dialog modal-md modal-notify"
      isOpen={props.show}
      toggle={props.toggleModal}
    >
      <MDBModalHeader>UNLOCK CONTENT</MDBModalHeader>
      <MDBModalBody>
        <Form>
          <Form.Group>
            <Row style={styles.minTextSectionStyle}>
              <span
                style={styles.textStyle}
              >{`Please confirm you want to purchase a post for ${price}`}</span>
            </Row>
          </Form.Group>
          <Form.Group>
            <Row style={styles.buttonsSectionStyle}>
              <BasicButton text="CANCEL" onClick={() => props.toggleModal()} />
              <BasicButton
                text="PAY"
                onClick={handleSubmit}
                // disabled={updateButtonDisable}
              />
            </Row>
          </Form.Group>
        </Form>
      </MDBModalBody>
    </MDBModal>
  );
};

export default UnlockContentModal;

const styles = {
  buttonsSectionStyle: {
    display: "flex",
    justifyContent: "end",
  },
  textStyle: {
    color: COLORS.darkPurple,
    fontSize: 18,
    fontWeight: "bold",
  },
};
