import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../providers/AuthProvider";
import { Row, Form, Col, Button } from "react-bootstrap";
import Sidebar from "../../../../components/profile/Sidebar";
import Header from "../../../../components/profile/Header";
import { BrowserView, MobileView } from "react-device-detect";
import { BoldText } from "../../../../components/texts/PageText";
import { LAYOUTS } from "../../../../constants";
import {
  getToastNotifications,
  postToastNotificationsNewCampaignContribution,
  postToastNotificationsNewComment,
  postToastNotificationsNewLikes,
  postToastNotificationsNewSubscriber,
  postToastNotificationsNewTip,
} from "../../../../api/ApiManager";
import MobileSidebar from "../../../../components/profile/MobileSidebar";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { Link } from "react-router-dom";

const ToastNotification = (props) => {
  const { user } = useContext(AuthContext);
  const [content, setContent] = useState({});

  const handleChange = (e) => {
    switch (e.target.name) {
      case "newCampaign":
        postToastNotificationsNewCampaignContribution(
          e.target.checked,
          user.uid,
          () => {
            alert("Compaign Notification updated!");
          }
        );
        break;
      case "newComment":
        postToastNotificationsNewComment(e.target.checked, user.uid, () => {
          alert("New-Comments Notification updated!");
        });
        break;
      case "newLikes":
        postToastNotificationsNewLikes(e.target.checked, user.uid, () => {
          alert("New-Likes Notification updated!");
        });
        break;
      case "newSubscriber":
        postToastNotificationsNewSubscriber(e.target.checked, user.uid, () => {
          alert("New-Subscriber Notification updated!");
        });
        break;
      case "newTip":
        postToastNotificationsNewTip(e.target.checked, user.uid, () => {
          alert("New-Tip Notification updated!");
        });
        break;

      default:
        break;
    }

    setContent({ ...content, [e.target.name]: e.target.checked });
  };

  useEffect(() => {
    getToastNotifications(user.uid, setContent);
  }, [user.uid]);

  const BodySection = () => {
    return (
      <div>
        <Row>
          <Col xs={12} className="mb-2">
            <h5 className="text-muted font-weight-bold">
              Related to you and your posts
            </h5>
          </Col>
          <Col xs={12}>
            <Form>
              <Form.Check
                type="checkbox"
                label="New Campaign Contribution"
                onChange={(e) => handleChange(e)}
                name="newcampaign"
                checked={content.newcampaign}
              />
            </Form>
            <Form>
              <Form.Check
                type="checkbox"
                label="New comment"
                onChange={(e) => handleChange(e)}
                name="newcomment"
                checked={content.newcomment}
              />
            </Form>
            <Form>
              <Form.Check
                type="checkbox"
                label="New Likes"
                onChange={(e) => handleChange(e)}
                name="newlikes"
                checked={content.newlikes}
              />
            </Form>
            <Form>
              <Form.Check
                type="checkbox"
                label="New Subscriber"
                onChange={(e) => handleChange(e)}
                name="newsubscriber"
                checked={content.newsubscriber}
              />
            </Form>
            <Form>
              <Form.Check
                type="checkbox"
                label="New Tip"
                onChange={(e) => handleChange(e)}
                name="newtip"
                checked={content.newtip}
              />
            </Form>
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <div>
      <BrowserView>
        <div className="container" style={LAYOUTS.profileHeaderStyle}>
          <Row>
            <Header />
          </Row>
        </div>
        <div className="container pb-4" style={LAYOUTS.bodyStyle}>
          <Row>
            <Col md={3}>
              <Sidebar />
            </Col>
            <Col md={9} className="text-left">
              <h4>
                <BoldText>Toast Notifications</BoldText>
              </h4>
              <hr style={styles.hr} />
              <BodySection />

              <Link to={"/settings/notification"}>
                <Button
                  variant="secondary"
                  size="md"
                  style={{ float: "right" }}
                >
                  <span style={{ float: "left" }}>
                    <FaArrowAltCircleLeft size={17} />
                  </span>
                  <span style={styles.notificationBackbutton}>Back</span>
                </Button>
              </Link>
            </Col>
          </Row>
        </div>
      </BrowserView>
      <MobileView>
        <div className="container header" style={LAYOUTS.profileHeaderStyle}>
          <Row>
            {" "}
            <Header />{" "}
          </Row>
        </div>
        <div className="container pb-4 headerBody" style={LAYOUTS.bodyStyle}>
          <Row>
            <MobileSidebar />
            <span style={styles.profileComponentHeader}>
              Toast Notifications
            </span>
            <Col md={12} className="text-left mt-2">
              <BodySection />

              <Link to={"/settings/notification"}>
                <Button
                  variant="secondary"
                  size="md"
                  style={{ float: "right" }}
                >
                  <span style={{ float: "left" }}>
                    <FaArrowAltCircleLeft size={17} />
                  </span>
                  <span style={styles.notificationBackbutton}>Back</span>
                </Button>
              </Link>
            </Col>
          </Row>
        </div>
      </MobileView>
    </div>
  );
};

const styles = {
  headerStyle: {
    paddingLeft: 25,
    height: 300,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  profileComponentHeader: {
    fontSize: "25px",
    fontWeight: 600,
  },
  notificationBackbutton: {
    paddingLeft: "10px",
    fontWeight: "600",
    fontSize: "small",
  },
};

export default ToastNotification;
