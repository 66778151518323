import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import ContentGridCard from "../cards/ContentGridCard";

const UserProfileFeedGrid = (props) => {
  const contentItem = props.contentList.map((content) => {
    return (
      <ContentGridCard key={content.postId} content={content} canEdit={true} />
    );
  });

  return (
    <Container fluid style={styles.sectionStyle}>
      <Row>
        <Col style={{ paddingLeft: 0, paddingRight: 0 }}>
          <Row style={styles.feedSectionStyle}>{contentItem}</Row>
        </Col>
      </Row>
    </Container>
  );
};

export default UserProfileFeedGrid;

const styles = {
  feedSectionStyle: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "left",
  },
  sectionStyle: {
    marginBottom: 50,
  },
};
