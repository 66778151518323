import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { LAYOUTS } from "../../../constants";
import { BoldText } from "../../../components/texts/PageText";
import Header from "../../../components/profile/Header";
import Sidebar from "../../../components/profile/Sidebar";
import { Row, Col, Form, Container } from "react-bootstrap";
import MobileSidebar from "../../../components/profile/MobileSidebar";

const FansFollowing = () => {
  const BodySection = () => {
    return (
      <div>
        <h4>
          <BoldText>Reward for subscriber refferrals</BoldText>
        </h4>
        <hr style={styles.hr} />
        <Row>
          <Col xs={10}>
            <h5 className="text-muted">1 Free month for referrer</h5>
          </Col>
          <Col xs={2} className="text-right">
            <Form>
              <Form.Check
                disabled
                type="switch"
                label=""
                id="disabled-custom-switch"
              />
            </Form>
          </Col>
        </Row>
        <h6>
          Your Fans will be able to share your profile link and for every new
          subscriber they refer to you, your Fans will receive an additional 1
          month subscription to your profile.
        </h6>
        <hr style={styles.hr} />
        <h4>
          <BoldText>Following Price</BoldText>
        </h4>
        <p className="text-muted">Free</p>
      </div>
    );
  };

  return (
    <div>
      <BrowserView>
        <Container style={LAYOUTS.bodyStyle}>
          <Row style={LAYOUTS.mainSectionStyleBlank}>
            <Header />
            <Col md={3}>
              <Sidebar />
            </Col>
            <Col md={9} className="text-left">
              <span style={styles.profileComponentHeader}>
                Fans & Following
              </span>
              <BodySection />
            </Col>
          </Row>
        </Container>
      </BrowserView>
      <MobileView>
        <div className="container header" style={LAYOUTS.profileHeaderStyle}>
          <Row>
            {" "}
            <Header />{" "}
          </Row>
        </div>
        <div className="container pb-4 headerBody" style={LAYOUTS.bodyStyle}>
          <Row>
            <MobileSidebar />
            <span style={styles.profileComponentHeader}>Fans & Following</span>
            <Col sm={12} className="text-left mt-2">
              <BodySection />
            </Col>
          </Row>
        </div>
      </MobileView>
    </div>
  );
};

export default FansFollowing;

const styles = {
  headerStyle: {
    paddingLeft: 25,
    height: 300,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  profileComponentHeader: {
    fontSize: "25px",
    fontWeight: 600,
  },
  label: {
    fontWeight: 400,
  },
  hr: {
    borderTop: "4px solid rgba(0,0,0,.1)",
  },
};
