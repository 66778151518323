import React, { useContext, useEffect, useState } from "react";
import { Routes, Route, Link } from "react-router-dom";
import { Row } from "react-bootstrap";
import { AuthContext } from "../providers/AuthProvider";
import { BrowserView, MobileView } from "react-device-detect";
import { COLORS } from "../constants";

// Bookmarks
import AllBookmarks from "../views/Bookmark/all";
import Photo from "../views/Bookmark/photo";
import Video from "../views/Bookmark/video";
import Audio from "../views/Bookmark/audio";
import Other from "../views/Bookmark/other";

// Company
import AboutUs from "../views/Company/AboutUs";
import Contact from "../views/Company/Contact";
import Support from "../views/Company/Support";

// Lists
import BlockedList from "../views/List/components/BlockedList";
import BookmarkList from "../views/List/components/BookmarkList";
import CloseFriend from "../views/List/components/CloseFriend";
import FansFollowing from "../views/UserProfile/components/FansFollowing";
import FollowingList from "./../views/List/components/FollowingList";
import RestrictedList from "../views/List/components/RestrictedList";

// Main
import AuthPage from "../views/AuthPage";
import Footer from "./Footer";
import Home from "../views/Home";
import LandingPage from "../views/LandingPage";
import NotFound from "../views/NotFound";
import Notifications from "../views/Notification/Notifications";
import ProfileModal from "./modals/ProfileModal";
import UserPage from "../views/UserPage/UserPage";

// Messages
import Messages from "../views/Messages/Messages";
import MessageNew from "../views/Messages/MessageNew";
import MessageReply from "../views/Messages/MessageReply";

// Notifications
import PushNotification from "../views/UserProfile/components/NotificationSettings/PushNotification";
import EmailNotification from "../views/UserProfile/components/NotificationSettings/EmailNotification";
import ToastNotification from "../views/UserProfile/components/NotificationSettings/ToastNotification";
import SmsNotification from "../views/UserProfile/components/NotificationSettings/SmsNotification";
import SiteNotification from "../views/UserProfile/components/NotificationSettings/SiteNotification";
import OtherNotification from "../views/UserProfile/components/NotificationSettings/OtherNotification";
import TelegramNotification from "../views/UserProfile/components/NotificationSettings/TelegramNotification";

// Posts
import EditPost from "../views/Posts/EditPost";
import NewPost from "../views/Posts/NewPost";
import PostComments from "../views/Posts/PostComments";

// Settings
import AccountSettings from "./../views/UserProfile/components/AccountSettings";
import EditSubscription from "../views/UserProfile/components/EditSubscription";
import ListSettings from "./../views/List/ListSettings";
import NotificationSettings from "../views/UserProfile/components/NotificationSettings/NotificationSettings";
import PrivacySettings from "./../views/UserProfile/components/PrivacySettings";
import ProfileSettings from "./../views/UserProfile/components/ProfileSettings";
import UserProfile from "../views/UserProfile/UserProfile";

// import PaymentSettings from "../views/Payment/Payment";
// import PaymentStatus from "../views/Payment/component/PaymentStatus";
// import AddCard from "../views/Payment/component/AddCard";
import Stats from "./../views/Stat/Stats";
import FAQ from "./../views/faq/Faq";

// Images
import addPostImage from "../assets/icons/navbar/add-post.svg";
import homeImage from "../assets/icons/navbar/home.svg";
import messageImage from "../assets/icons/navbar/message.svg";
import notificationImage from "../assets/icons/navbar/notification.svg";
import userImage from "../assets/icons/navbar/user.svg";

const Application = () => {
  const { token } = useContext(AuthContext);
  const [showProfileModal, setShowProfileModal] = useState(false);

  const toggleModal = () => {
    setShowProfileModal(!showProfileModal);
  };

  useEffect(() => {
    document.body.addEventListener("click", async (e) => {
      const id = e.srcElement.id;

      if (id === "profile_modal") {
        setShowProfileModal(true);
      }
    });
  }, []);

  return (
    <div>
      {token === null ? (
        <div>
          <Routes>
            <Route index element={<LandingPage />} />
            <Route path="aboutus" element={<AboutUs />} />
            <Route path="contact" element={<Contact />} />
            <Route path="login" element={<AuthPage />} />
            <Route path="register" element={<AuthPage />} />
            <Route path="support" element={<Support />} />
          </Routes>
          <Footer />
        </div>
      ) : (
        <div className="App">
          <BrowserView>
            <nav className="navbar navbar-expand-lg navbar-light">
              {/* fixed-top */}
              <div className="container-fluid ">
                <div
                  id="navbar"
                  className="collapse navbar-collapse fixed-top"
                  style={styles.topNavBarStyle}
                >
                  <img
                    src={require("../assets/images/logos/im_logo.png")}
                    style={styles.logoStyle}
                    alt="logo"
                  />
                  <ul className="navbar-nav nav-fill w-100">
                    <li className="nav-item">
                      <Link className="nav-link" to="/">
                        <img
                          src={homeImage}
                          style={styles.iconStyle}
                          alt="home"
                        />
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="messages">
                        <img
                          src={messageImage}
                          style={styles.iconStyle}
                          alt="message"
                        />
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/newpost">
                        <img
                          src={addPostImage}
                          style={{ width: 50, marginTop: -5 }}
                          alt="add post"
                        />
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/notifications">
                        <img
                          src={notificationImage}
                          style={styles.iconStyle}
                          alt="notification"
                        />
                      </Link>
                    </li>
                    <li className="nav-item">
                      <img
                        id="profile_modal"
                        src={userImage}
                        style={{ ...styles.iconStyle, ...{ paddingTop: 5 } }}
                        alt="profile"
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </BrowserView>
          <MobileView>
            {/* Bottom Navbar for mobile */}
            <div className="container">
              <Row
                className="justify-content-center fixed-bottom"
                style={styles.bottomNavBarStyle}
              >
                <div className="col-2">
                  <Link to="/">
                    <img src={homeImage} style={styles.iconStyle} alt="home" />
                  </Link>
                </div>
                <div className="col-2">
                  <Link to="/messages">
                    <img
                      src={messageImage}
                      style={{ ...styles.iconStyle }}
                      alt="message"
                    />
                  </Link>
                </div>
                <div className="col-2">
                  <Link to="/newpost">
                    <img
                      src={addPostImage}
                      style={{ width: 50, marginTop: -5 }}
                      alt="add post"
                    />
                  </Link>
                </div>
                <div className="col-2">
                  <Link to="/notifications">
                    <img
                      src={notificationImage}
                      style={styles.iconStyle}
                      alt="notification"
                    />
                  </Link>
                </div>
                <div className="col-2">
                  <img
                    id="profile_modal"
                    src={userImage}
                    style={styles.iconStyle}
                    alt="profile"
                  />
                </div>
              </Row>
            </div>
          </MobileView>

          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="aboutus" element={<AboutUs />} />
            <Route path="contact" element={<Contact />} />
            <Route path="messages">
              <Route index element={<Messages />} />
              <Route path=":pmid" element={<MessageReply />} />
              <Route path="new" element={<MessageNew />} />
            </Route>
            <Route path="/editpost/:postid" element={<EditPost />} />
            <Route path="/newpost" element={<NewPost />} />
            <Route path="/comments/:postid" element={<PostComments />} />
            <Route path="/my/lists" element={<ListSettings />}>
              <Route path="bookmarks" element={<BookmarkList />} />
              <Route path="blocked" element={<BlockedList />} />
              <Route path="restricted" element={<RestrictedList />} />
              <Route path="close_friends" element={<CloseFriend />} />
              <Route path="following" element={<FollowingList />} />
            </Route>
            <Route path="/notifications" element={<Notifications />} />
            <Route path="/bookmarks/all" element={<AllBookmarks />} />
            <Route path="/bookmarks/photos" element={<Photo />} />
            <Route path="/bookmarks/videos" element={<Video />} />
            <Route path="/bookmarks/audios" element={<Audio />} />
            <Route path="/bookmarks/others" element={<Other />} />
            <Route path="/userprofile" element={<UserProfile />} />
            <Route
              path="/settings/notification"
              element={<NotificationSettings />}
            >
              <Route path="push" element={<PushNotification />} />
              <Route path="email" element={<EmailNotification />} />
              <Route path="site" element={<SiteNotification />} />
              <Route path="toast" element={<ToastNotification />} />
              <Route path="sms" element={<SmsNotification />} />
              <Route path="telegram" element={<TelegramNotification />} />
              <Route path="other" element={<OtherNotification />} />
            </Route>
            <Route path="/settings/profile" element={<ProfileSettings />} />
            <Route path="/settings/account" element={<AccountSettings />} />
            <Route path="/settings/privacy" element={<PrivacySettings />} />
            <Route
              path="/settings/subscription"
              element={<EditSubscription />}
            />
            <Route path="/settings/fansfollwers" element={<FansFollowing />} />
            <Route path="/support" element={<Support />} />
            {/* <Route path="/my/payments/add_card">
              <AddCard />
            </Route>
            <Route path="/my/payments/status">
              <PaymentStatus />
            </Route>
            <Route path="/my/payments">
              <PaymentSettings />
            </Route> */}
            <Route path="/stats" element={<Stats />} />
            <Route path="/faq" element={<FAQ />} />

            <Route path="/im/:username" element={<UserPage />} />
            <Route path="login" element={<Home />} />
            <Route path="register" element={<Home />} />
            <Route path="*" element={<NotFound />} />
          </Routes>

          <ProfileModal show={showProfileModal} toggleModal={toggleModal} />
          <Footer />
        </div>
      )}
    </div>
  );
};

export default Application;

const styles = {
  bottomNavBarStyle: {
    backgroundColor: "white",
    height: 55,
    paddingTop: 5,
  },
  iconStyle: {
    height: "auto",
    width: 40,
  },
  linkStyle: {
    color: COLORS.darkPurple,
  },
  logoStyle: {
    height: "auto",
    width: 200,
  },
  topNavBarStyle: {
    backgroundColor: "white",
    height: 80,
    paddingTop: 5,
  },
};
