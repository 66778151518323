import React from "react";
import Pagination from "../Pagination/Pagination";
import SuggestionCard from "../cards/SuggestionCard";

const SuggestionFeed = (props) => {
  const { suggestionList, dataLimit } = props;

  if (suggestionList === null || typeof suggestionList === "undefined") {
    return null;
  }

  return (
    <div>
      <Pagination
        data={suggestionList}
        RenderComponent={SuggestionCard}
        title="SUGGESTIONS"
        pageLimit={10}
        dataLimit={dataLimit ?? 5}
      />
    </div>
  );
};

export default SuggestionFeed;


