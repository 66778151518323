import React from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { COLORS } from "../../constants";
import AvatarIcon from "../../components/profile/AvatarIcon";
import { dateTime, truncate } from "../../lib/utils";
import { isMobile } from "react-device-detect";

const MessageCard = (props) => {
  const { activeID, message } = props;

  const messageLength = isMobile ? 55 : 70;

  return (
    <div
      className="container"
      style={{
        ...styles.cardStyle,
        ...{
          backgroundColor:
            activeID === message.pmid ? COLORS.lightPurple : null,
        },
      }}
    >
      <Link to={`/messages/${message.pmid}`}>
        <Row>
          <Col style={styles.leftSectionStyle}>
            <AvatarIcon user={message} size={100} />
          </Col>
          <Col style={styles.rightSectionStyle}>
            <Row>
              <Col className="d-flex justify-content-end">
                <span style={styles.dateStyle}>
                  {dateTime(message.timestamp)}
                </span>
              </Col>
            </Row>
            <Row>
              <span style={styles.nameStyle}>{message.username}</span>
            </Row>
            <Row>
              <span style={styles.textStyle}>
                {truncate(message.message, messageLength)}
              </span>
            </Row>
          </Col>
        </Row>
      </Link>
    </div>
  );
};

export default MessageCard;

const styles = {
  cardStyle: {
    borderColor: COLORS.darkPurple,
    borderStyle: "solid",
    borderWidth: 1,
    height: 152,
    minWidth: 366,
    maxWidth: "99%",
    marginBottom: 10,
  },
  dateStyle: {
    color: COLORS.darkPurple,
    fontSize: 16,
    fontWeight: "bold",
    marginRight: 10,
  },
  leftSectionStyle: {
    paddingLeft: 10,
    paddingTop: 20,
    minWidth: 120,
    maxWidth: 120,
  },
  nameStyle: {
    color: "black",
    fontSize: 22,
    fontWeight: "bold",
  },
  rightSectionStyle: {
    marginTop: 10,
  },
  textStyle: {
    color: "black",
    fontSize: 20,
    textAlign: "left",
  },
};
