import React from "react";
import { Row } from "react-bootstrap";
import MessageDetailCard from "../cards/MessageDetailCard";

const MessageDetailFeed = (props) => {
  const messageItem = props.messages.map((message) => {
    return <MessageDetailCard key={message.pmid2} message={message} />;
  });

  return (
    <div className="container">
      <Row>{messageItem}</Row>
    </div>
  );
};

export default MessageDetailFeed;
