import React from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import { BrowserView, MobileView } from "react-device-detect";
import { BoldText } from "../../../components/texts/PageText";
import { LAYOUTS } from "../../../constants";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { Link } from "react-router-dom";

const BookmarkList = (props) => {
  const BodySection = () => {
    return (
      <Row>
        {/* Loop through over column when we get user bookmarks and paste the bookmark layout*/}

        <Col md={6} xs={12}>
          <div className="container">
            <div className="card" style={styles.cardStyle}>
              <div className="card-body"></div>
            </div>
          </div>
        </Col>
      </Row>
    );
  };

  return (
    <div>
      <BrowserView>
        <Container className="pb-4" style={LAYOUTS.bodyStyle}>
          <Row style={LAYOUTS.mainSectionStyleBlank}>
            <Col md={12} className="text-left" style={LAYOUTS.bodyStyle}>
              <h4>
                <BoldText>BOOKMARKS</BoldText>
              </h4>
              <hr style={styles.hr} />
              <BodySection />
              <Link to={"/my/lists"}>
                <Button
                  variant="secondary"
                  size="md"
                  style={{ float: "right" }}
                >
                  <span style={{ float: "left" }}>
                    <FaArrowAltCircleLeft size={17} />
                  </span>
                  <span style={styles.notificationBackbutton}>Back</span>
                </Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </BrowserView>
      <MobileView>
        <Container className="pb-4 headerBody" style={LAYOUTS.bodyStyle}>
          <Row style={LAYOUTS.mobileMainSectionStyle}>
            <span style={styles.profileComponentHeader}>BOOKMARKS</span>
            <Col xs={12} className="text-left mt-2">
              <BodySection />

              <Link to={"/my/lists"}>
                <Button
                  variant="secondary"
                  size="md"
                  style={{ float: "right" }}
                >
                  <span style={{ float: "left" }}>
                    <FaArrowAltCircleLeft size={17} />
                  </span>
                  <span style={styles.notificationBackbutton}>Back</span>
                </Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </MobileView>
    </div>
  );
};

const styles = {
  headerStyle: {
    paddingLeft: 25,
    height: 300,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  profileComponentHeader: {
    fontSize: "25px",
    fontWeight: 600,
  },
  notificationBackbutton: {
    paddingLeft: "10px",
    fontWeight: "600",
    fontSize: "small",
  },
};

export default BookmarkList;
