import React from "react";
import Spinner from "react-spinkit";
import { COLORS } from "../../constants";

const Loader = () => {
  return (
    <div className="col" style={styles.containerStyle}>
      <Spinner name="ball-pulse-rise" color={COLORS.darkPurple} />
    </div>
  );
};

export default Loader;

const styles = {
  containerStyle: {
    height: 75,
    lineHeight: "75px",
  },
};
