import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  FacebookAuthProvider,
  GoogleAuthProvider,
} from "firebase/auth";
import { app } from "./app";
import { getExistingAccount, postRegistration } from "../../api/ApiManager";

const auth = getAuth(app);

export const authMethods = {
  signIn: (email, password, setErrors, setToken) => {
    signInWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        // Grab token from local storage and set to state.
        setToken(window.localStorage.token);
        setErrors([]);
      })
      .catch((err) => {
        setErrors((prev) => [...prev, err.message]);
        // throw Error("Error signing up with password and email!");
      });
  },
  signInWithFacebook: (setErrors, setToken, callback) => {
    const provider = new FacebookAuthProvider();
    signInWithPopup(auth, provider)
      .then(async (result) => {
        // Grab token from local storage and set to state.
        setToken(window.localStorage.token);
        setErrors([]);

        getExistingAccount(result.user.uid, (result) => {
          if (result.accountExist === false) {
            const jsonBody = {
              uuid: result.user.uid,
              displayname: "New User",
            };

            postRegistration(jsonBody, () => {
              return callback();
            });
          } else {
            return callback();
          }
        });
      })
      .catch((err) => {
        setErrors((prev) => [...prev, err.message]);
        // throw TypeError("Error signing in with password and email!");
      });
  },
  signInWithGoogle: (setErrors, setToken, callback) => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then(async (result) => {
        // Grab token from local storage and set to state.
        setToken(window.localStorage.token);
        setErrors([]);

        getExistingAccount(result.user.uid, (result) => {
          if (result.accountExist === false) {
            const jsonBody = {
              uuid: result.user.uid,
              displayname: "New User",
            };
            postRegistration(jsonBody, () => {
              return callback();
            });
          } else {
            return callback();
          }
        });
      })
      .catch((err) => {
        setErrors((prev) => [...prev, err.message]);
        // throw TypeError("Error signing in with password and email!");
      });
  },
  signUp: (email, password, name, setErrors, setToken, callback) => {
    createUserWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        // Grab token from local storage and set to state.
        setToken(window.localStorage.token);

        const jsonBody = {
          uuid: userCredential.user.uid,
          displayname: name,
        };

        postRegistration(jsonBody, () => {
          return callback();
        });
        setErrors([]);
      })
      .catch((err) => {
        setErrors((prev) => [...prev, err.message]);
        // throw TypeError("Error signing in with password and email!");
      });
  },
  signOut: (setErrors, setToken) => {
    signOut(auth)
      .then(() => {
        localStorage.removeItem("token");
        setToken(null);
      })
      .catch((err) => {
        setErrors((prev) => [...prev, err.message]);
        localStorage.removeItem("token");
        setToken(null);
        // throw TypeError("Error signing out!");
      });
  },
};
