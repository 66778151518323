import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Container, Stack } from "react-bootstrap";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { AuthContext } from "../providers/AuthProvider";
import { COLORS } from "../constants";
import { BlackContentText, BoldText } from "../components/texts/PageText";
import signupBackgroundImage from "../assets/images/landing/signup_background.jpeg";
import topBackgroundImage from "../assets/images/landing/top_background.jpg";
import clockImage from "../assets/images/landing/clock.svg";
import creditCardImage from "../assets/images/landing/credit-card.svg";
import giveMoneyImage from "../assets/images/landing/give-money.svg";
import logoImage from "../assets/images/logos/im_logo_white.svg";
import moneyImage from "../assets/images/landing/money.svg";
import selfieImage from "../assets/images/landing/selfie.svg";
import shareImage from "../assets/images/landing/share.svg";

const LandingPage = (props) => {
  const [isLoginMode, setIsLoginMode] = useState(true);

  const {
    handleSignIn,
    handleSignInFacebook,
    handleSignInGoogle,
    inputs,
    setInputs,
    errors,
  } = useContext(AuthContext);

  const handleLogin = async (e) => {
    e.preventDefault();
    await handleSignIn();
  };

  const handleLoginFacebook = async (e) => {
    e.preventDefault();
    await handleSignInFacebook();
  };

  const handleLoginGoogle = async (e) => {
    e.preventDefault();
    await handleSignInGoogle();
  };

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const AuthButton = (props) => {
    const AuthButtonDiv = () => {
      return (
        <div
          style={{
            ...props.style,
            ...{
              width: 330,
              height: 45,
              border: `5px solid ${COLORS.darkPurple}`,
            },
          }}
        >
          <div style={styles.authButtonBackgroundStyle} />
          <div style={styles.authButtonTextStyle}>{props.text}</div>
        </div>
      );
    };

    if (typeof props.link !== "undefined") {
      return (
        <Link to={props.link} onClick={props.onClick}>
          <AuthButtonDiv />
        </Link>
      );
    } else {
      return (
        <button onClick={props.onClick} style={styles.clearButtonStyle}>
          <AuthButtonDiv />
        </button>
      );
    }
  };

  const HowSection = () => {
    const howSectionBottomMargin = isMobile ? 30 : 0;

    return (
      <Row style={styles.howSectionStyle}>
        <div className="col-12">
          <Row className="justify-content-center">
            <p
              style={{
                ...styles.headerTextStyle,
                ...{ color: "white" },
              }}
            >
              HOW TO EARN
            </p>
          </Row>
          <Row className="justify-content-center">
            <div
              className="col-lg-3 col-12"
              style={{ marginBottom: howSectionBottomMargin }}
            >
              <Row className="justify-content-center">
                <p
                  style={{
                    color: "white",
                    fontSize: 20,
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  CREATE
                </p>
              </Row>
              <Row className="justify-content-center">
                <img
                  src={selfieImage}
                  style={{ width: 150, height: "auto" }}
                  alt="earn"
                />
              </Row>
            </div>
            <div
              className="col-lg-6 col-12"
              style={{ marginBottom: howSectionBottomMargin }}
            >
              <Row className="justify-content-center">
                <p
                  style={{
                    color: "white",
                    fontSize: 20,
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  SHARE
                </p>
              </Row>
              <Row
                className="justify-content-center"
                style={{ alignItems: "flex-start" }}
              >
                <img
                  src={shareImage}
                  style={{ width: 320, height: "auto" }}
                  alt="earn"
                />
              </Row>
            </div>
            <div
              className="col-lg-3 col-12"
              style={{ marginBottom: howSectionBottomMargin }}
            >
              <Row className="justify-content-center">
                <p
                  style={{
                    color: "white",
                    fontSize: 20,
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  EARN
                </p>
              </Row>
              <Row className="justify-content-center">
                <img
                  src={moneyImage}
                  style={{ width: 150, height: "auto" }}
                  alt="earn"
                />
              </Row>
            </div>
          </Row>
          <Row className="justify-content-center">
            <p style={styles.subHeaderTextStyle}>
              GROW YOUR AUDIENCE. GROW YOUR BANK ACCOUNT
            </p>
          </Row>
        </div>
      </Row>
    );
  };

  const TopSection = () => {
    return (
      <Row style={styles.topSectionStyle}>
        <Col>
          <Row className="justify-content-center">
            <img src={logoImage} style={styles.logoStyle} alt="logo" />
          </Row>
          <Row
            className="justify-content-center"
            style={{ marginTop: 100, marginBottom: 100 }}
          >
            <p style={styles.headerTextStyle}>ARE YOU INTRIGUED?</p>
          </Row>
          <Stack
            direction={isMobile ? "veritical" : "horizontal"}
            gap={isMobile ? 2 : 5}
            className={isMobile ? "col-md-8 mx-auto" : "justify-content-center"}
          >
            <AuthButton
              text="REGISTER"
              link="/register"
              // style={{
              //   marginRight: isMobile ? 0 : 100,
              //   marginBottom: isMobile ? 20 : 0,
              // }}
            />
            <AuthButton text="LOG IN" link="/login" />
          </Stack>
        </Col>
      </Row>
    );
  };

  const WhySection = () => {
    const whyWidth = isMobile ? "100%" : "50%";
    const whyMargin = isMobile ? 0 : 50;
    const whySectionCardBottomMargin = isMobile ? 30 : 0;

    return (
      <Row style={styles.whySectionStyle}>
        <Col>
          <Row className="justify-content-center">
            <p
              style={{
                ...styles.headerTextStyle,
                ...{ color: "black" },
              }}
            >
              WHY CHOOSE US?
            </p>
          </Row>
          <Row className="justify-content-center">
            <div style={{ maxWidth: whyWidth, textAlign: "center" }}>
              <BlackContentText>
                Quality content is in high demand and now more than ever people
                want to be entertained. Imagine your fans and followers handing
                you money every time you log on.
              </BlackContentText>
            </div>
          </Row>
          <div
            className="row"
            style={{
              marginLeft: whyMargin,
              marginRight: whyMargin,
              marginTop: 20,
            }}
          >
            <div
              className="col-lg-6 col-12"
              style={{ marginBottom: whySectionCardBottomMargin }}
            >
              <WhySectionCard
                imageSrc={giveMoneyImage}
                title="HIGHEST PAYOUTS"
                description="Our services cost half of what the competition charges, don't
              waste time with competing sites, keep more of your hard earned
              money in YOUR pocket!"
              />
            </div>
            <div
              className="col-lg-6 col-12"
              style={{ marginBottom: { whySectionCardBottomMargin } }}
            >
              <WhySectionCard
                imageSrc={creditCardImage}
                title="PAYMENT PROCESS"
                description="We guarantee reliable payouts, earn money from every sale, post from anywhere, work from anywhere, set your own price, get paid to post."
              />
            </div>
          </div>
          <div
            className="row"
            style={{
              marginLeft: whyMargin,
              marginRight: whyMargin,
              marginTop: 20,
            }}
          >
            <div
              className="col-lg-6 col-12"
              style={{ marginBottom: { whySectionCardBottomMargin } }}
            >
              <WhySectionCard
                title="QUICK SETUP"
                imageSrc={clockImage}
                description="..."
              />
            </div>
            <div className="col-lg-6 col-12">
              <WhySectionCard
                imageSrc={giveMoneyImage}
                title="COMING SOON FEATURES"
                description="Coming soon features like the e-commerce store you will have even more opportunities to make money, to see what's new and what's coming check out our coming soon section."
              />
            </div>
          </div>
        </Col>
      </Row>
    );
  };

  const WhySectionCard = (props) => {
    const { imageSrc, title, description } = props;
    return (
      <Row>
        <div className="col" style={styles.whyLeftSectionStyle}>
          <img
            id="profile_modal"
            src={imageSrc}
            style={styles.whyImagesStyle}
            alt="payouts"
          />
        </div>
        <Col>
          <Row>
            <BoldText>{title}</BoldText>
          </Row>
          <Row>
            <BlackContentText>{description}</BlackContentText>
          </Row>
        </Col>
      </Row>
    );
  };

  useEffect(() => {
    document.body.addEventListener("click", async (e) => {
      const id = e.srcElement.id;

      switch (id) {
        case "sign_up":
          setIsLoginMode(!isLoginMode);
          break;
        default:
          break;
      }
    });
  }, [isLoginMode]);

  return (
    <div>
      <Container fluid style={styles.constainerStyle}>
        <TopSection />
        <WhySection />
        <HowSection />
        <Row>
          <div className="col" style={styles.authLeftSectionStyle}>
            <Row className="justify-content-center">
              <p
                style={{
                  ...styles.headerTextStyle,
                  ...{ color: COLORS.lightPurple },
                }}
              >
                Sign Up
              </p>
            </Row>
            <Stack gap={2} className="col-md-8 mx-auto">
              <AuthButton text="FACEBOOK" onClick={handleLoginFacebook} />
              <AuthButton text="GMAIL" onClick={handleLoginGoogle} />
            </Stack>
          </div>
          <div className="col" style={styles.authRightSectionStyle}>
            <Row className="justify-content-center">
              <p
                style={{
                  ...styles.headerTextStyle,
                  ...{ color: COLORS.lightPurple },
                }}
              >
                Member Login
              </p>
            </Row>
            <Row className="justify-content-center">
              {errors.length > 0
                ? errors.map((error) => <p style={{ color: "red" }}>{error}</p>)
                : null}
            </Row>
            <Row className="justify-content-center">
              <form style={{ width: 250 }}>
                <div style={styles.textSectionStyle}>
                  <input
                    type="email"
                    className="my-1 p-1 w-100"
                    name="email"
                    value={inputs.email}
                    placeholder="Email"
                    id="email"
                    onChange={onChangeHandler}
                    autoComplete="username"
                    style={styles.authInputStyle}
                  />
                </div>
                <div style={styles.textSectionStyle}>
                  <input
                    type="password"
                    className="mt-1 mb-3 p-1 w-100 no-shadow"
                    name="password"
                    value={inputs.password}
                    placeholder="Password"
                    id="password"
                    onChange={onChangeHandler}
                    autoComplete="current-password"
                    style={styles.authInputStyle}
                  />
                </div>
                <div>
                  <button
                    type="button"
                    className="btn btn-block"
                    onClick={handleLogin}
                    style={styles.loginButtonStyle}
                  >
                    LOG IN
                  </button>
                </div>
              </form>
            </Row>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default LandingPage;

const styles = {
  authButtonBackgroundStyle: {
    backgroundColor: "white",
    width: 320,
    height: 35,
    opacity: 0.7,
  },
  authButtonTextStyle: {
    position: "relative",
    marginTop: -30,
    height: 30,
    color: "black",
    fontWeight: "bold",
    textAlign: "center",
  },
  authInputStyle: {
    backgroundColor: COLORS.darkGray,
    borderColor: COLORS.darkPurple,
    boxShadow: "none",
    color: "white",
  },
  authLeftSectionStyle: {
    backgroundImage: `url(${signupBackgroundImage})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    paddingTop: 30,
    paddingBottom: 30,
  },
  authRightSectionStyle: {
    backgroundColor: COLORS.darkGray,
    paddingTop: 30,
    paddingBottom: 30,
  },
  clearButtonStyle: {
    background: "none",
    border: "none",
    outline: "none",
    padding: 0,
    minHeight: 52,
  },
  constainerStyle: {
    backgroundColor: "black",
  },
  headerTextStyle: {
    color: "white",
    fontSize: 40,
    fontWeight: "bold",
    letterSpacing: 3,
    textAlign: "center",
  },
  howSectionStyle: {
    backgroundColor: COLORS.darkPurple,
    paddingTop: 30,
    paddingBottom: 30,
  },
  loginButtonStyle: {
    background: COLORS.darkPurple,
    border: "none",
    color: "white",
    fontSize: 24,
    fontWeight: "bold",
    outline: "none",
    padding: 0,
    height: 40,
  },
  logoStyle: {
    height: "auto",
    width: 200,
  },
  textSectionStyle: {
    marginTop: 10,
  },
  subHeaderTextStyle: {
    color: "white",
    fontSize: 24,
    fontWeight: "bold",
    textAlign: "center",
  },
  textStyle: {
    fontSize: 16,
  },
  topSectionStyle: {
    backgroundImage: `url(${topBackgroundImage})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    textAlign: "center",
    paddingTop: 10,
    paddingBottom: 30,
  },
  whyImagesStyle: {
    height: "auto",
    width: 80,
  },
  whyLeftSectionStyle: {
    minWidth: 140,
    maxWidth: 140,
  },
  whySectionStyle: {
    backgroundColor: "white",
    paddingTop: 30,
    paddingBottom: 30,
  },
};
