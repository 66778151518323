import React from "react";
import MessageCard from "../cards/MessageCard";
import { BoldText } from "../texts/PageText";
import { BrowserView, MobileView } from "react-device-detect";
import { Row, Col } from "react-bootstrap";
import { SizableButton } from "../buttons/Buttons";


const MessageFeed = (props) => {
  const { activeID, hideNewMessageButton } = props;
  const messageItem = props.messageList.map((message) => {
    return (
      <MessageCard key={message.pmid} message={message} activeID={activeID} />
    );
  });

  return (
    <div>
      <BrowserView>
        <div className="container" style={styles.sectionStyle}>
          <Row style={styles.headerSectionStyle}>
            <BoldText>MESSAGES</BoldText>
          </Row>
          <Row>{messageItem}</Row>
        </div>
      </BrowserView>
      <MobileView>
          <Col className="container" style={styles.sectionStyle}>
            <Row style={styles.headerSectionStyle}>
              <Col
                className="d-flex align-self-end justify-content-start"
                style={{ paddingLeft: 0 }}
              >
                <BoldText>MESSAGES</BoldText>
              </Col>
              <Col
                className="d-flex align-self-end justify-content-end"
                style={{ paddingRight: 0 }}
              >
                {hideNewMessageButton ? null : (
                  <SizableButton
                    text="New Message"
                    to="/messages/new"
                    width={130}
                  />
                )}
              </Col>
            </Row>
            <Row>{messageItem}</Row>
          </Col>
      </MobileView>
    </div>
  );
};

export default MessageFeed;

const styles = {
  headerSectionStyle: {
    marginBottom: 5,
    paddingRight: 0,
  },
  sectionStyle: {
    marginBottom: 50,
    paddingTop: 20,
    paddingLeft: 5,
    paddingRight: 5,
  },
  linkStyle: {
    marginLeft: 20,
  },
  navSectionStyle: {
    paddingRight: 0,
  },
};
