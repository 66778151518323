import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { LAYOUTS } from "../../constants";
import "./Faq.css"
import { Row, Col, Form, FormControl, Button } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
import GeneralQuestion from './components/GeneralQuestion';

const FAQ = () => {
  const HeaderSection = () => {
    return (
      <div className="container mw-100">
        <div className="faq-header">
          <img
            align="left"
            className="faq-header-image-lg"
            src={require("../../assets/tmp/faq-header.jpg")}
            alt="Profile example"
          />
          <div className="faq-header-area">
            <h1>Have a Question?</h1>
            <h6>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</h6>
            <Form inline>
              <div className="inner-addon right-addon">
                <span className="glyphicon glyphicon-search">
                  {" "}
                  <FaSearch />
                </span>
                <FormControl
                  type="text"
                  placeholder="Search"
                  className="searchBar mr-sm-2"
                />
              </div>
              <Button variant="secondary">Search</Button>
            </Form>
          </div>
        </div>
      </div>
    );
  };

  const BodySection = () => {
    return (
      <Row>
        <Col md={3} xs={3}>
          <div className="d-flex align-items-start">
            <ul
              className="nav nav-tabs flex-column faqSidebar"
              style={{ borderBottom: "unset" }}
            >
              <li className="nav-item">
                <a
                  className="nav-link active"
                  data-toggle="tab"
                  href="#s1"
                  style={{ borderTop: "none" }}
                >
                  General Questions
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-toggle="tab" href="#s2">
                  Fan Questions
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-toggle="tab" href="#s3">
                  Creator Questions
                </a>
              </li>

              <li className="nav-item">
                <a className="nav-link" data-toggle="tab" href="#s4">
                  Support
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-toggle="tab" href="#s5">
                  Contact
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-toggle="tab"
                  href="#s6"
                  style={{ borderBottom: "unset" }}
                >
                  Payment
                </a>
              </li>
            </ul>{" "}
          </div>
        </Col>
        <Col md={9} xs={9}>
          <div className="tab-content">
            <div
              className="tab-pane container fade in show active text-left"
              id="s1"
            >
              {" "}
              <span style={styles.profileComponentHeader}>
                General Questions
              </span>
              <div style={styles.formDiv}>
                <GeneralQuestion />
              </div>
            </div>
            <div className="tab-pane container fade text-left" id="s2">
              <span style={styles.profileComponentHeader}>Fan Questions</span>
            </div>
            <div className="tab-pane container fade text-left" id="s3">
              <span style={styles.profileComponentHeader}>
                Creator Questions
              </span>
            </div>
            <div className="tab-pane container fade text-left" id="s4">
              <span style={styles.profileComponentHeader}>Support</span>
            </div>
            <div className="tab-pane container fade text-left" id="s5">
              <span style={styles.profileComponentHeader}>Contact</span>
            </div>
            <div className="tab-pane container fade text-left" id="s6">
              <span style={styles.profileComponentHeader}>Payment</span>
            </div>
          </div>
        </Col>
      </Row>
    );
  };

  return (
    <div>
      <BrowserView>
        <div className="container" style={LAYOUTS.profileHeaderStyle}>
          <Row>
            <HeaderSection />
          </Row>
        </div>
        <div className="container pb-4" style={LAYOUTS.bodyStyle}>
          <BodySection />
        </div>
      </BrowserView>
      <MobileView>
        <div style={LAYOUTS.mobileMainSectionStyle}>
          <div
            className="container mw-100"
            style={{ paddingRight: 0, paddingLeft: 0 }}
          >
            <div className="faq-header" style={{ height: "0px" }}>
              <img
                align="left"
                className="faq-header-image-lg"
                src={require("../../assets/tmp/faq-header.jpg")}
                alt="Profile example"
              />
              <div className="faq-header-area">
                <h4>Have a Question?</h4>
                <p style={{ fontSize: "small" }}>
                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                </p>

                <Form inline>
                  <div className="inner-addon right-addon">
                    <span className="glyphicon glyphicon-search">
                      {" "}
                      <FaSearch />
                    </span>
                    <FormControl
                      type="text"
                      placeholder="Search"
                      className="searchBar mr-sm-2"
                    />
                  </div>

                  <Button variant="secondary" size="sm">
                    Search
                  </Button>
                </Form>
              </div>
            </div>
          </div>
          <div className="container pb-4" style={LAYOUTS.bodyStyle}>
            <BodySection />
          </div>
        </div>
      </MobileView>
    </div>
  );
};

export default FAQ;

const styles = {
  headerStyle: {
    paddingLeft: 25,
    height: 300,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  formDiv: {
    background: "#f7f7f9",
    padding: "3%",
  },
  profileComponentHeader: {
    fontSize: "25px",
    fontWeight: 600,
  },
};
