import React, { useState } from "react";
import { MDBModal, MDBModalBody, MDBModalHeader } from "mdbreact";
import { Col, Row, Form } from "react-bootstrap";
import { COLORS } from "../../constants";
import { BasicButton } from "../buttons/Buttons";
import AvatarIcon from "../profile/AvatarIcon";
import { truncate } from "../../lib/utils";

const TipModal = (props) => {
  const { content } = props;
  const [tipInfo, setTipInfo] = useState({
    message: "",
    tip: "",
    maxText: "0/200",
  });
  const [updateButtonDisable, setUpdateButtonDisable] = useState(true);
  const [msgRows, setMsgRows] = useState(1);

  const handleKeyUp = (e) => {
    switch (e.target.name) {
      case "message":
        setMsgRows(Math.round((e.target.scrollHeight - 12) / 24));
        break;
      default:
        break;
    }
  };

  const handleChange = (e) => {
    let { value } = e.target;

    switch (e.target.name) {
      case "message":
        const msgCount = value.length;
        if (value.length <= 200) {
          setTipInfo({ ...tipInfo, message: value, maxText: `${msgCount}/200` });
        }
        break;
      case "tip":
        let { min, max } = e.target;

        if (value < 0 || value > 250) {
          value = Math.max(Number(min), Math.min(Number(max), Number(value)));
          document.getElementById("tip").value = value;
        }

        setTipInfo({ ...tipInfo, [e.target.name]: value });

        if (value >= 5 && value <= 250) {
          setUpdateButtonDisable(false);
        } else {
          setUpdateButtonDisable(true);
        }
        break;
      default:
        break;
    }
  };

  const handleSubmit = () => {
    props.toggleModal();
  };

  return (
    <MDBModal
      id="centralModalInfo"
      centered
      className="modal-dialog modal-md modal-notify"
      isOpen={props.show}
      toggle={props.toggleModal}
    >
      <MDBModalHeader>SEND TIP</MDBModalHeader>
      <MDBModalBody>
        <Row style={styles.userSectionStyle}>
          <Col style={styles.avatarSectionStyle}>
            <AvatarIcon user={content} size={70} />
          </Col>
          <Col>
            <Row style={{ paddingTop: 10 }}>
              <span style={styles.usernameStyle}>
                @{truncate(content.userName, 30)}
              </span>
            </Row>
            <Row>
              <span style={styles.nameStyle}>{content.displayName}</span>
            </Row>
          </Col>
        </Row>
        <Form>
          <Form.Group>
            <Row>
              <Form.Control
                className="shadow-none"
                type="number"
                id="tip"
                placeholder="Tip amount"
                defaultValue={tipInfo.tip}
                name="tip"
                min="5"
                max="250"
                onChange={(e) => handleChange(e)}
              />
            </Row>
            <Row style={styles.minTipTextSectionStyle}>
              <span style={styles.minTipTextStyle}>Minimum $5 USD</span>
            </Row>
          </Form.Group>
          <Form.Group>
            <Row>
              <Form.Control
                className="shadow-none"
                as="textarea"
                id="message"
                placeholder="Message (optional)"
                name="message"
                maxLength="200"
                rows={msgRows}
                onChange={(e) => handleChange(e)}
                onKeyUp={(e) => handleKeyUp(e)}
                style={{ resize: "none" }}
              />
            </Row>
            <Row style={styles.maxTextSectionStyle}>
              <span style={styles.maxTextStyle}>{tipInfo.maxText}</span>
            </Row>
          </Form.Group>
          <Form.Group>
            <Row style={styles.buttonsSectionStyle}>
              <BasicButton text="CANCEL" onClick={() => props.toggleModal()} />
              <BasicButton
                text="SEND TIP"
                onClick={handleSubmit}
                disabled={updateButtonDisable}
              />
            </Row>
          </Form.Group>
        </Form>
      </MDBModalBody>
    </MDBModal>
  );
};

export default TipModal;

const styles = {
  avatarSectionStyle: {
    minWidth: 95,
    maxWidth: 95,
  },
  buttonsSectionStyle: {
    display: "flex",
    justifyContent: "end",
  },
  maxTextSectionStyle: {
    display: "flex",
    justifyContent: "end",
  },
  maxTextStyle: {
    paddingRight: 15,
    color: COLORS.darkGray,
    fontSize: 12,
  },
  minTipTextSectionStyle: {},
  minTipTextStyle: {
    paddingLeft: 15,
    color: COLORS.darkGray,
    fontSize: 12,
  },
  nameStyle: {
    color: COLORS.darkPurple,
    fontSize: 15,
  },
  usernameStyle: {
    color: COLORS.darkPurple,
    fontWeight: "bold",
    fontSize: 15,
  },
  userSectionStyle: {
    marginBottom: 15,
  },
};
