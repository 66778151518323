import React from "react";
import { Link } from "react-router-dom";
import { Row } from "react-bootstrap";
import { COLORS } from "../constants";
import { BrowserView, MobileView } from "react-device-detect";
import { Button, InputGroup, FormControl } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import logoImage from "../assets/images/logos/im_logo_white.svg";

const Footer = () => {
  const LogoSection = () => {
    return <img src={logoImage} style={styles.logoStyle} alt="logo" />;
  };

  const LinkSection = () => {
    return (
      <ul className="" style={styles.linkItemsAreaStyle}>
        <li>
          <Link to="/aboutus" className="white-text">
            About Us
          </Link>
        </li>
        <li>
          <Link to="/contact" className="white-text">
            Contact
          </Link>
        </li>
        <li>
          <Link to="/support" className="white-text">
            Support
          </Link>
        </li>
      </ul>
    );
  };

  const SocialSection = () => {
    return (
      <div>
        <h3 style={styles.headerStyle}>FOLLOW US</h3>
        <div className="mb-3 flex-left" style={styles.socialLinksSectionStyle}>
          {/* Facebook */}
          <a
            className="fb-ic"
            href="https://fb.me/intriguemeofficial"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-facebook-f fa-lg white-text mr-md-4 mr-3 fa-2x">
              {" "}
            </i>
          </a>
          {/* Twitter */}
          <a
            className="tw-ic"
            href="https://twitter.com/intrigueme_"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-twitter fa-lg white-text mr-md-4 mr-3 fa-2x">
              {" "}
            </i>
          </a>
          {/* Instagram */}
          <a
            className="ins-ic"
            href="https://www.instagram.com/intriguemeofficial"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-instagram fa-lg white-text mr-md-4 mr-3 fa-2x">
              {" "}
            </i>
          </a>
        </div>
      </div>
    );
  };

  const SearchSection = () => {
    return (
      <div>
        <InputGroup className="mb-3">
          <FormControl
            placeholder="..."
            aria-label="Recipient's username"
            aria-describedby="basic-addon2"
          />
          <InputGroup style={{ display: "contents" }}>
            <Button size="sm" variant="outline-secondary" style={styles.search}>
              <span>
                <FaSearch size={25} />
              </span>
            </Button>
          </InputGroup>
        </InputGroup>
      </div>
    );
  };

  return (
    <footer className="footer p-5" style={styles.footerStyle}>
      <BrowserView>
        <div className="d-flex flex-column">
          <footer className="footer p-5">
            <div className="container-fluid">
              <Row>
                <div className="col-md-2 col-sm-12 d-flex align-items-center justify-content-center">
                  <LogoSection />
                </div>
                <div className="col-md-4 col-sm-6 d-flex align-items-center justify-content-center">
                  <LinkSection />
                </div>
                <div className="col-md-3 col-sm-6 d-flex align-items-center justify-content-center">
                  <SocialSection />
                </div>
                <div className="col-md-3 col-sm-12 d-flex align-items-center justify-content-center">
                  {/* <SearchSection /> */}
                </div>
              </Row>
            </div>
          </footer>
        </div>
      </BrowserView>
      <MobileView>
        <div className="container-fluid" style={{ marginBottom: 50 }}>
          <Row
            className="justify-content-center"
            style={{ alignItems: "flex-start" }}
          >
            <LogoSection />
          </Row>
          <Row className="justify-content-center">
            <LinkSection />
          </Row>
          <Row className="justify-content-center" style={{ marginTop: 20 }}>
            <SocialSection />
          </Row>
          <Row className="justify-content-center" style={{ marginTop: 20 }}>
            <SearchSection />
          </Row>
        </div>
      </MobileView>
    </footer>
  );
};

const styles = {
  contactDetailsStyle: {
    lineHeight: "100%",
    linkColor: "white",
  },
  footerStyle: {
    backgroundColor: "black",
    minHeight: 240,
  },
  headerStyle: {
    color: COLORS.lightPurple,
    fontSize: 16,
    fontWeight: "bold",
  },
  linkItemsAreaStyle: {
    linkColor: "white",
    listStyle: "none",
    paddingLeft: "unset",
  },
  logoStyle: {
    marginTop: -40,
    height: "auto",
    width: 180,
  },
  socialLinksSectionStyle: {
    marginTop: 15,
  },
  search: {
    width: "90px",
    height: "40px",
    display: "block",
    margin: "unset",
  },
};

export default Footer;
