import React from "react";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";

const FileUploader = (props) => {
  const maxFiles = typeof props.maxFiles !== "undefined" ? props.maxFiles : 25;

  // specify upload params and url for your files
  const getUploadParams = ({ meta }) => {
    return { url: "https://httpbin.org/post" };
  };

  // called every time a file's `status` changes
  // const handleChangeStatus = ({ meta, file }, status) => {
    // console.log(status, meta, file);
  // };

  // receives array of files that are done uploading when submit button is clicked
  // const handleSubmit = (files, allFiles) => {
  //   console.log(files.map((f) => f.meta));
  //   allFiles.forEach((f) => f.remove());
  // };
  
  return (
    <Dropzone
      getUploadParams={getUploadParams}
      onChangeStatus={props.onChangeStatus}
      onSubmit={props.onSubmit}
      accept="image/*,video/*"
      inputContent={(files, extra) =>
        extra.reject ? "Image and video files only" : "Upload files here..."
      }
      maxFiles={maxFiles}
      submitButtonContent={props.submitButtonContent}
      submitButtonDisabled={(files) => files.length > maxFiles}
      style={styles.uploaderStyle}
    />
  );
};

export default FileUploader;

const styles = {
  uploaderStyle: {
    dropzone: {
      minheight: 200,
      maxHeight: 250,
      width: 500,
    },
    dropzoneReject: { borderColor: "red", backgroundColor: "#DAA" },
    inputLabel: (files, extra) => (extra.reject ? { color: "red" } : {}),
  },
};
